import { Component, OnInit, Input } from '@angular/core';
import { OhnApiService } from '../../services/ohn-api.service';
import { OhnService } from '../../services/ohn.service';
import { OhnLogicService } from '../../services/ohn-logic.service';
import { OHNElement, OHNLogicNode, OHNUser } from '../../models/ohn-instances';
import { ModalController } from '@ionic/angular';
import { BranchedContentRendererComponent } from '../branched-content-renderer/branched-content-renderer.component';
import { Subject} from 'rxjs';
import { OhnLanguageService } from '../../services/ohn-language.service';
import * as _ from 'underscore/underscore';

@Component({
  selector: 'app-roadmap',
  templateUrl: './roadmap.component.html',
  styleUrls: ['./roadmap.component.scss'],
})
 
export class RoadmapComponent implements OnInit {

  @Input() parentElementSlug: string;
  @Input() user: OHNUser;

  parentElement : OHNElement;

  currentStepSlug : string;

  currentStepKeeper : OHNElement;

  loading : boolean = true;

  constructor(
    public modalController: ModalController,
  	private ohnApi: OhnApiService,
  	private ohnService: OhnService,
    private lS: OhnLanguageService,
  ) { }

  ngOnInit() {
    this.loading = true;
    this.loadParentElement();
  }

  loadParentElement() {
    this.ohnApi.getElement(this.parentElementSlug, 3).subscribe(element => {
      this.parentElement = <OHNElement>element;
      this.currentStepKeeper = this.parentElement.elements.find((e)=>{return e.controller !== 'pageController'});
      this.parentElement.elements = this.parentElement.elements.filter((e)=>{return e.controller === 'pageController'});
      this.getCurrentStepSlug();
     /* if (this.parentElement.config && this.parentElement.config.tutorialSlug) {
        this.getTutorialState();
      }*/
      this.loading = false;
    });
  }

  getCurrentStepSlug() {
  	if (this.currentStepKeeper) {
  		this.ohnApi.getElementStateSc(this.currentStepKeeper.element_slug, this.user.smart_contract).subscribe(state =>{
  			this.currentStepSlug = state.value && state.value.value;
  		});
  	}
  }

  getTutorialState() {
    this.ohnApi.getElementStateSc(this.parentElement.config.tutorialSlug, this.user.smart_contract).subscribe(state =>{
      if (state.value && state.value['tutorial_82491'] && !state.value['tutorial_82491']['tutorial-2_13810']) {
        this.openTutorial();
      }
    });
  }

  async openTutorial() {
    const contentPage = await this.modalController.create({
      component: BranchedContentRendererComponent,
      componentProps: {
        parentElementSlug : this.parentElement.config.tutorialSlug,
        user : this.user
      },
      backdropDismiss: false
    });

    contentPage.onDidDismiss().then((data) => {
      this.ohnApi.setElementStateSc('tutorial-2_13810', {value : 'viewed'}, this.user.smart_contract).subscribe();
    })

    return await contentPage.present();
  }

  async openDetails(index: number) {

    const contentPage = await this.modalController.create({
      component: BranchedContentRendererComponent,
      componentProps: {
      	parentElement : this.parentElement.elements[index],
      	isSinglePage : true,
        user : this.user,
        hideDoneButton: true
      },
      backdropDismiss: false
    });

    contentPage.onDidDismiss().then((data) => {
   
    })

    return await contentPage.present();

  }

  async openScreening() {
    const contentPage = await this.modalController.create({
      component: BranchedContentRendererComponent,
      componentProps: {
      	parentElementSlug : this.parentElement.config.connectedSurveySlug,
        user : this.user
      },
      backdropDismiss: false
    });

    contentPage.onDidDismiss().then((data) => {
   
    })

    return await contentPage.present();
  }

  closeModal() {
    this.modalController.dismiss({
      dismissed: true
    });
  }
}
