import { Component, OnInit, Input } from '@angular/core';
import { OHNElement } from '../../../models/ohn-instances';

@Component({
  selector: 'app-speed-sensor-element-config',
  templateUrl: './speed-sensor-element-config.component.html',
  styleUrls: ['./speed-sensor-element-config.component.scss'],
})
export class SpeedSensorElementConfigComponent implements OnInit {

	@Input() el: OHNElement;

  constructor() { }

  ngOnInit() {

  }
}