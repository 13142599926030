import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { OHNUser, OHNElement } from '../../models/ohn-instances';
import { OhnApiService } from '../../services/ohn-api.service';
import { OhnService } from '../../services/ohn.service';
import { RoadmapComponent } from '../roadmap/roadmap.component';
import { BranchedContentRendererComponent } from '../branched-content-renderer/branched-content-renderer.component';
import { BookmarksComponent } from '../bookmarks/bookmarks.component';
import { HistoryRendererComponent } from '../history-renderer/history-renderer.component';

import * as _ from 'underscore/underscore';

@Component({
  selector: 'app-full-screen-menu',
  templateUrl: './full-screen-menu.component.html',
  styleUrls: ['./full-screen-menu.component.scss'],
})
export class FullScreenMenuComponent implements OnInit {

  loading : boolean = true;

	@Input() parentElement: OHNElement;
	@Input() user: OHNUser;

  constructor(
  	private modalController : ModalController,
  	private ohnApi: OhnApiService,
  	private ohnService: OhnService
  ) { }

  ngOnInit() {
  	this.loadParentElement();
  }

  loadParentElement() {
  	this.loading = true;
  	this.ohnApi.getElement(this.parentElement.element_slug, 1).subscribe(parentElement => {
  		this.parentElement = parentElement;
  		this.loading = false;
  	});
  }

  async openItem(item: OHNElement) {

  	let componentToOpen: any;

    const page = await this.modalController.create({
      component: ((item.controller === 'roadmapController') && RoadmapComponent) 
              || ((item.controller === 'branchedLogicController') && BranchedContentRendererComponent)
              || ((item.controller === 'branchedContentController') && BranchedContentRendererComponent)
              || ((item.controller === 'bookmarksController') && BookmarksComponent)
              || ((item.controller === 'historyController') && HistoryRendererComponent),
      componentProps: {
        parentElementSlug : ((item.controller === 'historyController') && item.config.elementSlug) || item.element_slug,
        user : this.user,
        isSinglePage : item.config.singlePage,
        viewOnly : item.config.viewOnly,
        cssClass: 'modal-fullscreen'
      },
      backdropDismiss: false
    });

    page.onDidDismiss().then((data) => {
    })

    return await page.present();
  }

}
