import { Component, OnInit, Input } from '@angular/core';
import { ModalController, AlertController } from '@ionic/angular';
import { OhnApiService } from '../../../services/ohn-api.service';
import { OHNElement, OHNUser } from '../../../models/ohn-instances';
import { NotesHistoryComponent } from '../../notes-history/notes-history.component';
import { OhnLanguageService } from '../../../services/ohn-language.service';
import * as _ from 'underscore/underscore';

@Component({
  selector: 'app-notes-element',
  templateUrl: './notes-element.component.html',
  styleUrls: ['./notes-element.component.scss'],
})
export class NotesElementComponent implements OnInit {

	@Input() el: OHNElement;
	@Input() viewMode : string;
	@Input() user : OHNUser;
  @Input() displayHistory: boolean = true;
  @Input() collapseAll : boolean = false;

	saving: boolean = false;
  notes : any[] = [];

  loading: boolean = true;

  constructor(
  	private ohnApi: OhnApiService,
  	public modalController: ModalController,
    private alertCtrl: AlertController,
    private lS: OhnLanguageService,
  ) { }

  ngOnInit() {
  	this.el.value = this.el.value || '';
    this.loadHistory();
    this.el.valid = true;
  }

  async viewNotes() {
    const historyPage = await this.modalController.create({
      component: NotesHistoryComponent,
      componentProps: {
        el : this.el,
        user : this.user
      },
      backdropDismiss: false
    });

    historyPage.onDidDismiss().then((data) => {
   
    })

    return await historyPage.present();
  }

  showNotes(){
    this.displayHistory = this.displayHistory ? false : true;
  }

  loadHistory() {
    this.loading = true;
    this.ohnApi.getElementHistoryScCostil(this.el.element_slug, 'all', 0, this.user.smart_contract).subscribe((history)=>{
      this.notes = [];
      if (history) {
        let keys : string[] = _.keys(history);
        keys.forEach((k)=>{
          this.notes.push({date: new Date(k), value : history[k].value, id :history[k].id});
        });
        this.notes.sort(function(a,b){
          return b.date - a.date;
        });
      }
      this.saving = false;
      this.loading = false;
    });
  }

  async showDeleteDialog(note: any) {
   const alert = await this.alertCtrl.create({
      header: this.lS.g('deleteNote'),
      message: this.lS.g('areYouSure'),
      buttons: [
        {
          text: this.lS.g('goBack'),
          cssClass: 'secondary',
          handler: (blah) => {
           
          }
        }, {
          text: this.lS.g('delete'),
          role: 'destructive',
          cssClass: 'secondary',
          handler: () => {
            this.deleteNote(note);
          }
        }
      ]
    });

    await alert.present();
  }

  deleteNote(note: any) {
    this.notes = _.filter(this.notes, (n)=>{return n.id != note.id});
    this.ohnApi.deleteElementStateSc(this.el.element_slug, {value: {id : note.id}}, this.user.smart_contract).subscribe(event => {
      
    });
  }

  saveNote() {
  	if (this.el.value && this.el.value != '') {
	  	this.saving = true;
	  	this.ohnApi.setElementStateSc(this.el.element_slug, {value : this.el.value}, this.user.smart_contract).subscribe(state => {
	      this.el.value = '';
        this.loadHistory();
	    });
	  }
  }
}