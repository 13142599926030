import { Component, OnInit, Input } from '@angular/core';
import { OHNElement } from '../../models/ohn-instances';
import { ModalController } from '@ionic/angular';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-notification-rules-modal',
  templateUrl: './notification-rules-modal.component.html',
  styleUrls: ['./notification-rules-modal.component.scss'],
})
export class NotificationRulesModalComponent implements OnInit {

  @Input() notificationsModifier : Subject<OHNElement>;
  @Input() notifications: OHNElement[];

  constructor(
    public modalController: ModalController
  ) { }

  ngOnInit() {}

  markAsRead(notification : OHNElement) {
    this.notifications = this.notifications.filter(n=>{
      return n.element_slug != notification.element_slug;
    });
    this.notificationsModifier.next(notification);
  }

  viewMore() {
    this.modalController.dismiss({
      dismissed: true,
      viewMore : true
    });
  }

  closeModal() {
    this.modalController.dismiss({
      dismissed: true
    });
  }

}
