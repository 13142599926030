import { Component, OnInit, Input } from '@angular/core';
import { AlertController, ModalController, PopoverController } from '@ionic/angular';
import { formatDate } from '@angular/common';
import { OHNElement, OHNUser, OHNCalendarEvent } from '../../../models/ohn-instances';
import { OhnApiService } from '../../../services/ohn-api.service';
import { Subject} from 'rxjs';
import { API_URL, DEBUG_MODE } from '../../../../environments/environment';
import { PopoverComponent } from '../../../components/basic/popover/popover.component';
import { CalendarEventViewPage } from '../../../secured/calendar-event-view/calendar-event-view.page';
import { BranchedContentRendererComponent } from '../../branched-content-renderer/branched-content-renderer.component';
import { AuthenticationService } from '../../../services/authentication.service';

@Component({
  selector: 'app-single-patient-survey-widget',
  templateUrl: './single-patient-survey-widget.component.html',
  styleUrls: ['./single-patient-survey-widget.component.scss'],
})
export class SinglePatientSurveyWidgetComponent implements OnInit {

  @Input() el: OHNElement;
  @Input() me: OHNUser;
  @Input() refresher : Subject<boolean>;

  currentScreenState : string = 'patientHome';

  loading : boolean = true;

  noEmail : boolean = false;

  userGroup : string = 'undefined';

  interventionState : number = 2; //0 : 3 sessions completed; 1 : less than 3 completed, but the latest was < 7 days ago; 2 : ready for follow-up session

  initSession : any = {
    surveySlug : 'session-1-79074',
    reportSlug : 'reportsession_11626200144509',
    title : 'Baseline Session'
  }

  followUpSession : any = {
    surveySlug : 'returning-session-16813',
    reportSlug : 'reportreturning_session1638876319713',
    title : 'Follow Up'
  }

  session : any;

  API_URL = API_URL;

  constructor(
    private alertController: AlertController,
    private ohnApi: OhnApiService,
    private auth: AuthenticationService,
    public modalController: ModalController,
    public popoverController: PopoverController
  ) { }

  ngOnInit() {
    this.clean();
    if (this.me.elements['intervention-control-randomizati-15683'] === 1  && this.me.histories['html_printout_intervention'] && this.me.histories['html_printout_intervention'].length > 0) {
      this.userGroup = 'intervention';
      this.getInterventionState();
    }
    if ((this.me.elements['intervention-control-randomizati-15683'] === 0 || this.me.elements['intervention-control-randomizati-15683'] === undefined) && this.me.histories['html_printout_intervention'] && this.me.histories['html_printout_intervention'].length > 0) {
      this.userGroup = 'control';
    }

    if (this.me.elements['intervention-control-randomizati-15683'] === undefined && this.me.histories['html_printout_intervention'] && this.me.histories['html_printout_intervention'].length > 0) {
      this.userGroup = 'notAvailable';
    }

    this.currentScreenState = 'patientHome';
    this.loading = false;
  }

  startSurvey() {
    this.loading = true;
    this.session = (this.me.histories && (this.me.histories.html_printout_intervention || this.me.histories.html_printout_followup)) ? this.followUpSession : this.initSession;
    this.scheduleEvent(this.generateEvent());
  }

  clean() {
    this.userGroup = 'undefined';
    this.interventionState = 2;
    localStorage.removeItem('ohn-radonc-printout-recently-generated');
    localStorage.removeItem('ohn-radonc-group');
  }

  getInterventionState() {
    let daysDifference : number = 0;
    let lastRportDate : Date;

    if (this.me.histories && this.me.histories != null && this.me.histories['html_printout_followup'] && this.me.histories['html_printout_followup'].length > 0) {
      lastRportDate = new Date(this.me.histories['html_printout_followup'][this.me.histories['html_printout_followup'].length - 1].timestamp);
    } else if (this.me.histories && this.me.histories != null && this.me.histories['html_printout_intervention'] && this.me.histories['html_printout_intervention'].length > 0) {
      lastRportDate = new Date(this.me.histories['html_printout_intervention'][this.me.histories['html_printout_intervention'].length - 1].timestamp);
    }

    if (lastRportDate) {
      daysDifference = (new Date().getTime() - lastRportDate.getTime()) / (1000 * 3600 * 24);
    }

    if (this.me.histories && this.me.histories != null && this.me.histories['html_printout_followup'] && this.me.histories['html_printout_followup'].length >= 2) {
      this.interventionState = 0;
    }
    if (this.me.histories && this.me.histories != null 
      && (
        (this.me.histories['html_printout_followup'] && this.me.histories['html_printout_followup'].length < 2)
        || ((!this.me.histories['html_printout_followup'] || this.me.histories['html_printout_followup'].length == 0) && this.me.histories['html_printout_intervention'] && this.me.histories['html_printout_intervention'].length > 0))
      && daysDifference < 7) {
      this.interventionState = 1;
    }
  }

  generateEvent(){
    let event = new OHNCalendarEvent({
      title : this.session.title,
      startTime : new Date(),
      completed : false,
      allDay: true,
      controller : 'survey',
      inner_element_slug : this.session.surveySlug,
      inner_element_state_id : '',
      report_element_slug : this.session.reportSlug,
      report_element_state_id : '',
      content : ''
    });
    return event;
  }

  scheduleEvent(event: any) {
    this.ohnApi.setElementStateSc('calendar_container', {value: event}, this.me.smart_contract).subscribe(newEvent => {
      this.openEventModal(new OHNCalendarEvent(newEvent));
      this.loading = false;
    });
  }

  async openEventModal(event: any) {
    /*const calendarEventPage = await this.modalController.create({
      component: CalendarEventViewPage,
      componentProps: {
        'calendarElement' : {element_slug:'calendar_container'},
        'user' : this.currentPatient,
        'event' : event,
        'skipResultScreen' : true
      },
      cssClass: 'modal-fullscreen',
      backdropDismiss: false
    });*/
    const contentPage = await this.modalController.create({
      component: BranchedContentRendererComponent,
      componentProps: {
        parentElementSlug : event.inner_element_slug,
        user : this.me,
        event : event,
        hideCloseButton: true
      },
      cssClass: 'modal-fullscreen',
      backdropDismiss: false
    });

    contentPage.onDidDismiss().then((data) => {
      if (data.data.finished) {
        this.loadThankYouPage()
      }
    })

    return await contentPage.present();
  }

  loadThankYouPage() {
    this.loading = true;
    this.ohnApi.getMe().subscribe(user=>{
      this.me = <OHNUser>user;
      this.currentScreenState = 'patientThankYou';
      this.loading = false;
    })
  }

  complete() {
    if (this.me.elements['intervention-control-randomizati-15683'] == 1) {
      this.userGroup = 'intervention';
      this.getInterventionState();
    }

    if (this.me.elements['intervention-control-randomizati-15683'] == 0) {
      this.userGroup = 'control';
    }

    if (this.me.elements['intervention-control-randomizati-15683'] === undefined && this.me.histories['html_printout_intervention'] && this.me.histories['html_printout_intervention'].length > 0) {
      this.userGroup = 'notAvailable';
      console.log('NA');
    }

    this.currentScreenState = 'patientHome';
  }

  async logoutNotification() {
    const alert = await this.alertController.create({
      header: 'Warning',
      message: 'Are you sure you want to logout?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Yes',
          role: 'confirm',
          handler: () => {
            this.auth.logout();
          }
        },
      ],
      backdropDismiss : false,
    });

    await alert.present();
  }

  async openPrint(ev: any) {

    let pdfList = [];

    if (this.me.histories.html_printout_intervention) {

      let printout = {
        category: "Baseline session",
        links: []
      }

      this.me.histories.html_printout_intervention.forEach( pdf => {
        let linkItem = {
          link: API_URL + '/reports/' + pdf.value[0],
          text: formatDate(new Date(pdf.timestamp + 'Z'), 'MM/dd/yyyy h:mm a', 'en')
        }
        printout['links'].push(linkItem);
      });

      pdfList.push(printout);
    }

    if (this.me.histories.html_printout_followup) {

      let printout = {
        category: "Follow Up session",
        links: []
      }

      this.me.histories.html_printout_followup.forEach( pdf => {
        let linkItem = {
          link: API_URL + '/reports/' + pdf.value[0],
          text: formatDate(new Date(pdf.timestamp + 'Z'), 'MM/dd/yyyy h:mm a', 'en')
        }
        printout['links'].push(linkItem);
      });

      pdfList.push(printout);

    }

    const popover = await this.popoverController.create({
      component: PopoverComponent,
      cssClass: 'ohn-widget-print-popover',
      event: ev,
      animated: true,
      componentProps: {
        dataType: 'print',
        inputData: pdfList
      },
      translucent: true
    });
    await popover.present();
  }

}
