import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { OHNElement, OHNEventPreset, OHNEventSequence, OHNUser } from '../../models/ohn-instances';
import { BehaviorSubject, Subject } from 'rxjs';
import { OhnLanguageService } from '../../services/ohn-language.service';

import * as _ from 'underscore/underscore';

enum Views { ListAll, PrescribedParameters, MedicationPrescriptions, MeetingPrescriptions }

@Component({
  selector: 'app-calendar-event-scheduler',
  templateUrl: './calendar-event-scheduler.component.html',
  styleUrls: ['./calendar-event-scheduler.component.scss'],
})

export class CalendarEventSchedulerComponent implements OnInit {

	@Input() workoutList: OHNElement;
	@Input() surveyList: OHNElement;
	@Input() meetingList: OHNEventPreset[];
	@Input() medicationList: OHNEventPreset[];
	@Input() sequenceList: OHNEventSequence[];
  @Input() textMessageList: OHNEventPreset[];
  @Input() instacartList: OHNEventPreset[];
	@Input() selectedDate: Date;
	@Input() calendarUpdater : Subject<any>;
  @Input() calendarElement : OHNElement;
  @Input() user: OHNUser;
	@Input() viewType : string;

	Views = Views;

	currentEventElement : OHNElement | OHNEventPreset;

	currentType : string;

	eventExtras : any = {};

	uniqueSequenceEvents : any[] = [];

	sequenceEventToEditIndex : number = 0;

	currentSequence : OHNEventSequence;

	currentView = new BehaviorSubject<Views>(Views.ListAll);

  constructor(
    public modalController: ModalController,
    private lS: OhnLanguageService,
  ) { }

  ngOnInit() {
  	this.eventExtras.startTime = this.selectedDate.toISOString();
  }

  async scheduleEvent() {
  	await this.modalController.dismiss({
  		element : this.currentEventElement,
  		type : this.currentType,
  		eventExtras : this.eventExtras
  	});
  }

  async scheduleSequence() {
  	await this.modalController.dismiss({
  		sequence : this.currentSequence,
  		type : this.currentType,
  		uniqueEvents : this.uniqueSequenceEvents
  	});
  }

  processElementClick(el : OHNElement | OHNEventPreset | OHNEventSequence, type: string) {
  	this.currentType = type;
  	if (['workout', 'survey'].indexOf(type) >= 0) {
  		this.currentEventElement = <OHNElement>el;
  		if (this.viewType === 'calendar') {
  			this.currentView.next(Views.PrescribedParameters);
  		} else {
  			this.scheduleEvent();
  		}
  	}
  	if (['medication', 'meeting'].indexOf(type) >= 0) {
  		this.currentEventElement = <OHNEventPreset>el;
  		if (this.viewType === 'calendar') {
  			this.initMeetingOrMed();
  			this.currentView.next(Views.PrescribedParameters);
  		} else {
  			this.scheduleEvent();
  		}
  	}
    if (['text_message'].indexOf(type) >= 0) {
      this.currentEventElement = <OHNEventPreset>el;
      if (this.viewType === 'calendar') {
        this.currentView.next(Views.PrescribedParameters);
      } else {
        this.scheduleEvent();
      }
    }
    if (['instacart_instance'].indexOf(type) >= 0) {
      this.currentEventElement = <OHNEventPreset>el;
      if (this.viewType === 'calendar') {
        this.currentView.next(Views.PrescribedParameters);
      } else {
        this.scheduleEvent();
      }
    }
  	if (type === 'sequence') {
  		this.prepareSequenceElements(el);
  	}
  	//this.scheduleEvent(el, type);
  }

  prepareSequenceElements(sequence : any) {
  	this.currentSequence = <OHNEventSequence>sequence;
  	let keys : string[] = _.keys(sequence.days);
  	keys.forEach((k)=>{
  		this.uniqueSequenceEvents = _.union(this.uniqueSequenceEvents, sequence.days[k]);
  	});
  	this.uniqueSequenceEvents = _.unique(this.uniqueSequenceEvents, (e)=>{return e.elementId});
  	this.uniqueSequenceEvents.forEach((e)=>{
  		switch (e.type) {
  			case 'workout':
  				e.element = this.workoutList.elements.find((el)=>{return el.element_slug === e.elementId});
  				e.eventExtras = {};
  				e.eventExtras.startTime = new Date(this.selectedDate.getTime() + 10*3600000).toISOString();
			    e.eventExtras.endTime = new Date(this.selectedDate.getTime() + 10*3600000 + 900000).toISOString();
  				break;
  			case 'survey':
  				e.element = this.surveyList.elements.find((el)=>{return el.element_slug === e.elementId});
  				e.eventExtras = {};
  				e.eventExtras.startTime = new Date(this.selectedDate.getTime() + 10*3600000).toISOString();
          e.eventExtras.endTime = new Date(this.selectedDate.getTime() + 10*3600000 + 900000).toISOString();
  				break;
        case 'text_message':
          e.element = this.textMessageList.find((el)=>{return el.id === e.elementId});
          e.eventExtras = {};
          e.eventExtras.startTime = new Date(this.selectedDate.getTime() + 10*3600000).toISOString();
          e.eventExtras.endTime = new Date(this.selectedDate.getTime() + 10*3600000 + 900000).toISOString();
          break;
        case 'instacart_instance':
          e.element = this.instacartList.find((el)=>{return el.id === e.elementId});
          e.eventExtras = {};
          e.eventExtras.startTime = new Date(this.selectedDate.getTime() + 10*3600000).toISOString();
          e.eventExtras.endTime = new Date(this.selectedDate.getTime() + 10*3600000 + 900000).toISOString();
          break;
  			case 'medication':
  				e.element = this.medicationList.find((el)=>{return el.id === e.elementId});
  				e.eventExtras = {};
  				e.eventExtras.weekDaysToTake = [true, false, false, false, false, false, false];
					e.eventExtras.intakeTimes = [];
			  	e.eventExtras.startTime = new Date(this.selectedDate).toISOString();
			    e.eventExtras.endTime = new Date(this.selectedDate.getTime() + 900000).toISOString();
			    e.eventExtras.intakeTimes.push({value : "2020-01-01T08:00:00"});
  				break;
  			case 'meeting':
  				e.element = this.meetingList.find((el)=>{return el.id === e.elementId});
  				e.eventExtras = {};
  				e.eventExtras.weekDaysToTake = [true, false, false, false, false, false, false];
					e.eventExtras.intakeTimes = [];
			  	e.eventExtras.startTime = new Date(this.selectedDate).toISOString();
			    e.eventExtras.endTime = new Date(this.selectedDate.getTime() + 900000).toISOString();
			    e.eventExtras.intakeTimes.push({value : "2020-01-01T08:00:00"});
  				break;

  			default:
  				
  				break;
  		}
  	});
  	this.sequenceEventToEditIndex = -1;
  	this.processSequenceEvent();
  }

  processSequenceEvent() {
  	if (this.sequenceEventToEditIndex == (this.uniqueSequenceEvents.length - 1)) {
  		this.scheduleSequence();
  	} else {
  		this.sequenceEventToEditIndex++;
  		this.currentEventElement = this.uniqueSequenceEvents[this.sequenceEventToEditIndex].element;
  		this.eventExtras = this.uniqueSequenceEvents[this.sequenceEventToEditIndex].eventExtras;
  		this.currentView.next(Views.PrescribedParameters);
  	}
  }

  openMedicationManagement() {
  	this.currentView.next(Views.MedicationPrescriptions);
  }

  openMeetingManagement() {
  	this.currentView.next(Views.MeetingPrescriptions);
  }

  sequenceBack() {
  	if (this.sequenceEventToEditIndex == 0) {
  		this.back();
  	} else {
  		this.sequenceEventToEditIndex--;
  		this.currentEventElement = this.uniqueSequenceEvents[this.sequenceEventToEditIndex].element;
  		this.eventExtras = this.uniqueSequenceEvents[this.sequenceEventToEditIndex].eventExtras;
  	}
  }

  hasVariablePrescribedParameters(el : OHNElement) : boolean {
  	let res: boolean = false;
  	if (el.report_element) {
  		el.report_element.elements.forEach(e=>{
  			if (e.config && e.config.hasPrescribedParameters && e.config.prescribedParameterVaries) res = true;
  		});
  	}
  	return res;
  }

  initMeetingOrMed() {
  	this.eventExtras.weekDaysToTake = [true, false, false, false, false, false, false];
		this.eventExtras.intakeTimes = [];
  	this.eventExtras.startTime = this.selectedDate.toISOString();
    this.eventExtras.endTime = new Date(this.selectedDate.getTime() + 31536000000).toISOString();
    this.addIntakeTime();
  }

  addIntakeTime() {
    this.eventExtras.intakeTimes.push({value : "2020-01-01T08:00:00"});
  }

  deleteIntakeTime(index: number) {
    this.eventExtras.intakeTimes.splice(index, 1);
  }

  back() {
  	this.currentView.next(Views.ListAll);
  }

  closeModal() {
  	this.modalController.dismiss();
  }
}
