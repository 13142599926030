import { Component, OnInit, Input } from '@angular/core';
import { OHNElement, OHNUser } from '../../models/ohn-instances';
import { OhnApiService } from '../../services/ohn-api.service';
import { API_URL, DEBUG_MODE } from '../../../environments/environment';
import { OhnLanguageService } from '../../services/ohn-language.service';

@Component({
  selector: 'app-fitbit-device-pairing',
  templateUrl: './fitbit-device-pairing.component.html',
  styleUrls: ['./fitbit-device-pairing.component.scss'],
})
export class FitbitDevicePairingComponent implements OnInit {

	@Input() el: OHNElement;
  @Input() user: OHNUser;

	pairingCode : string = "";
  errorMsg: string = "";

  devices: any = [];

	loading : boolean = false;

  syncURL : string;

  fitbitSynced : boolean = false;

  fitBitDevice : any;

  constructor(
  	private ohnApi: OhnApiService,
    private lS: OhnLanguageService,
  ) {}

  ngOnInit() {
    this.loadPairedDevices();
  }

  loadPairedDevices() {
    this.loading = true;
    this.ohnApi.getFitbitPairedDevices().subscribe(devices => {
      DEBUG_MODE && console.log('Devices', devices);
      this.devices = devices;
      this.fitBitDevice = this.devices.find((d)=>{return d.tmp_code !== undefined});
      this.loading = false;
      this.errorMsg = "";
      this.getSyncURL();// oauth_ready = true
    });
  }
 
  getSyncURL(){
    this.ohnApi.getFitbitSyncURL(this.user.smart_contract).subscribe(url => {
      this.fitbitSynced = url.oauth_ready;
      this.syncURL = url.oauth_url;
    })
  }

  forgetPairedDevice() {
    this.loading = true;
    if (this.fitBitDevice) {
      this.ohnApi.deleteUserDevice(this.fitBitDevice.device_uuid, this.user.smart_contract).subscribe(devices => {
        this.fitBitDevice = undefined;
        this.loadPairedDevices();
      });
    }
  }

  getPairingCode() {
  	this.loading = true;
  	this.ohnApi.getFitbitPairingCode().subscribe(code => {
      this.pairingCode = code.device_code;
      this.loading = false;
    });
  }

  setPairingCode() {
    this.loading = true;
    this.ohnApi.setFitbitPairingCode(this.pairingCode).subscribe(code => {
      this.loadPairedDevices();
    },
    error => {
      this.errorMsg = error.error.message;
      this.loading = false;
    });
  }

}
