import { Component, OnInit, Input } from '@angular/core';
import { OhnLogicService } from '../../../services/ohn-logic.service';
import * as _ from 'underscore/underscore';

@Component({
  selector: 'app-pick-many-element',
  templateUrl: './pick-many-element.component.html',
  styleUrls: ['./pick-many-element.component.scss'],
})
export class PickManyElementComponent implements OnInit {

  @Input() el: any;
  @Input() viewMode : string;

  valueInterface: any = {};

  slugs: string[];

  validityRules : any[];

  disableChoices : boolean = false;

  hasDependencies : boolean = false;

  constructor(
    private ohnLogicService: OhnLogicService,
  ) { }

  updateValue(choicekey) {
    this.el.value = _.filter(this.slugs, (slug) => { return this.valueInterface[slug]});
    this.el.valid = this.ohnLogicService.processValidityRules(this.validityRules, this.el.controller, this.el.value);
    if (this.hasDependencies) {
      this.processDependencies(choicekey);
    }
    this.el.elements.forEach((e)=>{
      if (e.config.disableWhenOtherChecked) {
        let disabled : boolean = false;
        e.config.disableWhenOtherChecked.forEach((s)=>{
          if (this.valueInterface[s]) {
            disabled = true
          }
        })
        e.valid = !disabled;
      }
    })
  }

  processDependencies(choiceSlug: string) {
    if (this.el.config.counterDisableDependency) {
      /*let currentCounerValue : number = this.ohnLogicService.getValueFromElementContainer(this.el.config.counterDisableDependency.counterSlug);
      if (this.valueInterface[choiceSlug]) {
        currentCounerValue += 1;
      } else {
        currentCounerValue -= 1;
      }
      console.log(currentCounerValue);
      this.disableChoices = currentCounerValue >= this.el.config.counterDisableDependency.maxValue ? true : false;
      console.log(this.disableChoices);*/
      this.ohnLogicService.setValueOfElementContainer(this.el.config.counterDisableDependency.counterSlug, this.el.value.length);
      this.disableChoices = this.el.value.length >= this.el.config.counterDisableDependency.maxValue ? true : false;
    }

    if (this.el.config.onChangeDependencies) {
      if (this.el.config.onChangeDependencies[choiceSlug]) {
        const checked = this.valueInterface[choiceSlug] ? "checked" : "unchecked";
        this.el.config.onChangeDependencies[choiceSlug][checked].dependants.forEach((d)=>{
          this.ohnLogicService.setValueOfElementContainer(d.elementSlug, d.value);
        })
      }
    }
  }

  ngOnInit() {
    this.el.value = this.el.value ? this.el.value : [];

    if (this.el.config.counterDisableDependency) {
      this.ohnLogicService.setValueOfElementContainer(this.el.config.counterDisableDependency.counterSlug, this.el.value.length);
      let currentCounerValue : number = this.ohnLogicService.getValueFromElementContainer(this.el.config.counterDisableDependency.counterSlug);
    }

    this.el.valid = true;

    this.el.elements.forEach((e)=>{
      e.valid = true;
    })

    this.hasDependencies = this.el.config.counterDisableDependency || this.el.config.onChangeDependencies;

    this.slugs = _.map(this.el.elements, (el) => {return el.element_slug});

    for (let e of this.el.elements) {
      this.valueInterface[e.element_slug] = this.el.value.indexOf(e.element_slug) >= 0;
    }

    this.validityRules = (this.el.config && this.el.config.validityRules) ? this.el.config.validityRules : [];
    if (this.validityRules.length > 0) {
      this.el.valid = this.ohnLogicService.processValidityRules(this.validityRules, this.el.controller, this.el.value);
    }
  }

}
