import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-jinia-template-element',
  templateUrl: './jinia-template-element.component.html',
  styleUrls: ['./jinia-template-element.component.scss'],
})
export class JiniaTemplateElementComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
