import { Component, OnInit, Input } from '@angular/core';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { ToastController, ModalController } from '@ionic/angular';
import { OHNElement, OHNCalendarEvent, OHNMedication, OHNMeeting, OHNUser } from '../../../models/ohn-instances';
import { OhnApiService } from '../../../services/ohn-api.service';
import { OhnService } from '../../../services/ohn.service';
import { NewMeetingComponent } from '../../new-meeting/new-meeting.component';
import { ActionsProcessorService } from '../../../services/actions-processor.service';
import * as _ from 'underscore/underscore';
import { Subject} from 'rxjs';


@Component({
  selector: 'app-meetings-widget',
  templateUrl: './meetings-widget.component.html',
  styleUrls: ['./meetings-widget.component.scss'],
})
export class MeetingsWidgetComponent implements OnInit {

	@Input() el: OHNElement;
  @Input() refresher : Subject<boolean>;
  @Input() me: OHNUser;

  meetings: OHNMeeting[];

	calendarElement: OHNElement;

	loading: boolean = false;

  constructor(
  	private ohnApi: OhnApiService,
    private ohnService: OhnService,
    private toastController : ToastController,
    private modalController : ModalController,
    private actionsProcessor : ActionsProcessorService,
    private iab: InAppBrowser
  ) { }

  ngOnInit() {
  	this.loadCalendarHistory();
  	this.loadCalendarElement();
  	this.ohnService.widgetRefresher.subscribe(refreshing => {
      this.loadCalendarHistory();
      this.loadCalendarElement();
    });
    this.refresher.subscribe(refreshing => {
      this.loadCalendarHistory();
      this.loadCalendarElement();
    });
  }

  async openMeetingModal(isNew: boolean, meeting: OHNMeeting) {
    const editMedPage = await this.modalController.create({
      component: NewMeetingComponent,
      componentProps: {
        'calendarElement' : this.calendarElement,
        'user' : this.me,
        'isNew' : isNew,
        'editMeeting' : meeting
      },
      backdropDismiss: false
    });

    editMedPage.onDidDismiss().then((data) => {
      this.ohnService.widgetRefresher.next();
    })

    return await editMedPage.present();
  }

  loadCalendarHistory() {
  	this.loading = true;
  	this.ohnApi.getElementHistory(this.el.config.medication_slug, 'day', 0).subscribe(history => {
      let meetings: any[] = (history && _.isArray(history)) ? _.filter(history, (h)=>{return h.controller == 'meeting'}) : [];
      this.meetings = _.map(meetings, (s)=>{return new OHNMeeting(s, true, true)});
      this.loading = false;
    });
  }

  loadCalendarElement() {
  	this.ohnApi.getElement(this.el.config.medication_slug, 3).subscribe(calendar => {
      this.calendarElement = calendar;
    });
  }

  completeMeeting(meeting : OHNMeeting) {
    if (!meeting.completed) {
      //this.meetings = _.filter(this.meetings, (i)=>{return i.id != meeting.id});
      this.notifyOfIntake(meeting);
      this.ohnApi.patchElementStateSc(this.calendarElement.element_slug, {value: {id : meeting.id, completed: true}}, this.me.smart_contract).subscribe(state => {
        
      });
      
      if (this.calendarElement.actions && this.calendarElement.actions.meeting && this.calendarElement.actions.meeting.triggers && this.calendarElement.actions.meeting.triggers.completed) {
        this.actionsProcessor.processActions(this.calendarElement.actions.meeting.triggers.completed, {}, this.me);
      }
    }
  }

  openMeetingLink(meeting : OHNMeeting) {
  	//window.open(intake.contentObject.url, '_system');
  	const browser = this.iab.create(meeting.contentObject.url, '_system');
  }

  async notifyOfIntake(prescription: OHNMeeting) {
    const toast = await this.toastController.create({
      message: '<strong>'+ prescription.title +' : has been marked as visited.',
      duration: 2000
    });
    toast.present();
  }

}
