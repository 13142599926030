import { Component, OnInit, Input } from '@angular/core';
import { ToastController, ModalController } from '@ionic/angular';
import { OhnApiService } from '../../services/ohn-api.service';
import { OHNElement, OHNUser } from '../../models/ohn-instances';
import * as _ from 'underscore/underscore';
import { DEBUG_MODE } from '../../../environments/environment';

@Component({
  selector: 'app-assessment-renderer',
  templateUrl: './assessment-renderer.component.html',
  styleUrls: ['./assessment-renderer.component.scss'],
})
export class AssessmentRendererComponent implements OnInit {

	@Input() parentSlug: string;
	@Input() user: OHNUser;

  parentElement: OHNElement;

	loading: boolean = true;

  constructor(
    private ohnApi: OhnApiService,
    private toastController: ToastController,
    public modalController: ModalController,
  ) { }

  loadParentElement(slug: string) {
    this.ohnApi.getElement(slug, 3).subscribe(parentElement => {
      this.parentElement = parentElement;
      this.loading = false;
      DEBUG_MODE && console.log('Parent Element',this.parentElement);
    });
  }

  ngOnInit() {
    this.loadParentElement(this.parentSlug);
  }

  save() {
		let values: any = _.object(_.map(this.parentElement.elements, (e) => { return [e.element_slug, e.value] }));

    this.ohnApi.setElementStateSc(this.parentSlug, {value: values}, this.user.smart_contract).subscribe(event => {
      this.notifyOfSaved();
      this.closeModal();
    });
  }

  async notifyOfSaved() {
  	const toast = await this.toastController.create({
      message: 'Your response has been saved',
      duration: 2000
    });
    toast.present();
  }

  closeModal() {
    this.modalController.dismiss({
      dismissed: true
    });
  }
}