import { Component, OnInit, Input } from '@angular/core';
import * as _ from 'underscore/underscore';

@Component({
  selector: 'app-height-input-element',
  templateUrl: './height-input-element.component.html',
  styleUrls: ['./height-input-element.component.scss'],
})
export class HeightInputElementComponent implements OnInit {

	@Input() el: any;
	@Input() viewMode : string;

  constructor() { }

  ngOnInit() {
  	this.el.value = (!this.el.value || _.isEmpty(this.el.value)) ? {feet: undefined, inches: undefined} : this.el.value;
  	if (_.isString(this.el.value)) {
  		this.el.value = JSON.parse(this.el.value);
  	}
    this.el.valid = true;
  }

}
