import { Component, OnInit, Input } from '@angular/core';
import { OhnLogicService } from '../../../services/ohn-logic.service';
import { OHNElement } from '../../../models/ohn-instances';

@Component({
  selector: 'app-period-to-days-selector',
  templateUrl: './period-to-days-selector.component.html',
  styleUrls: ['./period-to-days-selector.component.scss'],
})
export class PeriodToDaysSelectorComponent implements OnInit {

  @Input() el: any;
  @Input() viewMode : string;
  @Input() allElements: OHNElement[];

  multiplier : string = '1';
  inputValue : number;

  validityRules : any[];

  selectedDate : any;

  maxDate: string;

  minDate: string;

  constructor(
    private ohnLogicService: OhnLogicService,
  ) { }

  ngOnInit() {
    this.maxDate = new Date().toISOString().split('T')[0];
    this.minDate = new Date(new Date(this.maxDate).getTime() - 365*24*3600000).toISOString().split('T')[0];

    if (this.allElements['in-the-last-30-days-have-you-sm-28386'] && this.allElements['in-the-last-30-days-have-you-sm-28386'].value && this.allElements['in-the-last-30-days-have-you-sm-28386'].value == "not-at-all-former-smoker-94516") {
      this.maxDate = new Date(new Date().getTime() - 30*24*3600000).toISOString().split('T')[0];
    }

    if (this.allElements['in-the-last-30-days-have-you-sm-28386'] && this.allElements['in-the-last-30-days-have-you-sm-28386'].value && this.allElements['in-the-last-30-days-have-you-sm-28386'].value == "some-days-current-smoker-62428") {
      this.minDate = new Date(new Date().getTime() - 30*24*3600000).toISOString().split('T')[0];
    }

    this.el.value = this.el.value || 0;
    this.el.valid = true;
    this.validityRules = (this.el.config && this.el.config.validityRules) ? this.el.config.validityRules : [];
    if (this.validityRules.length > 0) {
      this.el.valid = this.ohnLogicService.processValidityRules(this.validityRules, this.el.controller, this.el.value);
    }
  }

  changed() {
    this.el.value = Math.floor((new Date().getTime() - new Date(this.selectedDate).getTime()) / 86400000);
    //this.el.value = this.inputValue * parseInt(this.multiplier);
    this.el.valid = this.ohnLogicService.processValidityRules(this.validityRules, this.el.controller, this.el.value);
  }

}
