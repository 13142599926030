import { Component, OnInit, Input } from '@angular/core';
import { OhnApiService } from '../../services/ohn-api.service';
import { OhnService } from '../../services/ohn.service';
import { OHNElement, OHNUser } from '../../models/ohn-instances';
import * as _ from 'underscore/underscore';

@Component({
  selector: 'app-user-report-renderer',
  templateUrl: './user-report-renderer.component.html',
  styleUrls: ['./user-report-renderer.component.scss'],
})
export class UserReportRendererComponent implements OnInit {

	@Input() el: OHNElement;
	@Input() user: OHNUser;

	reportElement : OHNElement;

	userProfile: any = [];
	reportFields: any = [];

	loading: boolean = true;

  constructor(
  	private ohnApi: OhnApiService,
    public ohnService: OhnService
  ) {
  }

  ngOnInit() {
  	this.getParentElement();
  }

  getParentElement() {
  	this.ohnApi.getElement(this.el.element_slug, 2).subscribe(element => {
  		this.el = element;
  		this.loadReportElement();
  	});
  }

  loadReportElement() {
  	this.ohnApi.getElement(this.el.config.reportElementSlug, 2).subscribe(element => {
  		this.reportElement = element;
  		this.ohnApi.getPatientReport(this.el.config.reportElementSlug, this.user.smart_contract).subscribe(report => {
	  		this.parseReport(report);
	  	});
  	});
  }

  parseReport(report: any){
  	if (report) {
  		let profileFields : string[] = _.keys(report.profile);
  		_.each(profileFields, (f)=>{
  			this.userProfile.push({
  				field : f,
  				value : report.profile[f]
  			});
  		});
  		_.each(this.reportElement.elements, (element) => {
  			element.report = [];
  			if (report[this.el.config.reportElementSlug][element.element_slug]) {
  				let historyKeys : string[] = _.keys(report[this.el.config.reportElementSlug][element.element_slug]);
  				_.each(historyKeys, (h)=>{
		  			element.report.push({
		  				date : new Date(h),
		  				value : report[this.el.config.reportElementSlug][element.element_slug][h]
		  			});
		  		});
  			}
  		});
  		this.loading = false;
  	}
  }
}