import { Component, OnInit } from '@angular/core';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { OhnApiService } from '../../services/ohn-api.service';
import { OHNElement, OHNUser } from '../../models/ohn-instances';
import * as _ from 'underscore/underscore';

@Component({
  selector: 'app-page-elements-renderer',
  templateUrl: './page-elements-renderer.page.html',
  styleUrls: ['./page-elements-renderer.page.scss'],
})
export class PageElementsRendererPage implements OnInit {

	parentElement: OHNElement;

	loading: boolean = true;

  constructor(
  	private router: Router,
    private activatedRoute: ActivatedRoute,
    private ohnApi: OhnApiService
  ) { }

  loadParentElement(slug: string) {
    this.ohnApi.getElement(slug, 3).subscribe(parentElement => {
      this.parentElement = parentElement;
      this.loading = false;
    });
  }

  ngOnInit() {
  	let parentSlug: string = this.activatedRoute.snapshot.paramMap.get('elementSlug');
    this.loadParentElement(parentSlug);
  }

}
