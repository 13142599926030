import { Component, OnInit, Input } from '@angular/core';
import { formatDate } from '@angular/common';
import { Subject } from 'rxjs';
import * as _ from 'underscore/underscore';

@Component({
  selector: 'app-stats-chart',
  templateUrl: './stats-chart.component.html',
  styleUrls: ['./stats-chart.component.scss'],
})
export class StatsChartComponent implements OnInit {

	@Input() chartData : any;
	@Input() dataMappingKeys : any;
	@Input() sortByDate : boolean;
	@Input() chartType : string;
	@Input() chartUpdater : Subject<any>;
	@Input() chartOptions : any;

	renderOptions : any;

	constructor() { 

	}

	ngOnInit() {
		if (this.chartType != 'dataFrame') {
			this.loadDefaultChart();
		}
		if (this.chartUpdater){
			this.chartUpdater.subscribe((d)=>{
				switch (this.chartType) {
					case "dataFrame":
						this.updateFrameChartData(d);
						break;
					
					default:
						this.chartData = d;
						this.loadDefaultChart();
						break;
				}
			});
		}
	}

	loadDefaultChart() {
		let xKey: string = this.dataMappingKeys ? this.dataMappingKeys.date : 't';
		let yKey: string = this.dataMappingKeys ? this.dataMappingKeys.value : 'h';

		if (this.sortByDate) {
			this.chartData = _.sortBy(this.chartData, (d)=>{ return new Date(d[xKey])});
		}

		let xVals = this.chartData.map(function (item) {
			return item[xKey].toLocaleString();
		});

		let yVals = this.chartData.map(function (item) {
			return item[yKey];
		});

		this.renderOptions = {
			xAxis: {
				data: xVals
			},
			yAxis: {
				type: 'value'
			},
			dataZoom: [{
        type: 'inside',
        realtime : true,
        start: (this.chartOptions ? this.chartOptions.dataZoom.start : 90),
        end: (this.chartOptions ? this.chartOptions.dataZoom.end : 100)
	    }, {
	        start: (this.chartOptions ? this.chartOptions.dataZoom.start : 90),
        	end: (this.chartOptions ? this.chartOptions.dataZoom.end : 100),
	        handleIcon: 'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
	        handleSize: '80%',
	        handleStyle: {
	            color: '#fff',
	            shadowBlur: 3,
	            shadowColor: 'rgba(0, 0, 0, 0.6)',
	            shadowOffsetX: 2,
	            shadowOffsetY: 2
	        }
	    }],
			series: [{
				data: yVals,
				type: 'line',
        		symbol: 'none',
				lineStyle: {color: '#33a0bb'}
			}]
		}
	}

	updateFrameChartData(data: any) {
		if (data.value) {
			let series : any[] = [];
			let dataFrameKeys : string[] = _.filter(_.keys(data.value.value[0]), (k)=>{return k != 'time'});
			let source : any[] = [
				['time']
			];
			data.value.value.forEach((v)=>{
				source[0].push(formatDate(new Date(v['time']), 'M/d/yy h:mm a', 'en'));
			});
			dataFrameKeys.forEach((k,i)=>{
				source[i+1] = [k];
				series.push({type: 'line', smooth: true, seriesLayoutBy: 'row', emphasis: {focus: 'series'}});
				data.value.value.forEach((v)=>{
					source[i+1].push(v[k]);
				});
			});
			console.log("source", source);
			this.renderOptions = {
        legend: {
        	padding: [15,20,15,20]
				},
				grid: {
					left:'15%',
					right: '10%',
					top: 50,
					bottom: 50
				},
        tooltip: {
          trigger: 'axis'
        },
        dataset: {
            source: source
        },
        xAxis: {
        	type: 'category'
      	},
        yAxis: {gridIndex: 0},
        series: series
			}
		}
	}
}