import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-hidden-string',
  templateUrl: './hidden-string.component.html',
  styleUrls: ['./hidden-string.component.scss'],
})
export class HiddenStringComponent implements OnInit {

  @Input() el: any;

  constructor() { }

  ngOnInit() {
    this.el.value = this.el.value || null;
    this.el.valid = true;
  }

}
