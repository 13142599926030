import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { OHNUser, OHNCalendarEvent, OHNElement, OHNMedication, OHNMeeting } from '../../models/ohn-instances';

@Component({
  selector: 'app-calendar-event-result',
  templateUrl: './calendar-event-result.page.html',
  styleUrls: ['./calendar-event-result.page.scss'],
})
export class CalendarEventResultPage implements OnInit {

	@Input() event : OHNCalendarEvent | OHNMedication | OHNMeeting;
	@Input() calendarElement : OHNElement;
  @Input() user : OHNUser;
 
  constructor(
  	public modalController: ModalController
  ) { }

  ngOnInit() {
  }

  loadReportData() {

  }

  closeModal() {
		this.modalController.dismiss({
			dismissed: true
		});
	}
}
