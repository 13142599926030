import { Component, OnInit, Input } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { OHNElement, OHNCalendarEvent, OHNMedication, OHNMeeting, OHNUser } from '../../../models/ohn-instances';
import { OhnApiService } from '../../../services/ohn-api.service';
import { OhnService } from '../../../services/ohn.service';
import { BranchedContentRendererComponent } from '../../branched-content-renderer/branched-content-renderer.component';
import { ActionsProcessorService } from '../../../services/actions-processor.service';
import { DEBUG_MODE } from '../../../../environments/environment';

import * as _ from 'underscore/underscore';
import { Subject} from 'rxjs';

@Component({
  selector: 'app-common-calendar-widget',
  templateUrl: './common-calendar-widget.component.html',
  styleUrls: ['./common-calendar-widget.component.scss'],
})
export class CommonCalendarWidgetComponent implements OnInit {

	@Input() el: OHNElement;
	@Input() me: OHNUser;
  @Input() refresher : Subject<boolean>;

	events: (OHNCalendarEvent|OHNMedication|OHNMeeting)[];

	calendarElement: OHNElement;

	loading: boolean = true;

  constructor(
  	private ohnApi: OhnApiService,
    private ohnService: OhnService,
  	public modalController: ModalController,
    private toastController : ToastController,
    private iab: InAppBrowser,
    private actionsProcessor : ActionsProcessorService
  ) { }

  ngOnInit() {
  	this.refresher.subscribe(refreshing => {
      //this.loadCalendarHistory();
      this.loadCalendarHistoryWeek();
    });
    //this.loadCalendarHistory();
    this.loadCalendarHistoryWeek();
  	this.loadCalendarElement();
  }

  loadCalendarHistory() {
  	this.loading = true;
  	this.ohnApi.getElementHistoryScCostil('calendar_container', 'today', 1, this.me.smart_contract).subscribe(history => {
  	DEBUG_MODE && console.log('Calendar Event History', history);
    this.events = _.isArray(history) ? _.map(history, (s)=>{	
      let event : OHNMedication | OHNCalendarEvent | OHNMeeting;
      switch (s.controller) {
        case "medication":
          event = new OHNMedication(s, true, true)
          break;

        case "meeting":
          event = new OHNMeeting(s, true, true)
          break;
        
        default:
          event = new OHNCalendarEvent(s)
          break;
        }
        return event;
    	}) : [];
      this.loading = false;
      DEBUG_MODE && console.log('Formatted Events', this.events);
    });
  }

  loadCalendarHistoryWeek(){
    this.loading = true;
    this.ohnApi.getElementHistoryByDatesScCostil('calendar_container', OhnService.getStringDatesPeriodForCalendar(new Date(), 'week', 1), this.me.smart_contract).subscribe(history => {
      DEBUG_MODE && console.log('Calendar Event History', history);
    this.events = _.isArray(history) ? _.map(history, (s)=>{  
      let event : OHNMedication | OHNCalendarEvent | OHNMeeting;
      switch (s.controller) {
        case "medication":
          event = new OHNMedication(s, true, true)
          break;

        case "meeting":
          event = new OHNMeeting(s, true, true)
          break;
        
        default:
          event = new OHNCalendarEvent(s)
          break;
        }
        return event;
      }) : [];
      this.loading = false;
      DEBUG_MODE && console.log('Formatted Events', this.events);
    });
  }

  async startSurvey(event: any) {
    const contentPage = await this.modalController.create({
      component: BranchedContentRendererComponent,
      componentProps: {
        parentElementSlug : event.inner_element_slug,
        user : this.me,
        event : event
      },
      cssClass: 'modal-fullscreen',
      backdropDismiss: false
    });

    contentPage.onDidDismiss().then((data) => {
   
    })
    return await contentPage.present();
  }

  completeMeeting(meeting : OHNMeeting) {
    if (!meeting.completed) {
      //this.meetings = _.filter(this.meetings, (i)=>{return i.id != meeting.id});
      this.notifyOfAction(meeting, 'has been marked as visited.');
      this.ohnApi.patchElementStateSc(this.calendarElement.element_slug, {value: {id : meeting.id, completed: true, nylas_calendar_id : meeting.nylas_calendar_id, nylas_event_id: meeting.nylas_event_id}}, this.me.smart_contract).subscribe(state => {
         
      });
      
      if (this.calendarElement.actions && this.calendarElement.actions.meeting && this.calendarElement.actions.meeting.triggers && this.calendarElement.actions.meeting.triggers.completed) {
        this.actionsProcessor.processActions(this.calendarElement.actions.meeting.triggers.completed, {}, this.me);
      }
    }
  }

  takeMedicine(event: any, intake : OHNMedication) {
   // this.intakes = _.filter(this.intakes, (i)=>{return i.id != intake.id});
    if (!intake.completed && event.detail.checked) {
      intake.completed = true;
      this.notifyOfAction(intake, 'has been taken.');
      this.ohnApi.patchElementStateSc(this.calendarElement.element_slug, {value: {id : intake.id, completed: true, nylas_calendar_id:intake.nylas_calendar_id, nylas_event_id: intake.nylas_event_id}}, this.me.smart_contract).subscribe(state => {
      });
    } else {
      intake.completed = false;
      this.ohnApi.patchElementStateSc(this.calendarElement.element_slug, {value: {id : intake.id, completed: false, nylas_calendar_id:intake.nylas_calendar_id, nylas_event_id: intake.nylas_event_id}}, this.me.smart_contract).subscribe(state => {
      });
    }
  }

  openMeetingLink(meeting : OHNMeeting) {
    const browser = this.iab.create(meeting.contentObject.url, '_system');
  }

  async notifyOfAction(event: OHNMeeting | OHNMeeting | OHNCalendarEvent, text : string) {
    const toast = await this.toastController.create({
      message: event.title +' : ' + text,
      duration: 2000
    });
    toast.present();
  }

  loadCalendarElement() {
  	this.ohnApi.getElement('calendar_container', 3).subscribe(calendar => {
      this.calendarElement = calendar;
    });
  }
}