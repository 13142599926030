import { Component, OnInit, Input } from '@angular/core';
import { OhnLogicService } from '../../../services/ohn-logic.service';

@Component({
  selector: 'app-hidden-counter',
  templateUrl: './hidden-counter.component.html',
  styleUrls: ['./hidden-counter.component.scss'],
})
export class HiddenCounterComponent implements OnInit {

  @Input() el: any;

  constructor(
    private ohnLogicService: OhnLogicService,
  ) { }

  ngOnInit() {
    this.el.value = this.el.value || 0;
    this.el.valid = true;
    this.ohnLogicService.setValueOfElementContainer(this.el.element_slug, this.el.value);
  }

}