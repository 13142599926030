import { Component, OnInit, Input } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { formatDate } from '@angular/common';
import { OHNElement, OHNCalendarEvent, OHNUser} from '../../../models/ohn-instances';
import { OhnApiService } from '../../../services/ohn-api.service';
import { CalendarEventViewPage } from '../../../secured/calendar-event-view/calendar-event-view.page';
import { PopoverComponent } from '../../../components/basic/popover/popover.component';
import { API_URL, DEBUG_MODE } from '../../../../environments/environment';

@Component({
  selector: 'app-radonc-start',
  templateUrl: './radonc-start.component.html',
  styleUrls: ['./radonc-start.component.scss'],
})
export class RadoncStartComponent implements OnInit {

  @Input() user: OHNUser;

  initSession : any = {
    surveySlug : 'session-1-79074',
    reportSlug : 'reportsession_11626200144509',
    title : 'Session 1'
  }

  followUpSession : any = {
    surveySlug : 'returning-session-16813',
    reportSlug : 'reportreturning_session1638876319713',
    title : 'Follow Up'
  }

  session : any;

  generating : boolean = false;

  API_URL = API_URL;

  constructor(
    private ohnApi: OhnApiService,  
    public modalController: ModalController,
    public popoverController: PopoverController
  ) { }

  ngOnInit() {
    this.session = this.initSession;
  }

  startSurvey(){
    this.session = this.initSession;
    localStorage.removeItem('ohn-radonc-group');
    this.generating = true;
    this.scheduleEvent(this.generateEvent());
  }

  startFollowUp(){
    this.session = this.followUpSession;
    localStorage.removeItem('ohn-radonc-group');
    this.generating = true;
    this.scheduleEvent(this.generateEvent());
  }

  startSurveyControl() {
    this.session = this.initSession;
    localStorage.setItem('ohn-radonc-group', '0');
    this.generating = true;
    this.scheduleEvent(this.generateEvent());
  }

  startSurveyIntervention(){
    this.session = this.initSession;
    localStorage.setItem('ohn-radonc-group', '1');
    this.generating = true;
    this.scheduleEvent(this.generateEvent());
  }

  generateEvent(){
    let event = new OHNCalendarEvent({
      title : this.session.title,
      startTime : new Date(),
      completed : false,
      allDay: true,
      controller : 'survey',
      inner_element_slug : this.session.surveySlug,
      inner_element_state_id : '',
      report_element_slug : this.session.reportSlug,
      report_element_state_id : '',
      content : ''
    });
    return event;
  }

  scheduleEvent(event: any) {
    this.ohnApi.setElementStateSc('calendar_container', {value: event}, this.user.smart_contract).subscribe(newEvent => {
      this.openEventModal(new OHNCalendarEvent(newEvent));
      this.generating = false;
    });
  }

  async openEventModal(event: any) {
    const calendarEventPage = await this.modalController.create({
      component: CalendarEventViewPage,
      componentProps: {
        'calendarElement' : {element_slug:'calendar_container'},
        'user' : this.user,
        'event' : event
      },
      cssClass: 'modal-fullscreen',
      backdropDismiss: false
    });

    calendarEventPage.onDidDismiss().then((data) => {
    })

    return await calendarEventPage.present();
  }

  async openPrint(ev: any) {

    let pdfList = [];

    if (this.user.histories.html_printout_intervention) {

      let printout = {
        category: "Baseline session",
        links: []
      }

      this.user.histories.html_printout_intervention.forEach( pdf => {
        let linkItem = {
          link: API_URL + '/reports/' + pdf.value[0],
          text: formatDate(pdf.timestamp, 'MM/dd/yyyy h:mm a', 'en')
        }
        printout['links'].push(linkItem);
      });

      pdfList.push(printout);
    }

    if (this.user.histories.html_printout_followup) {

      let printout = {
        category: "Follow Up session",
        links: []
      }

      this.user.histories.html_printout_followup.forEach( pdf => {
        let linkItem = {
          link: API_URL + '/reports/' + pdf.value[0],
          text: formatDate(pdf.timestamp, 'MM/dd/yyyy h:mm a', 'en')
        }
        printout['links'].push(linkItem);
      });

      pdfList.push(printout);

    }

    const popover = await this.popoverController.create({
      component: PopoverComponent,
      event: ev,
      componentProps: {
        dataType: 'print',
        inputData: pdfList
      },
      translucent: true
    });
    await popover.present();
  }

}
