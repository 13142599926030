import { Component, OnInit, Input } from '@angular/core';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { PageElementsRendererPage } from '../../secured/page-elements-renderer/page-elements-renderer.page';
import { RadoncStaffManagementComponent } from '../../components/radonc-staff-management/radonc-staff-management.component';
import { OHNElement, OHNUser } from '../../models/ohn-instances';
import { OhnService } from '../../services/ohn.service';
import { DEBUG_MODE, APP_SLUG, IS_RAD_ONC_APP } from '../../../environments/environment';

@Component({
  selector: 'app-multi-level-menu',
  templateUrl: './multi-level-menu.component.html',
  styleUrls: ['./multi-level-menu.component.scss'],
})
export class MultiLevelMenuComponent implements OnInit {

  //@Input() me :OHNUser;
  @Input() expand: boolean;
  @Input() app :OHNElement;;

  menuItems : any[] = [
      {
        "level": 0,
        "expand": false,
        "icon": "people-outline",
        "element_slug":"patient-management_97898",
        "text": "User Management",
        "action": "navigateTo",
        "name": "/secured/user-management/patient-management_97898",
        "params": "",
        "permit": "MANAGE_USERS",
        "visibleForRoles" : ['all']
      },
      /*
      {
        "level": 0,
        "expand": false,
        "icon": "people-outline",
        "element_slug":"patient-management_97898",
        "text": "Care Team",
        "action": "navigateTo",
        "name": "/secured/user-management/patient-management_97898/team",
        "params": "",
        "permit": "MANAGE_USERS",
        "visibleForRoles" : ['patientspherex_admin', 'patientspherex_coordinator', 'patientspherex_projectCoordinator']
      },
      */
      {
        "level": 0,
        "expand": false,
        "icon": "hammer-outline",
        "element_slug":"patient-management_97898",
        "text": "Content Management",
        "action": "navigateTo",
        "name": "/secured/calendar-sequence-manager",
        "params": "",
        "permit": "MANAGE_USERS",
        "visibleForRoles" : ['patientspherex_admin', 'patientspherex_coordinator', 'patientspherex_projectCoordinator']
      },
      {
        "level": 0,
        "expand": false,
        "icon": "chatbox-ellipses-outline",
        "element_slug":"",
        "text": "Staff and Text Messages Manager",
        "action": "openStaffModal",
        "name": "",
        "params": "",
        "permit": "MANAGE_USERS",
        "visibleForRoles" : ['patientspherex_admin', 'patientspherex_coordinator', 'patientspherex_projectCoordinator'],
        "extras": {}
      }
      /*
      {
        "level": 0,
        "expand": false,
        "icon": "cog",
        "element_slug":"project-management",
        "text": "Projects",
        "action": "navigateTo",
        "name": "secured/project-management",
        "params": "",
        "permit": "MANAGE_USERS",
        "visibleForRoles" : ['patientspherex_admin', 'patientspherex_coordinator']
      },
      {
        "level": 0,
        "expand": false,
        "icon": "hico-spreadsheets-filled",
        "element_slug":"",
        "text": "Email Report",
        "action": "emitEvent",
        "name": "filterChange",
        "params": "",
        "permit": "MANAGE_USERS"
      },
      {
        "level": 0,
        "expand": false,
        "icon": "book-outline",
        "element_slug":"boscc_glossary",
        "text": "Glossary",
        "action": "openModal",
        "name": "boscc_glossary",
        "params": "",
        "permit": "WRITE",
        "visibleForRoles" : ['all'],
        "extras": {}
      },

      {
        "level": 0,
        "expand": false,
        "icon": "help-circle-outline",
        "element_slug":"patient-management_97898",
        "text": "Help",
        "action": "openLink",
        "name": "https://docs.google.com/forms/d/e/1FAIpQLSdiyxr2A_o-73kSYbz0u8BN74Q4M1NkJ7WHZAwYNxghDVidXw/viewform?usp=pp_url&entry.660711297=um&entry.1970007375={{me.id}}",
        "params": "",
        "permit": "WRITE",
        "visibleForRoles" : ['all'],
        "extras": {}
      }      */
    ];

  constructor(
    private router: Router,
    public modalController: ModalController,
    private ohnService: OhnService
  ) { }

  ngOnInit() {
    //this.filterMenuButtons();
  }
/*
  filterMenuButtons(){
    this.menuItems = this.menuItems.filter(i => {
      let available : boolean = false;
      if (i.visibleForRoles[0] == 'all') {
        available = true
      } else {
        this.me.roles.forEach(r=>{
          if (i.visibleForRoles.indexOf(r) >= 0) {
            available = true
          }
        })
      }
      return available;
    });
  }
*/
  onItemActivated(item: any) {
    DEBUG_MODE && console.log("onItemActivated", item);

    switch (item.action) {
      case 'navigateTo':
        let navigationExtras: NavigationExtras = {
          state: {
            app : this.app
          },
          replaceUrl: true
        };
        this.router.navigate([item.name], navigationExtras);
        break;

      case 'emitEvent':
        this.ohnService[item.name].next(item);
        break;

      case 'openModal':
        this.openModal(item.name);
        break;

      case 'openLink':
        this.openLink(item.name);
        break;

      case 'openStaffModal':
        this.openStaffModal();
        break;

      default:
        DEBUG_MODE && console.log("No action");
    }
  }

  async openModal(slug: string) {
    const renderModal = await this.modalController.create({
      component: PageElementsRendererPage,
      componentProps: {
        'isModal': true,
        'elementSlug': slug
      },
      backdropDismiss: true,
      cssClass: 'ohn-modal-right'
    });

    return await renderModal.present();
  }

  async openStaffModal() {
    const staffPage = await this.modalController.create({
      component: RadoncStaffManagementComponent,
      componentProps: {
      },
      backdropDismiss: false
    });

    staffPage.onDidDismiss().then((data) => {
    })

    return await staffPage.present();
  }

  openLink(url: string) {
    window.open(url, "_blank");
  }


}
