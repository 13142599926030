import { Component, OnInit, Input } from '@angular/core';
import { OHNElement } from '../../../models/ohn-instances';

@Component({
  selector: 'app-number-element-config',
  templateUrl: './number-element-config.component.html',
  styleUrls: ['./number-element-config.component.scss'],
})
export class NumberElementConfigComponent implements OnInit {

	@Input() el: OHNElement;

  constructor() { }

  ngOnInit() {}

}
