import { Component, OnInit, Input } from '@angular/core';
import { OHNElement } from '../../../models/ohn-instances';

@Component({
  selector: 'app-string-element-config',
  templateUrl: './string-element-config.component.html',
  styleUrls: ['./string-element-config.component.scss'],
})
export class StringElementConfigComponent implements OnInit {

	@Input() el: OHNElement;

  constructor() { }

  ngOnInit() {}

}
