import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { OHNElement, OHNUser } from '../../../models/ohn-instances';
import { OhnApiService } from '../../../services/ohn-api.service';
import { OhnService } from '../../../services/ohn.service';
import * as _ from 'underscore/underscore';
import { Subject} from 'rxjs';

import { HistoryRendererComponent } from '../../history-renderer/history-renderer.component';

@Component({
  selector: 'app-history-widget',
  templateUrl: './history-widget.component.html',
  styleUrls: ['./history-widget.component.scss'],
})
export class HistoryWidgetComponent implements OnInit {

	@Input() el: OHNElement;
  @Input() refresher : Subject<boolean>;
  @Input() me: OHNUser;

	loading: boolean = false;

  constructor(
  	private ohnApi: OhnApiService,
    private ohnService: OhnService,
  	public modalController: ModalController
  ) { }

  ngOnInit() {
  }

  async openModalComponent() {
    const contentPage = await this.modalController.create({
      component: HistoryRendererComponent,
      componentProps: {
        parentElementSlug : this.el.config.elementSlug,
        user : this.me
      },
      backdropDismiss: false
    });

    contentPage.onDidDismiss().then((data) => {
   
    })

    return await contentPage.present();
  }
}
