import { Component, OnInit } from '@angular/core';
import { OhnApiService } from '../../services/ohn-api.service';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-radonc-staff-management',
  templateUrl: './radonc-staff-management.component.html',
  styleUrls: ['./radonc-staff-management.component.scss'],
})
export class RadoncStaffManagementComponent implements OnInit {

  staff : any;

  welcomeMessage : any;

  saving : boolean = false;

  constructor(
    private ohnApi: OhnApiService,
    public modalController: ModalController,
  ) { }

  ngOnInit() {
    this.ohnApi.getElement('radonc_staff_container', 3).subscribe(staff=>{
      this.staff = staff;
    });
    this.ohnApi.getElement('welcome_message_element', 3).subscribe(message=>{
      this.welcomeMessage = message;
    })
  }

  addOption() {
    this.staff.elements.push(
      {
        text : "",
        _cls : "ContentElement",
        controller : "contentElementController",
        settings : {},
        config : {},
        elements : []
      }
    );
  }

  deleteOption(elementIndex : number) {
    this.staff.elements.splice(elementIndex, 1);
  }

  save() {
    this.saving = true;
    this.ohnApi.setElement('welcome_message_element', this.welcomeMessage).subscribe(m=>{
      this.ohnApi.setElement('radonc_staff_container', this.staff).subscribe(r=>{
        this.saving = false;
        this.closeModal();
      })
    })
  }

  closeModal() {
    this.modalController.dismiss({
      dismissed: true
    });
  }

}
