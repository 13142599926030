import { Component, OnInit, Input } from '@angular/core';
import { OHNElement, OHNUser, OHNExternalDevice} from '../../models/ohn-instances';
import { OhnApiService } from '../../services/ohn-api.service';
import { OhnService } from '../../services/ohn.service';
import { ModalController, AlertController } from '@ionic/angular';

@Component({
  selector: 'app-rule-editor',
  templateUrl: './rule-editor.component.html',
  styleUrls: ['./rule-editor.component.scss'],
})
export class RuleEditorComponent implements OnInit {

  @Input() parentElement : OHNElement;

  nodes : any = {};

  rules : any;

  availableBasicElements : OHNElement[] = [];

  operators : string[] = ['=', '!=', '>', '<', '>=', '<=', 'contains', '!contains'];

  fieldType : string;

  selectedElement : OHNElement;

  beforeInit : boolean = true;

  nodeOrder : any[] = [];

  constructor(
    public modalController: ModalController,
    private ohnApi: OhnApiService,
    private ohnService: OhnService,
    private alertCtrl: AlertController
  ) { }

  ngOnInit() {
    this.availableBasicElements = this.collectBasicElements(this.parentElement);
    this.initTransitions();
  }

  initTransitions() {
    if (!this.parentElement.config.rules) {
      this.rules = {
        text: 'Branching',
        id : 'branching',
        nodes: [
        ]
      }
    } else {
      this.rules = this.parentElement.config.rules;
    }
    this.parentElement.elements.forEach((e, i)=>{
      this.nodeOrder.push({slug : e.element_slug, lastNode : false});
      const node = this.rules.nodes.find(n=>{return n.id == e.element_slug});
      if (node) {
        this.nodes[e.element_slug] = node.transitions;
      } else if (i != (this.parentElement.elements.length - 1)) {
        this.nodes[e.element_slug] = [
          {
            nextNode: this.parentElement.elements[i+1].element_slug,
            operator: "",
            operand: "",
            transitions: [],
            type: "noConditions",
            compareToNode: ""
          }
        ]
      } else {
        this.nodes[e.element_slug] = [];
      }
    });
    console.log(this.nodes);
    this.beforeInit = false;
  }

  switchLastNode() {
    //lastNode
  }

  collectBasicElements(element: OHNElement) {
    let basicElements : OHNElement[] = [];
    element.elements.forEach(p => {
      p.elements.forEach(e => {
        if (['stringFieldController', 'numericFieldController', 'pickOneDefaultController', 'yesNoController'].indexOf(e.controller) >= 0) {
          basicElements.push(e);
        }
      });
    });
    return basicElements;
  }

  newRule(element: OHNElement) {
    if (!this.nodes[element.element_slug]) {
      this.nodes[element.element_slug] = [];
    }
    this.nodes[element.element_slug].push(
      {
        nextNode: undefined,
        operand: undefined,
        operator: undefined,
        transitions: [],
        type: "compare",
        reserveNodeId: undefined,
        compareToNode: undefined
      }
    );
  }

  compareToChanged(transition : any) {
    //this.selectedElement = this.availableBasicElements.find(e=>{return e.element_slug == transition.compareToNode});
  }

  getFieldType(elementSlug: string) {
    const element = this.availableBasicElements.find(e=>{return e.element_slug == elementSlug});
    const fieldType = ['stringFieldController', 'numericFieldController'].indexOf(element.controller) >= 0 ? 'string' : 'pickone';
    return fieldType;
  }

  getSelectedElementForTransition(elementSlug: string){
    const element = this.availableBasicElements.find(e=>{return e.element_slug == elementSlug});
    return element;
  }

  switchDefaultTransition(elementSlug: string){
    if (this.isDefaultTransition(elementSlug)) {
      this.nodes[elementSlug] = [];
    } else {
      const pageIndex = this.parentElement.elements.findIndex(e=>{return e.element_slug == elementSlug});
      if (pageIndex != (this.parentElement.elements.length - 1)) {
        this.nodes[elementSlug] = [
          {
            nextNode: this.parentElement.elements[pageIndex+1].element_slug,
            operator: "",
            operand: "",
            transitions: [],
            type: "noConditions",
            compareToNode: ""
          }
        ]
      } else {
        this.nodes[elementSlug] = []
      }
    }
  }

  isDefaultTransition(elementSlug: string){
    return this.nodes[elementSlug].length == 0 || this.nodes[elementSlug][0].type == 'noConditions';
  }

  isVisibleValueInput(elementSlug: string){
    return false;
  }

  save() {
    this.rules.nodes = [];
    this.parentElement.elements.forEach(e=>{
      this.rules.nodes.push({
        text: '',
        transitions: this.nodes[e.element_slug],
        id: e.element_slug,
        level: 0
      })
    });
    this.parentElement.config.rules = this.rules;
    this.ohnApi.setElement(this.parentElement.element_slug, {config : this.parentElement.config}).subscribe();
    this.closeModal();
  }

  closeModal() {
    this.modalController.dismiss({
      dismissed: true
    });
  }
}