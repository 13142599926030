import { Component, OnInit, Input} from '@angular/core';
import { ModalController, ToastController, AlertController } from '@ionic/angular';
import { OHNUser, OHNCalendarEvent, OHNElement ,OHNCalendarAction, OHNCalendarActionList, OHNMedication } from '../../models/ohn-instances';
import { OhnApiService } from '../../services/ohn-api.service';
import { OhnService } from '../../services/ohn.service';
import * as _ from 'underscore/underscore';

@Component({
  selector: 'app-new-medication',
  templateUrl: './new-medication.component.html',
  styleUrls: ['./new-medication.component.scss'],
})
export class NewMedicationComponent implements OnInit {

	@Input() user: OHNUser;
	@Input() calendarElement : OHNElement;
  @Input() isNew: boolean;
  @Input() editMed: OHNMedication;

  med: OHNMedication;

	actionProcessed: string;

	loading: boolean = false;

  weekDaysToTake: boolean[] = [false, false, false, false, false, false, false];

  intakeTimes : any[] = [];

  fillType: string = 'manually';

  daily: boolean = false;

  searchProcessing :boolean = false;

  medSearch: string = '';

  searchResult : any[] = [];

  rrule : any = {};

  rruleDays : any[] = [
    {val : 'SU', isChecked : false},
    {val : 'MO', isChecked : false},
    {val : 'TU', isChecked : false},
    {val : 'WE', isChecked : false},
    {val : 'TH', isChecked : false},
    {val : 'FR', isChecked : false},
    {val : 'SA', isChecked : false}
  ]

  constructor(
  	public modalController: ModalController,
    public toastController: ToastController,
    private ohnApi: OhnApiService,
    private ohnService: OhnService,
    private alertCtrl: AlertController
  ) { }
 
  ngOnInit() {
  	this.loadDetailedCalendarElement();
    this.med = this.editMed || new OHNMedication();
    if (this.isNew){
      this.med.startTime = new Date().toISOString();
      this.med.endTime = new Date(new Date().getTime() + 600000).toISOString();
      this.addIntakeTime();
    } else {
      this.med.startTime = new Date(this.med.startTime).toISOString();
      this.med.endTime = new Date(this.med.endTime).toISOString();
      this.setWeekdaysAndIntakeTimes();
    }
  }

  setWeekdaysAndIntakeTimes() {
    this.med.cron.weekday.forEach(w => { this.weekDaysToTake[w] = true; });
    this.daily = _.filter(this.weekDaysToTake, (d)=>{return !d;}) ? false : true;
    this.med.cron.hour.forEach((h,i) => {
      let tmpDate : Date = new Date();
      tmpDate.setHours(h);
      tmpDate.setMinutes(this.med.cron.minute[i]);
      this.intakeTimes.push({value : tmpDate.toISOString()});
    });
  }

  dailySwitched(event: any) {
    if (event.detail.checked) {
      this.weekDaysToTake.forEach((w,i) => { this.weekDaysToTake[i] = true; });
    } else {
      this.weekDaysToTake.forEach((w,i) => { this.weekDaysToTake[i] = false; });
    }
  }

  addIntakeTime() {
    this.intakeTimes.push({value : "2020-01-01T08:00:00"});
  }

  deleteIntakeTime(index: number) {
    this.intakeTimes.splice(index, 1);
  }

  loadDetailedCalendarElement(){
  	this.loading = true;
  	this.ohnApi.getElement(this.calendarElement.element_slug, 3).subscribe(calendar => {
  		this.calendarElement = calendar;
  		this.loading = false;
  	});
  }

  save() {
  	this.loading = true;
    this.med.content = JSON.stringify(this.med.contentObject);
    this.med.formatCron(this.weekDaysToTake, this.intakeTimes);
    this.med.rrule = this.getRRule();
    let medWithTrigger: any = this.ohnService.getObjectCopy(this.med);
    if (this.isNew) {    
      medWithTrigger['post_set_state_trigger'] = [
        {
          action:'setup_periodic_get_state_for_calendar'
        }
      ];
      this.ohnApi.setElementStateSc(this.calendarElement.element_slug, {value: medWithTrigger}, this.user.smart_contract).subscribe(event => {
        this.med = new OHNMedication(event);
        this.completeEditing('created');
      });
    } else {
      medWithTrigger['pre_set_state_trigger'] = [
        {
          action:'reschedule_periodic_get_state_for_calendar'
        }
      ];
      this.ohnApi.patchElementStateSc(this.calendarElement.element_slug, {value: medWithTrigger}, this.user.smart_contract).subscribe(state => {
        this.completeEditing('modified');
      });
    }
  }

  getRRule() {
    const endPeriod = new Date(new Date(this.med.startTime).getTime() + 31536000000).toISOString();
    let rruleString : string = 'RRULE:UNTIL=' + endPeriod.toString().replace(/[\-,\:]/g, '').replace(/\..*Z/, 'Z');
    if (this.rrule.freq) {
      rruleString += ';FREQ=' + this.rrule.freq.toUpperCase(); 
    }
    const selectedDays = this.rruleDays.filter(d=>{return d.isChecked}).map(d=>{return d.val});
    if (selectedDays.length > 0) {
      rruleString += ';BYDAY=' + selectedDays.join();
    }

    return rruleString;
  }

  async showDeleteDialog() {
   const alert = await this.alertCtrl.create({
      header: 'Delete Medication',
      message: 'Are you sure you want to delete <strong>'+ this.med.title +'</strong>?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
           
          }
        }, {
          text: 'Yes',
          handler: () => {
            this.delete(this.med);
          }
        }
      ]
    });

    await alert.present();
  }

  delete(prescription: OHNMedication){
    //this.prescriptions = _.filter(this.prescriptions, (p)=>{return p.id != prescription.id});
    let medWithTrigger: any = this.ohnService.getObjectCopy(this.med);
    medWithTrigger['pre_set_state_trigger'] = [
      {
        action:'delete_periodic_tasks_for_calendar'
      }
    ];
    this.ohnApi.deleteElementStateSc(this.calendarElement.element_slug, {value: medWithTrigger}, this.user.smart_contract).subscribe(event => {
      this.completeEditing('deleted');
    });
  }

  completeEditing(action: string) {
    this.loading = false;
    this.actionProcessed = action;
    this.notify(action);
    this.closeModal();
  }

  searchDB() {
    if (this.medSearch.length >= 3) {
      let searhcObj: any = {"search_by__icontains" : this.medSearch};
      this.searchProcessing = true;
      this.ohnApi.searchElementQuery(this.calendarElement.config.enabledEvents.medicationSearchSlug, searhcObj).subscribe(searchResult => {
        //console.log(searchResult);
        this.searchResult = searchResult;
        this.searchProcessing = false;
       /* if (searchResult) {
          this.searchResult = searchResult.map(m=>{
            return {
              name : m.meeting_name,
              link : m.comments,
              duration : m.duration_time,
              startTime : '2020-01-01T' + this.datePipe.transform(new Date((new Date('2020-01-01T' + m.start_time).getTime() - this.userTimezoneOffset)), 'HH:mm'),
              startTimeString : this.datePipe.transform(new Date((new Date('2020-01-01T' + m.start_time).getTime() - this.userTimezoneOffset)), 'h:mm a'),
              dayString : OhnService.dayOfWeekNumToText(m.weekday),
              day : m.weekday
            }
          });
        }
        this.searchProcessing = false;
        console.log(this.searchResult);*/
      });
    }
  }

  selectMed(medication: any) {
    this.med.title = this.pickShortestName(medication);
    this.fillType = 'manually';
  }

  pickShortestName(med : any) {
    return (med.generic_name.length <= med.brand_name.length) ? med.generic_name : med.brand_name;
  }


  async notify(action : string) {
  	const toast = await this.toastController.create({
      message: 'Medication has been ' + action,
      duration: 2000
    });
    toast.present();
  }

  closeModal() {
  	if (this.actionProcessed) {
  		this.modalController.dismiss({
	      dismissed: true,
	      event: this.med,
        deleted : this.actionProcessed === 'deleted',
        isNew: this.isNew
	    });
  	} else {
      this.modalController.dismiss({
        dismissed: true
      });
    }
  }

}