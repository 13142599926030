import { Component, OnInit, Input } from '@angular/core';
import { OHNElement } from '../../../models/ohn-instances';
import { OhnLanguageService } from '../../../services/ohn-language.service';

@Component({
  selector: 'app-fitbit-heart-rate-config',
  templateUrl: './fitbit-heart-rate-config.component.html',
  styleUrls: ['./fitbit-heart-rate-config.component.scss'],
})
export class FitbitHeartRateConfigComponent implements OnInit {

	@Input() el: OHNElement;

  constructor(
    private lS: OhnLanguageService,
  ) { }

  ngOnInit() {}

}
