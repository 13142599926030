import { Component, OnInit, Input } from '@angular/core';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { OHNElement } from '../../../models/ohn-instances';

@Component({
  selector: 'app-video-element',
  templateUrl: './video-element.component.html',
  styleUrls: ['./video-element.component.scss'],
})
export class VideoElementComponent implements OnInit {

	@Input() el: OHNElement;
	@Input() viewMode : string;

	videoUrl: SafeResourceUrl;

  constructor(private domSanitizer: DomSanitizer) { }

  ngOnInit() {
    this.el.valid = true;
  	this.videoUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(this.el.video_url);
  }

}
