import { Component, OnInit, Input } from '@angular/core';
import { OhnLanguageService } from '../../../services/ohn-language.service';

@Component({
  selector: 'app-fitbit-heart-rate',
  templateUrl: './fitbit-heart-rate.component.html',
  styleUrls: ['./fitbit-heart-rate.component.scss'],
})
export class FitbitHeartRateComponent implements OnInit {

	@Input() el: any;
	@Input() viewMode : string;

  constructor(
    private lS: OhnLanguageService,
  ) { }

  ngOnInit() {
    this.el.valid = true;
  }

}
