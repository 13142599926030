import { Component, OnInit, Input } from '@angular/core';
import { OHNElement, OHNUser } from '../../models/ohn-instances';
import { ModalController } from '@ionic/angular';
import { OhnApiService } from '../../services/ohn-api.service';
import { NotificationRulesModalComponent } from '../notification-rules-modal/notification-rules-modal.component';
import { Subject } from 'rxjs';
import * as _ from 'underscore/underscore';


@Component({
  selector: 'app-notification-rules',
  templateUrl: './notification-rules.component.html',
  styleUrls: ['./notification-rules.component.scss'],
})
export class NotificationRulesComponent implements OnInit {

  @Input() container: OHNElement;
  @Input() user: OHNUser;
  @Input() userDetailsOpener : Subject<OHNUser>;

  notifications: OHNElement[] = [];

  notificationsModifier : Subject<OHNElement> = new Subject();

  constructor(
    private modalController: ModalController,
    private ohnApi : OhnApiService
  ) { }

  ngOnInit() {

    this.notificationsModifier.subscribe(notification=>{
      this.markAsRead(notification);
    });
    this.formatNotifications();

  }

  formatNotifications() {
    const keys = _.keys(this.user.elements.rules_container);
    keys.forEach(k=>{
       if (this.user.elements.rules_container[k].value && this.user.elements.rules_container[k].value == 1) {
         const element = this.container.elements.find(e=>{return e.element_slug == k});
         if (element) {
           this.notifications.push(element);
         }
       }    
    });
    this.user.order = this.notifications.length;
  }

  markAsRead(notification : OHNElement) {
    this.notifications = this.notifications.filter(n=>{
      return n.element_slug != notification.element_slug;
    });
    this.ohnApi.setElementStateSc(notification.element_slug, {value : ""}, this.user.smart_contract).subscribe(resp=>{

    });
  }

  async openDetailsModal() {
    const detailsModal= await this.modalController.create({
     component: NotificationRulesModalComponent,
      componentProps: {
        notifications : this.notifications,
        notificationsModifier : this.notificationsModifier
      },
      cssClass: "ohn-modal-right"
    });

    detailsModal.onDidDismiss().then((data) => {
      if (data.data.viewMore) {
        this.userDetailsOpener.next(this.user);
      }
     // if (data.data.newEvent) this.eventSource.push(data.data.newEvent);
      //this.eventCalendar.loadEvents();
    })

    return await detailsModal.present();
  }

}
