import { Component, OnInit, ViewChildren, QueryList, Input } from '@angular/core';
import { ModalController, AlertController } from '@ionic/angular';
import { ProfileFieldsViewComponent } from '../../components/profile-fields-view/profile-fields-view.component';
import { OHNUser, OHNUserRole , OHNElement} from '../../models/ohn-instances';
import { OhnApiService } from '../../services/ohn-api.service';
import { OhnService } from '../../services/ohn.service';
import { combineLatest, Observable, forkJoin} from 'rxjs';
import * as _ from 'underscore/underscore';

import { DEBUG_MODE } from '../../../environments/environment';

@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.page.html',
  styleUrls: ['./new-user.page.scss'],
})

export class NewUserPage implements OnInit {

	@ViewChildren(ProfileFieldsViewComponent) profiles: QueryList<ProfileFieldsViewComponent>;

	@Input() newUser: boolean;
  @Input() me: OHNUser;
  @Input() userToEdit: OHNUser;

	user: OHNUser = <OHNUser>{
		profiles: {}
	};

	newUserCreated: boolean = false;

  allUserRoles : OHNUserRole[];

	userRoles: OHNUserRole[];

  accessableRoles : string[];

  acessableRolesState : any = {};

  constructor(
     public modalController: ModalController,
     private ohnApi: OhnApiService,
     private ohnService: OhnService,
     private alertController: AlertController
  ){
  }

  ngOnInit() {
    this.loadRoles();
    if (!this.newUser) {
      this.user = this.userToEdit;
    }
  }

  loadRoles() {
    this.ohnApi.getAvailableRoles().subscribe((roles: OHNUserRole[])=>{
      this.allUserRoles = roles;
      DEBUG_MODE && console.log('All Roles', this.allUserRoles);
      this.accessableRoles = this.getAccessableRoleList(this.allUserRoles);
      if (!this.newUser) {
        _.each(this.accessableRoles, (r)=>{
          this.acessableRolesState[r] = _.contains(this.user.roles, r);
        });
        this.updateAcessableRole();
      } else {
        if (this.accessableRoles.length > 0) {
          this.acessableRolesState[this.accessableRoles[0]] = true;
          this.updateAcessableRole();
        }
      }
      console.log(this.acessableRolesState);
      this.loadUserProfiles();
    });
  }

  loadUserProfiles() {
    _.each(this.accessableRoles, (r)=>{
      if (this.newUser) {
        this.ohnApi.getElement(this.ohnService.appRoleModifier(r, false) + '_profile', 3).subscribe((profile)=>{
          this.addElementsToRole(profile, this.allUserRoles);
        });
      } else {
        combineLatest(
          this.ohnApi.getElement(this.ohnService.appRoleModifier(r, false) + '_profile', 3),
          this.ohnApi.getElementStateSc((this.ohnService.appRoleModifier(r, false) + '_profile'), this.user.smart_contract),
        ).subscribe((elements: any[]) => {
          if (elements[1].value && elements[1].value.value) {
            _.each(elements[0].elements, (e)=>{           
              e.value = elements[1].value.value[e.element_slug];
            });
          }
          this.addElementsToRole(elements[0], this.userRoles);
          this.addElementsToRole(elements[0], this.allUserRoles);
        });
      }
    });
  }

  addElementsToRole(element: OHNElement, rolesObject: OHNUserRole[]){
    _.each(rolesObject, (r)=>{
      if (this.ohnService.appRoleModifier(r.name, false) + '_profile' == element.element_slug) {
        r.roleProfileElements = element.elements;
      }
    });
  }

  getAccessableRoleList(roles: OHNUserRole[]) {
    if (_.contains(this.me.roles, this.ohnService.appRoleModifier('admin', true))) {
      return _.map(roles, (r)=>{return r.name});
    } else {
      let srtingRoles: string[] = [];
      _.each(roles, (r)=>{
        if (_.contains(this.me.roles, r.name) && r.dependants) {
          srtingRoles = _.union(srtingRoles, r.dependants);
        }
      });
      return srtingRoles;
    }
  }

  updateAcessableRole() {
    this.userRoles = _.filter(this.allUserRoles, (r)=>{return this.acessableRolesState[r.name]});
  }

  save() {
    DEBUG_MODE && console.log("SAVING", this.user);

    let grantRoles : string[] = _.map(this.userRoles, (r)=>{return r.name});
    if (!this.newUser) {
      grantRoles = _.filter(grantRoles, (r)=>{return !_.contains(this.user.roles, r.name)});
    }
    DEBUG_MODE && console.log("grantRoles", grantRoles);
    this.user.roles = grantRoles;
    DEBUG_MODE && console.log("SAVING", this.user);
    
    if (this.newUser) {
      this.ohnService.startLoading('Saving user profile ...');
      this.ohnApi.addNewUser(this.user).subscribe(user => {
        this.user = user;
        //this.grantRoles();    
        this.ohnService.stopLoading();
        this.newUserCreated = this.newUser;
        this.saveProfiles();
      });
    } else {
      this.ohnService.startLoading('Saving user profile ...');
      this.ohnApi.patchUser(this.user).subscribe(user => {
        this.user = user;
        //this.grantRoles();    
        this.userToEdit.roles = this.user.roles;
        this.ohnService.stopLoading();
        this.newUserCreated = this.newUser;
        this.saveProfiles();
      });
    }    
  }

  grantRoles() {
    let grantRoles : string[] = _.map(this.userRoles, (r)=>{return this.ohnService.appRoleModifier(r.name, false)});
    if (!this.newUser) {
      grantRoles = _.filter(grantRoles, (r)=>{return !_.contains(this.user.roles, this.ohnService.appRoleModifier(r.name, true))});
    }
    if (grantRoles.length > 0) {
      this.ohnApi.grantUserRole({
        "username": this.user.username,
        "role": grantRoles
      }).subscribe(userrole => {
        this.user = userrole;
        (!this.newUser) && (this.userToEdit.roles = this.user.roles);
        this.ohnService.stopLoading();
        this.newUserCreated = this.newUser;
        this.saveProfiles();
      });
    } else {
      this.saveProfiles();
    }
  }

  saveProfiles(){
    let profileSavingTasks$ : Observable<any>[] = [];
    this.profiles.forEach((profile) => { 
      this.user.profiles[this.ohnService.appRoleModifier(profile.profile.name, false) + '_profile'].value = profile.saveProfile();
      profileSavingTasks$.push(
        this.ohnApi.setElementStateSc((this.ohnService.appRoleModifier(profile.profile.name, false) + '_profile'), {value : this.user.profiles[this.ohnService.appRoleModifier(profile.profile.name, false) + '_profile']}, this.user.smart_contract)
      );
    });
    if (profileSavingTasks$.length > 0) {
      const requests = forkJoin(profileSavingTasks$).subscribe(results => { 
        this.finishEditing();
      });
    } else {
      this.finishEditing();
    }
  }

  finishEditing() {
    //this.ohnService.stopLoading().then(()=>{this.closeModal('default');});
    this.ohnService.stopLoading();
    this.closeModal('default');
  }

  async showDeleteDialog() {
    const alert = await this.alertController.create({
      header: 'Delete user',
      inputs: [
        {
          name: 'text',
          type: 'text',
          placeholder: 'Type in "delete"'
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
          }
        }, {
          text: 'Ok',
          handler: (inputs) => {
            if (inputs && inputs.text && inputs.text == 'delete') {
              this.delete();
            }
          }
        }
      ]
    });

    await alert.present();
  }

  delete(){
    this.ohnService.startLoading('Deleting...');
    this.ohnApi.deleteUser(this.user.smart_contract).subscribe(message => {
      this.ohnService.stopLoading();
      this.closeModal('delete');
    });
  }

  closeModal(context: string) {
  	if (this.newUserCreated) {
      this.user.order = 0;
  		this.modalController.dismiss({
	      dismissed: true,
	      newUser: this.user
	    });
  	} else {
      switch (context) {
        case "delete":
          this.modalController.dismiss({
            dismissed: true,
            deleteUser : this.user
          });
          break;
        
        default:
          this.modalController.dismiss({
            dismissed: true
          });
          break;
      }
  	}
  }
}