import { Component, OnInit, Input } from '@angular/core';
import { OHNElement } from '../../../models/ohn-instances';
import { OhnLanguageService } from '../../../services/ohn-language.service';

@Component({
  selector: 'app-timer-element',
  templateUrl: './timer-element.component.html',
  styleUrls: ['./timer-element.component.scss'],
})
export class TimerElementComponent implements OnInit {

	@Input() el: OHNElement;
	@Input() viewMode : string;

	timerState : string = 'stopped';

	time : number = 0;

	intervalHandler : any;

  constructor(
    private lS: OhnLanguageService,
  ) { }

  ngOnInit() {
    this.el.valid = true;
  	this.time = parseInt(this.el.config.hours) * 3600 + parseInt(this.el.config.minutes) * 60 + parseInt(this.el.config.seconds);
  	this.intervalHandler = setInterval(()=>{ 
  		if (this.timerState == 'started' && this.time > 0) {
  			this.time--;
  			if (this.time == 0) {
  				this.timerState = 'completed';
  				clearInterval(this.intervalHandler);
  			}
  		}
  	},1000);
  }

  start(){
  	this.timerState = 'started';
  }

  stop(){
  	this.timerState = 'stopped';
  }

  getCurrentHours() {
  	return Math.floor(this.time / 3600);
  }

  getCurrentMinutes() {
  	return Math.floor(this.time / 60) % 60;
  }

  getCurrentSeconds() {
  	return this.time % 60;
  }
}
