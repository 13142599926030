import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { AlertController, ModalController, ToastController } from '@ionic/angular';
import { APP_SLUG, ENABLE_HEALTH_KIT_DEBUG } from '../../environments/environment';
import { OHNUser, OHNElement } from '../models/ohn-instances';
import { OhnApiService } from './ohn-api.service';
import { combineLatest, Observable, forkJoin} from 'rxjs';
import { Health } from '@ionic-native/health/ngx';
import { Device } from '@ionic-native/device/ngx';
import * as _ from 'underscore/underscore';

@Injectable({
  providedIn: 'root'
})
export class OhnHealthApiService {

	datePipe : DatePipe = new DatePipe('en-US');

	user : OHNUser;

	latestSyncDates : any = {};

	activityTypes : string[] = [
		'biking',
		'diving',
		'gymnastics',
		'hiking',
		'running',
		'skiing',
		'stairs',
		'swimming',
		'walking',
		'wheelchair',
		'yoga'
	]

  constructor(
		private alertController: AlertController,
		private modalController: ModalController,
		private toastController: ToastController,
		private ohnApi: OhnApiService,
		private health: Health,
		private device: Device
	){

	}

	initHealthKit(app : OHNElement, user : OHNUser) {
		this.user = user;
    if (app.config && app.config.healthKit && app.config.healthKit.enabled) {
      this.health.isAvailable()
      .then((available:boolean) => {
        if (available) {
           this.health.requestAuthorization(app.config.healthKit.fields)
           .then(res => {
              this.syncHealthKitData(app.config);
            })
           .catch(e => {console.log(e)});
        }
      })
      .catch(e => {console.log(e)});
    }
  }

	syncHealthKitData(config: any) {
		this.health.isAuthorized(config.healthKit.fields)
    .then((authorized:any) => {
      if (authorized) {
      	this.ohnApi.getElementStateSc('hk_sync_dates', this.user.smart_contract).subscribe(state => {

      		this.latestSyncDates = (state.value == "<None>" || state.value == null || state.value.value == null) ? {} : state.value.value;

					let healthKitQueryTasks$ : Promise<any>[] = [];
	      	config.healthKit.fields.forEach((field)=>{
	      		//let startDate : Date = this.latestSyncDates[field] ? new Date(new Date(this.latestSyncDates[field]).getTime() + 1000) : new Date(new Date().getTime() - 200 * 24 * 60 * 60 * 1000);
	      		let startDate : Date = this.latestSyncDates[field] ? new Date(new Date(this.latestSyncDates[field]).getTime() + 1000) : new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000);
	      		healthKitQueryTasks$.push(this.health.query({
						  startDate: new Date(startDate.getTime()),
						  endDate: new Date(),
						  dataType: field,
						  limit: 1000
						}));
	      	});
	      	const requests = forkJoin(healthKitQueryTasks$).subscribe(results => {

	      		let logObjects : any[] = [];

	      		config.healthKit.fields.forEach((f, i)=>{
	      			let logObj : any = {
		      			timeZoneOffset : new Date().getTimezoneOffset(),
		      			data : {}
		      		}
	      			logObj.data[f] = results[i];
	      			logObjects.push(logObj);
	      		});

	      		logObjects.forEach((o, i)=>{
				    	setTimeout(fu=>{
				    		this.ohnApi.logDataToServ(o).subscribe();
				    	}, i*3000);
				    });

	      		/*let logObj : any = {
	      			timeZoneOffset : new Date().getTimezoneOffset(),
	      			data : {}
	      		}
	      		config.healthKit.fields.forEach((f, i)=>{
	      			logObj.data[f] = results[i];
	      		});
	      		this.ohnApi.logDataToServ(logObj).subscribe();*/
	      		//this.processData(results, config); 		
		      });
				});
      }
    })
    .catch(e => {console.log(e)});
	}

	processData(data : any[], config : any) {
    config.healthKit.fields.forEach((f, i)=>{
    	setTimeout(fu=>{
    		if (data[i].length > 0) {
	      	if (f == 'activity') {
	      		this.processActivityData(data[i]);
	      	} else {
	      		this.generateDataFrame(f, data[i]);
	      	}
	      }
    	}, i*3000);
    });
  }

  processActivityData(data: any[]) {
  	data = _.sortBy(data, (d)=>{ return new Date(d.endDate)});
  	this.latestSyncDates['activity'] = new Date(data[data.length-1].endDate);
  	this.activityTypes.forEach(t => {
  		let filteredByType : any[] = data.filter(d => {return d.value == t;});
  		if (filteredByType.length > 0) {
  			let fullDataFrame : any[] = [];
  			filteredByType.forEach(d => {
  				fullDataFrame.push({
  					time : d.startDate,
  					calories : 0,
  					distance : 0
  				});
		      let item : any = {};
		      item['time'] = d.endDate;
          item['calories'] = d.calories;
          item['distance'] = d.distance;
		      fullDataFrame.push(item);
		    });
		    fullDataFrame = _.sortBy(fullDataFrame, (d)=>{ return new Date(d.time)});
		    this.splitFullFrameByDays(fullDataFrame, 'activity_' + t);
  		}
  	});
  }

  generateDataFrame(field: string, data: any[]) {
  	let fullDataFrame : any[] = [];
  	data.forEach(d => {
      let item : any = {};
      item['time'] = d.startDate;
      switch (field) {
        case 'blood_pressure':
          item['systolic'] = d.value.systolic;
          item['diastolic'] = d.value.diastolic;
          break;
        
        default:
          item['value'] = d.value;
          break;
      }
      fullDataFrame.push(item);

    });
    fullDataFrame = _.sortBy(fullDataFrame, (d)=>{ return new Date(d.time)});
    this.latestSyncDates[field] = new Date(fullDataFrame[fullDataFrame.length-1].time);
    this.splitFullFrameByDays(fullDataFrame, field);
  }

  splitFullFrameByDays(frame: any[], field : string){
  	let setStateQueryTasks$ : Observable<any>[] = [];
    while(frame.length > 0) {
      let startDate = new Date(frame[0].time);
      startDate.setHours(0);
      let endDate = new Date(startDate.getTime() + (24 * 3600000) - 1);
      let frameChunk : any[] = frame.filter((f)=>{
        let fTime : number = new Date(f.time).getTime();
        return (fTime >= startDate.getTime() && fTime <= endDate.getTime());
      });
      setStateQueryTasks$.push(
      	this.ohnApi.setElementStateSc('hk_' + field, {value : frameChunk}, this.user.smart_contract)
      )

      frame = frame.filter((f)=>{
        let fTime : number = new Date(f.time).getTime();
        return (fTime < startDate.getTime() || fTime > endDate.getTime());
      });
    }
    let calendarHistory : any[] = [];
    const requests = forkJoin(setStateQueryTasks$).subscribe(results => {
  		let title : string;
  		switch (field) {
  			case 'heart_rate' : 
  				title = "Heart Rate";
  				break;
  			case 'blood_pressure' : 
  				title = "Blood Pressure";
  				break;
  			case 'weight' : 
  				title = "Weight";
  				break;
  			case 'steps' : 
  				title = "Steps";
  				break;
  			case 'distance' : 
  				title = "Distance";
  				break;
  			case 'calories' : 
  				title = "Calories";
  				break;
  			case 'height' : 
  				title = "Height";
  				break;
  			case 'oxygen_saturation' : 
  				title = "Oxygen Saturation";
  				break;
  			case 'activity' : 
  				title = "Activity";
  				break;
  			case 'fat_percentage' : 
  				title = "Fat Percentage";
  				break;
  			case 'activity_biking' : 
  				title = "Activity - biking";
  				break;
  			case 'activity_diving' : 
  				title = "Activity - diving";
  				break;
  			case 'activity_gymnastics' : 
  				title = "Activity - gymnastics";
  				break;
  			case 'activity_hiking' : 
  				title = "Activity - hiking";
  				break;
  			case 'activity_running' : 
  				title = "Activity - running";
  				break;
  			case 'activity_skiing' : 
  				title = "Activity - skiing";
  				break;
  			case 'activity_stairs' : 
  				title = "Activity - stairs";
  				break;
  			case 'activity_swimming' : 
  				title = "Activity - swimming";
  				break;
  			case 'activity_walking' : 
  				title = "Activity - walking";
  				break;
  			case 'activity_wheelchair' : 
  				title = "Activity - wheelchair";
  				break;
  			case 'activity_yoga' : 
  				title = "Activity - yoga";
  				break;

  			default :
  				title = "Health Data"
  		}

  		results.forEach(r => {
  			calendarHistory.push({
			    smart_contract: this.user.smart_contract,
			    value: {
			      allDay: true,
			      controller: "dataframe",
			      startTime: r.value.time[0],
			      completed: true,
			      title: title,
			      inner_element_slug: 'hk_' + field,
			      inner_element_state_id: r.id
			    }
			  })
  		});
  		
  		this.saveCalendarEvents(calendarHistory);
  		this.saveLatestSyncElementState();
    });
  }

	saveCalendarEvents(data: any[]){
		this.ohnApi.setElementHistory('calendar_container', data).subscribe();
		this.notifyOfSaved();
	}

	saveLatestSyncElementState() {
		this.ohnApi.setElementStateSc('hk_sync_dates', {value : this.latestSyncDates}, this.user.smart_contract).subscribe();
	}

	patchDevice(device : any) {
		this.ohnApi.patchDevice(device).subscribe();
	}

	async notifyOfSaved() {
  	const toast = await this.toastController.create({
      message: 'Health Kit Data Synced',
      duration: 3000,
      position: 'middle'
    });
    toast.present();
  }
}