import { Component, OnInit, Input } from '@angular/core';
import { OHNElement } from '../../../models/ohn-instances';
import { OhnLogicService } from '../../../services/ohn-logic.service';

@Component({
  selector: 'app-date-field-element',
  templateUrl: './date-field-element.component.html',
  styleUrls: ['./date-field-element.component.scss'],
})
export class DateFieldElementComponent implements OnInit {

  @Input() el: OHNElement;
  @Input() viewMode : string;

  validityRules : any[];

  constructor(
    private ohnLogicService: OhnLogicService,
  ) { }

  ngOnInit() {
  	this.el.value = this.el.value || null;
    this.validityRules = (this.el.config && this.el.config.validityRules) ? this.el.config.validityRules : [];
    this.el.valid = this.validityRules.length > 0 ? false : true;
  }

  changed() {
    this.el.valid = this.ohnLogicService.processValidityRules(this.validityRules, this.el.controller, this.el.value);
  }

}
