import { Component, OnInit, Input } from '@angular/core';
import { DatePipe } from '@angular/common';
import { OHNUser, OHNElement } from '../../../models/ohn-instances';
import { OhnApiService } from '../../../services/ohn-api.service';
import * as _ from 'underscore/underscore';
import { Subject } from 'rxjs';

import { DEBUG_MODE } from '../../../../environments/environment';
 
@Component({
  selector: 'app-blood-pressure-history-renderer',
  templateUrl: './blood-pressure-history-renderer.component.html',
  styleUrls: ['./blood-pressure-history-renderer.component.scss'],
})
export class BloodPressureHistoryRendererComponent implements OnInit {

	@Input() element: OHNElement;
	@Input() user: OHNUser;
	@Input() period: string;
	@Input() date: Date;

	chartUpdater : Subject<any> = new Subject();

	formattedData = {
		value : {
			value : []
		}
	};

	datePipe : DatePipe = new DatePipe('en-US');

  constructor(
  	private ohnApi: OhnApiService
  ) { }

  ngOnInit() {
  	this.loadHistory();
  }

  loadHistory(){
  	this.formattedData['value']['value'] = [];
  	this.formattedData['value']['value'] = [
  	]
  	this.ohnApi.getElementHistoryByDatesScCostil(this.element.element_slug, [this.datePipe.transform(this.date, 'yyyy-MM-dd'), this.datePipe.transform(this.date, 'yyyy-MM-dd')], this.user.smart_contract).subscribe(history=>{
  		DEBUG_MODE && console.log('Element History', history);
  		if (history) {
  			let keys : string[] = _.keys(history);
  			keys.forEach((k, index)=>{
  				this.formattedData['value']['value'].push({time : new Date(k), Systolic : history[k].value.systolic, Diastolic : history[k].value.diastolic});
  			});
  			this.chartUpdater.next(this.formattedData);
  			DEBUG_MODE && console.log('Formatted Data', this.formattedData);
  		}
  	});
  }

}
