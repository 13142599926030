import { Component, OnInit, Input } from '@angular/core';
import { ModalController, ToastController, PopoverController } from '@ionic/angular';
import { OHNUser, OHNEventSequence, OHNEventSequenceItem, OHNCalendarEvent, OHNMedication, OHNEventPreset, OHNElement, OHNCalendarAction, OHNCalendarActionList, OHNCalendarSequence, OHNUserRole } from '../../models/ohn-instances';
import { OhnApiService } from '../../services/ohn-api.service';
import { CalendarEventSchedulerComponent } from '../../components/calendar-event-scheduler/calendar-event-scheduler.component';
import { OhnService } from '../../services/ohn.service';
import { RuleEditorComponent } from '../../components/rule-editor/rule-editor.component';
import * as _ from 'underscore/underscore';

import { DEBUG_MODE, IS_RAD_ONC_APP, INSTACART_ENABLED } from '../../../environments/environment';

@Component({
  selector: 'app-calendar-sequence-manager',
  templateUrl: './calendar-sequence-manager.page.html',
  styleUrls: ['./calendar-sequence-manager.page.scss'],
})
export class CalendarSequenceManagerPage implements OnInit {

	//@Input() calendarElement : OHNElement;

	editingCalendarAction : OHNCalendarActionList;

	calendarActions : OHNCalendarActionList[] = [];

	calendarSequence : OHNCalendarSequence;

  currentPresetActionIndex : number;

	calendarSequenceList : OHNCalendarSequence[] = [];

	numberOfWeeks : number = 1;

	isCloning : boolean = false;

	isNewAction : boolean = false;

	isNewSequence : boolean = false;

	loading: boolean = false;

  ///////////////////////////////////////////////////////////////

  surveyList : OHNElement;

  workoutList : OHNElement;

  medicationList : OHNEventPreset[];

  meetingList : OHNEventPreset[];

  textMessageList : OHNEventPreset[];

  instacartList : OHNEventPreset[];

  sequenceList : OHNEventSequence[];

  currentSurvey : OHNElement;

  currentSequence : OHNEventSequence;

  currentWorkout : OHNElement;

  currentPage : OHNElement;

  currentMedication : OHNEventPreset;

  currentMeeting : OHNEventPreset;

  currentMessage : OHNEventPreset;

  currentInstance : OHNEventPreset;

  eventTypeSegment : string = 'survey';

  segmentManage: string = 'actions';

  roles: OHNUserRole[] = [];

  currentRole : OHNUserRole;

  currentProfile : OHNElement;

  profiles : any = {};

  app : OHNElement;

  IS_RAD_ONC_APP = IS_RAD_ONC_APP;

  INSTACART_ENABLED = INSTACART_ENABLED;

  healthKitDataTypes : any[] = [
    {
      slug : 'weight',
      text : 'Weight',
      enabled : false
    },
    {
      slug : 'blood_pressure',
      text : 'Blood Pressure',
      enabled : false
    },
    {
      slug : 'heart_rate',
      text : 'Heart Rate',
      enabled : false
    },
    {
      slug : 'steps',
      text : 'Steps',
      enabled : false
    },
    {
      slug : 'distance',
      text : 'Distance',
      enabled : false
    },
    {
      slug : 'calories',
      text : 'Calories',
      enabled : false
    },
    {
      slug : 'height',
      text : 'Height',
      enabled : false
    },
    {
      slug : 'activity',
      text : 'Activity',
      enabled : false
    },
    {
      slug : 'fat_percentage',
      text : 'Fat Percentage',
      enabled : false
    }
  ];

  constructor(
  	private modalController : ModalController,
  	private ohnApi: OhnApiService,
  	private ohnService: OhnService,
  	private toastController: ToastController,
  	public popoverController: PopoverController
  ){ }

  ngOnInit() {
    this.loadSurveyList();
    this.loadWorkoutList();
    this.loadMedicationList();
    this.loadMeetingList();
    this.loadSequenceList();
    if (IS_RAD_ONC_APP) {
      this.loadTextMessageList();  
    }
    if (INSTACART_ENABLED) {
      this.loadInstacartList();
    }
    this.loadRoles();
    this.loadApp();
  }

  loadApp() {
    this.ohnApi.getElement('app', 0).subscribe(app => {
      this.app = <OHNElement>app;
      this.app.config.healthKit.fields.forEach(f=>{
        this.healthKitDataTypes.find(dt=>{
          return dt.slug == f;
        }).enabled = true;
      });
    });
  }

  updateDataTypes() {
    this.app.config.healthKit.fields = this.healthKitDataTypes.filter(dt=>{return dt.enabled;}).map(dt=>{return dt.slug;});
  }

  saveHealthKitSettings() {
    this.ohnApi.setElement('app', this.app).subscribe(app => {
    });
    this.notifyOfSaved('Sync Settings Updated');
  }

  loadRoles(){
    this.ohnApi.getAvailableRoles().subscribe(roleList => {
      this.roles = roleList;
      this.roles.forEach(r=>{
        this.loadProfile(r);
      })
    });
  }

  selectRole(role: OHNUserRole){
    this.currentProfile = this.profiles[role.name];
  }

  loadProfile(role : OHNUserRole) {
    this.ohnApi.getElement(this.ohnService.appRoleModifier(role.name, false)+'_profile' , 4).subscribe(profile => {
      this.profiles[role.name] = profile;
    });
  }

  loadSurveyList(){
    this.loading = true;
    this.ohnApi.getElement('surveys_container', 4).subscribe(surveyList => {
      this.surveyList = surveyList;
      DEBUG_MODE && console.log("Survey List", this.surveyList);
      this.loading = false;
    });
  }

  loadMedicationList(){
    this.loading = true;
    this.ohnApi.getElement('medications_container', 1).subscribe(medicationContainer => {
      this.medicationList = (medicationContainer.config && medicationContainer.config.medicationList) ? JSON.parse(medicationContainer.config.medicationList) : [];
      DEBUG_MODE && console.log('Medication List',this.medicationList);
      this.loading = false;
    });
  }

  loadMeetingList(){
    this.loading = true;
    this.ohnApi.getElement('meetings_container', 1).subscribe(meetingContainer => {
      this.meetingList = (meetingContainer.config && meetingContainer.config.meetingList) ? JSON.parse(meetingContainer.config.meetingList) : [];
      this.loading = false;
    });
  }

  loadInstacartList(){
    this.loading = true;
    this.ohnApi.getElement('instacart_instance_container', 1).subscribe(instacartInstanceContainer => {
      this.instacartList = (instacartInstanceContainer.config && instacartInstanceContainer.config.instanceList) ? JSON.parse(instacartInstanceContainer.config.instanceList) : [];
      this.loading = false;
    });
  }

  loadTextMessageList(){
    this.loading = true;
    this.ohnApi.getElement('text_messages_container', 1).subscribe(textMessageContainer => {
      this.textMessageList = (textMessageContainer.config && textMessageContainer.config.messageList) ? JSON.parse(textMessageContainer.config.messageList) : [];
      this.loading = false;
    });
  }

  loadSequenceList(){
    this.loading = true;
    this.ohnApi.getElement('sequences_container', 1).subscribe(sequenceContainer => {
      this.sequenceList = (sequenceContainer.config && sequenceContainer.config.sequencesList) ? JSON.parse(sequenceContainer.config.sequencesList) : [];
      DEBUG_MODE && console.log('Sequence List', this.sequenceList);
      this.loading = false;
    });
  }

  loadWorkoutList(){
    this.loading = true;
    this.ohnApi.getElement('workouts_container', 4).subscribe(workoutList => {
      this.workoutList = workoutList;
      this.loading = false;
    });
  }

  selectSurvey(index: number) {
    this.currentSurvey = this.surveyList.elements[index];
    (!this.currentSurvey.config) && (this.currentSurvey['config'] = {});
    DEBUG_MODE && console.log("Current Survey", this.currentSurvey);
    this.currentPage = this.currentSurvey.elements[0];
  }

  selectWorkout(index: number) {
    this.currentWorkout = this.workoutList.elements[index];
    this.currentPage = this.currentWorkout.elements[0];
  }

  selectMedication(index: number) {
    this.currentMedication = this.medicationList[index];
  }

  selectSequence(index: number) {
    this.currentSequence = this.sequenceList[index];
  }

  selectMeeting(index: number) {
    this.currentMeeting = this.meetingList[index];
  }

  selectMessage(index: number) {
    this.currentMessage = this.textMessageList[index];
  }

  selectInstance(index: number) {
    this.currentInstance = this.instacartList[index];
  }

  addSurvey() {
    this.surveyList.elements.push(
      <OHNElement>{
        text : 'New Survey',
        _cls : 'Page',
        controller : 'branchedContentController',
        elements : []
      }
    );
    this.currentSurvey = this.surveyList.elements[this.surveyList.elements.length - 1];
    this.currentPage = this.currentSurvey.elements[0];
  }

  addWorkout() {
    this.workoutList.elements.push(
      <OHNElement>{
        text : 'New Workout',
        _cls : 'Page',
        controller : 'branchedContentController',
        elements : [],
        config: {
          isFitbitWorkout : false
        }
      }
    );
    this.currentWorkout = this.workoutList.elements[this.workoutList.elements.length - 1];
    this.currentPage = this.currentWorkout.elements[0];
  }

  addMedication() {
    this.medicationList.push(
      <OHNEventPreset>{
        title : 'New Medication',
        description : '',
        id : OhnService.getUniqueId(this.medicationList),
        contentObject : {
          dose : ''
        }
      }
    );
    this.currentMedication = this.medicationList[this.medicationList.length - 1];
  }

  addSequence() {
    this.sequenceList.push(
      <OHNEventSequence>{
        title : 'New Sequence',
        description : '',
        days : {},
        numberOfWeeks : 1,
        id : OhnService.getUniqueId(this.sequenceList)
      }
    );
    this.currentSequence = this.sequenceList[this.sequenceList.length - 1];
  }

  addMeeting() {
    this.meetingList.push(
      <OHNEventPreset>{
        title : 'New Meeting',
        description : '',
        id : OhnService.getUniqueId(this.meetingList),
        contentObject : {
          dose : ''
        }
      }
    );
    this.currentMeeting = this.meetingList[this.meetingList.length - 1];
  }

  addMessage() {
    this.textMessageList.push(
      <OHNEventPreset>{
        title : 'New Text Message',
        description : '',
        id : OhnService.getUniqueId(this.textMessageList),
        inner_element_slug : ''
      }
    );
    this.currentMessage = this.textMessageList[this.textMessageList.length - 1];
  }

  addInstance() {
    this.instacartList.push(
      <OHNEventPreset>{
        title : 'New Instacart Instance',
        text : 'Go To Instacart',
        id : OhnService.getUniqueId(this.instacartList),
        image_url : ''
      }
    );
    this.currentInstance = this.instacartList[this.instacartList.length - 1];
  }

  addPage() {
    this.currentSurvey.elements.push(
      <OHNElement>{
        text : 'Survey Page',
        _cls : 'Page',
        controller : 'pageController',
        elements : []
      }
    );
    this.currentPage = this.currentSurvey.elements[this.currentSurvey.elements.length - 1];
  }

  addWorkoutPage() {
    this.currentWorkout.elements.push(
      <OHNElement>{
        text : 'Workout Step',
        _cls : 'Page',
        controller : 'pageController',
        elements : []
      }
    );
    this.currentPage = this.currentWorkout.elements[this.currentWorkout.elements.length - 1];
  }

  addElement(element: OHNElement) {
    this.currentPage.elements.push(element);
  }

  addElementToProfile(element: OHNElement) {
    this.currentProfile.elements.push(element);
  }

  deleteFromSequence(eventIndex: number, day: number) {
    this.currentSequence.days[day].splice(eventIndex, 1);
  }

  deleteElement(elementIndex: number) {
    this.currentPage.elements.splice(elementIndex, 1);
  }

  deleteElementFromProfile(elementIndex: number) {
    this.currentProfile.elements.splice(elementIndex, 1);
  }

  saveProfile(){
    this.loading = true;
    if (this.currentProfile) {
      this.ohnApi.setElement(this.currentProfile.element_slug, this.currentProfile).subscribe(profile => {
        this.loading = false;
      });
    }
  }

  deleteCurrentSurvey() {
    this.surveyList.elements = this.surveyList.elements.filter((e)=>{ return e !== this.currentSurvey});
    this.currentSurvey = this.surveyList.elements[0];
    this.ohnApi.setElement('surveys_container', this.surveyList).subscribe(surveyList => {
      this.notifyOfSaved('Survey Deleted');
    });
  }

  deleteCurrentWorkout() {
    this.workoutList.elements = this.workoutList.elements.filter((e)=>{ return e !== this.currentWorkout});
    this.currentWorkout = this.workoutList.elements[0];
    this.ohnApi.setElement('workouts_container', this.workoutList).subscribe(workoutList => {
      this.notifyOfSaved('Workout Deleted');
    });
  }

  deleteCurrentMedication() {
    this.medicationList = this.medicationList.filter((e)=>{ return e !== this.currentMedication});
    this.currentMedication = this.medicationList[0];
    this.ohnApi.setElement('medications_container', {config : {medicationList : JSON.stringify(this.medicationList)}}).subscribe(medicationContainer => {
      this.notifyOfSaved('Medication Deleted');
    });
  }

  deleteCurrentMeeting() {
    this.meetingList = this.meetingList.filter((e)=>{ return e !== this.currentMeeting});
    this.currentMeeting = this.meetingList[0];
    this.ohnApi.setElement('meetings_container', {config : {meetingList : JSON.stringify(this.meetingList)}}).subscribe(meetingContainer => {
      this.notifyOfSaved('Meeting Deleted');
    });
  }

  deleteCurrentMessage() {
    this.textMessageList = this.textMessageList.filter((e)=>{ return e !== this.currentMessage });
    this.currentMessage = this.textMessageList[0];
    this.ohnApi.setElement('text_messages_container', {config : {messageList : JSON.stringify(this.textMessageList)}}).subscribe(messageContainer => {
      this.notifyOfSaved('Text Message Deleted');
    });
  }

  deleteCurrentInstance() {
    this.instacartList = this.instacartList.filter((e)=>{ return e !== this.currentInstance });
    this.currentInstance = this.instacartList[0];
    this.ohnApi.setElement('instacart_instance_container', {config : {instanceList : JSON.stringify(this.instacartList)}}).subscribe(instanceContainer => {
      this.notifyOfSaved('Instacart Instance Deleted');
    });
  }

  deleteCurrentSequence() {
    this.sequenceList = this.sequenceList.filter((e)=>{ return e !== this.currentSequence});
    this.currentSequence = this.sequenceList[0];
    this.ohnApi.setElement('sequences_container', {config : {sequencesList : JSON.stringify(this.sequenceList)}}).subscribe(sequenceContainer => {
      this.notifyOfSaved('Sequence Deleted');
    });
  }

  deleteCurrentPage() {
    this.currentSurvey.elements = this.currentSurvey.elements.filter((e)=>{ return e !== this.currentPage});
    this.currentPage = this.currentSurvey.elements[0];
  }

  deleteCurrentStep() {
    this.currentWorkout.elements = this.currentWorkout.elements.filter((e)=>{ return e !== this.currentPage});
    this.currentPage = this.currentWorkout.elements[0];
  }

  selectPage(page: OHNElement) {
    this.currentPage = page;
  }

  saveSurveyList(){
    this.loading = true;
    if (this.currentSurvey.element_slug) {
      this.ohnApi.setElement(this.currentSurvey.element_slug, this.currentSurvey).subscribe(survey => {
        this.updateContainerElement(this.currentSurvey, false);
      });
    } else {
      this.ohnApi.setElement('surveys_container', this.surveyList).subscribe(surveyList => {
        this.currentSurvey.element_slug = surveyList.elements[surveyList.elements.length - 1].element_slug;
        this.updateContainerElement(this.currentSurvey, true);
      });
    }
  }

  saveWorkoutList() {
    this.loading = true;
    if (this.currentWorkout.element_slug) {
      this.ohnApi.setElement(this.currentWorkout.element_slug, this.currentWorkout).subscribe(workout => {
        this.updateContainerElement(this.currentWorkout, false);
      });
    } else {
      this.ohnApi.setElement('workouts_container', this.workoutList).subscribe(workoutList => {
        this.currentWorkout.element_slug = workoutList.elements[workoutList.elements.length - 1].element_slug;
        this.updateContainerElement(this.currentWorkout, true);
      });
    }
  }

  saveMedicationList() {
    this.loading = true;
    this.ohnApi.setElement('medications_container', {config : {medicationList : JSON.stringify(this.medicationList)}}).subscribe(medicationContainer => {
      this.loading = false;
    });
  }

  saveMeetingList() {
    this.loading = true;
    this.ohnApi.setElement('meetings_container', {config : {meetingList : JSON.stringify(this.meetingList)}}).subscribe(meetingContainer => {
      this.loading = false;
    });
  }

  saveMessageList() {
    this.loading = true;
    this.ohnApi.setElement('text_messages_container', {config : {messageList : JSON.stringify(this.textMessageList)}}).subscribe(messageContainer => {
      this.loading = false;
    });
  }

  saveInstanceList() {
    this.loading = true;
    this.ohnApi.setElement('instacart_instance_container', {config : {instanceList : JSON.stringify(this.instacartList)}}).subscribe(instanceContainer => {
      this.loading = false;
    });
  }

  saveSequenceList() {
    this.loading = true;
    this.ohnApi.setElement('sequences_container', {config : {sequencesList : JSON.stringify(this.sequenceList)}}).subscribe(sequenceContainer => {
      this.loading = false;
    });
  }

  //deeper element pull to get slugs
  updateContainerElement(element: OHNElement, needsReportCreation: boolean) {
    this.ohnApi.getElement(element.element_slug, 3).subscribe(responseElement => {
      this.loading = false;
      this.notifyOfSaved('Updated');
      element = <OHNElement>responseElement;
      if (needsReportCreation) {
        element.report_element = <OHNElement>{
          text : 'Report for ' + element.text,
          element_slug : 'report' + element.text.replace(/ |\#|\&|\%|\?|\@|\/|\\/ig, '_').toLowerCase() + new Date().getTime(),
          elements : this.collectBasicElements(element),
          _cls : 'ReportElement',
          controller : 'reportElementController'
        }
      } else {
        element.report_element.elements = this.collectBasicElements(element)
      }
      this.ohnApi.setElement(element.element_slug, {report_element : element.report_element}).subscribe();
    });
  }

  collectBasicElements(element: OHNElement) {
    let basicElements : OHNElement[] = [];
    element.elements.forEach(p => {
      p.elements.forEach(e => {
        if (['stringFieldController', 'numericFieldController', 'dateTimeFieldController', 'pickOneDefaultController', 'pickManyDefaultController', 'yesNoController', 'speedSensorElementController', 'noiseLevelElementController', 'heartRateElementController', 'bloodPressureElementController', 'fitbitHeartRateElementController', 'timerElementController', 'numericPickOneController', 'periodToDaysController', 'randomizationElementController', 'textMessageController', 'hiddenStringController', 'currentTimestampPickerController', 'numericSliderFieldController'].indexOf(e.controller) >= 0) {
          basicElements.push(e);
        }
      });
    });
    return basicElements;
  }

  async showSchedulerModal(week: number, day : number) {
    const modal = await this.modalController.create({
      component: CalendarEventSchedulerComponent,
      componentProps: {
        workoutList : this.workoutList,
        surveyList : this.surveyList,
        medicationList : this.medicationList,
        meetingList : this.meetingList,
        textMessageList : this.textMessageList,
        instacartList : this.instacartList,
        viewType : 'sequence',
        selectedDate : new Date()
      },
      backdropDismiss: true,
      mode: "md"
    });

    modal.onDidDismiss().then((res) => {
      if (res.data) {
        if (!this.currentSequence.days[week*7 + day]) this.currentSequence.days[week*7 + day] = [];
        this.currentSequence.days[week*7 + day].push(<OHNEventSequenceItem>{
          title : (['workout', 'survey'].indexOf(res.data.type) >= 0) ? res.data.element.text : res.data.element.title,
          elementId : (['workout', 'survey'].indexOf(res.data.type) >= 0) ? res.data.element.element_slug : res.data.element.id,
          type : res.data.type
        });
      }
    });

    return await modal.present();
  }

  async notifyOfSaved(text: string) {
    const toast = await this.toastController.create({
      message: text,
      duration: 2000
    });
    toast.present();
  }

  newWeek() {
    this.currentSequence.numberOfWeeks++;
  }

  async openRuleEditor() {
    const modal = await this.modalController.create({
      component: RuleEditorComponent,
      componentProps: {
        parentElement : this.currentSurvey
      },
      backdropDismiss: true,
      mode: "md"
    });

    modal.onDidDismiss().then((res) => {
      if (res.data) {
      
      }
    });

    return await modal.present();
  }
}