import { Component, OnInit, Input } from '@angular/core';
import { OHNElement, OHNUser } from '../../../models/ohn-instances';
import { OhnLogicService } from '../../../services/ohn-logic.service';

@Component({
  selector: 'app-text-message-element',
  templateUrl: './text-message-element.component.html',
  styleUrls: ['./text-message-element.component.scss'],
})
export class TextMessageElementComponent implements OnInit {

  @Input() el: OHNElement;
  @Input() user : OHNUser;
  validityRules : any[];

  validInput : boolean = true;

  constructor(
    private ohnLogicService: OhnLogicService,
  ) { }

  ngOnInit() {
    this.el.value = this.el.value || {phone_number: null} ;
    this.validityRules = (this.el.config && this.el.config.validityRules) ? this.el.config.validityRules : [];
    this.el.valid = this.validityRules.length > 0 ? false : true;
  }

  changed() {
    this.el.valid = this.ohnLogicService.processValidityRules(this.validityRules, this.el.controller, this.el.value.phone_number);
    this.validInput = this.el.value.phone_number == "" ? true : this.checkPhoneNumberValidity();
  }

  checkPhoneNumberValidity() {

    let re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

    return re.test(this.el.value.phone_number);
  }

}
