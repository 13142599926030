import { Injectable } from '@angular/core';
import { OHNLogicNode, OHNLogicTransition, OHNLogicTree, OHNTriggerAction, OHNCalendarEvent, OHNEventSequence } from '../models/ohn-instances';
import { OhnApiService } from './ohn-api.service';
import * as _ from 'underscore/underscore';

@Injectable({
  providedIn: 'root'
})
export class OhnLogicService {

  logicTree : OHNLogicTree;
  valueContainer : any;
  currentNode : OHNLogicNode;
  previousNode : OHNLogicNode;
  finalNodeReached : boolean;
  reserveNodeId : string;

  constructor(
    private ohnApi : OhnApiService,
  ) { }

  init(logicTree: any, valueContainer: any, startNodeId : string) {
    this.logicTree = new OHNLogicTree(logicTree);
    this.valueContainer = valueContainer;
    this.currentNode = this.logicTree.nodes.find(node => node.id === startNodeId);
    this.finalNodeReached = this.currentNode.transitions.length === 0;
    return this.logicTree;
  }

  getNextNode() {
    if (!this.finalNodeReached) {  
      let fulfilledTransitions: any[] = this.processTransitions(this.currentNode.transitions);
      if (fulfilledTransitions.length > 0) {
        this.previousNode = this.currentNode;
        this.currentNode = this.logicTree.nodes.find(node => node.id === fulfilledTransitions[fulfilledTransitions.length - 1].params.nextNode);
        this.currentNode.previousNodeId = this.previousNode.id;
        this.finalNodeReached = this.currentNode.transitions.length === 0 && !this.reserveNodeId;
        return this.currentNode;
      } else if (this.reserveNodeId) {
        this.previousNode = this.currentNode;
        this.currentNode = this.logicTree.nodes.find(node => node.id === this.reserveNodeId);
        this.currentNode.previousNodeId = this.previousNode.id;
        this.reserveNodeId = undefined;
        this.finalNodeReached = this.currentNode.transitions.length === 0;
        return this.currentNode;
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  }

  goToNode(nodeId: string) {
    this.currentNode = this.logicTree.nodes.find(node => node.id === nodeId);
  }

  processTransitions(transitions: any[]) {
    let transitionResults : any[] = [];
    transitions.forEach(t => {
      let transitionRes: any = this.processTransition(t);
      if (transitionRes.value) {
        if (transitionRes.params.reserveNodeId) {
          this.reserveNodeId = transitionRes.params.reserveNodeId;
        }
        transitionResults.push(transitionRes);
        if (t.triggerActions && t.triggerActions.length > 0) {
          this.processTriggers(t.triggerActions);
        }
      }
    });
    return transitionResults;
  }

  processTriggers(triggerActions : OHNTriggerAction[]) {
    triggerActions.forEach((action)=>{
      switch (action.type) {
        case 'sendTextMessage' :
          let messageExtras : string = "username - " + localStorage.getItem('OHNcurrentBranchedLogicUserName') + ' ; ';
          let fitConditions : boolean = true;
          if (action.params.extraElements) {
            action.params.extraElements.forEach((slug)=>{
              if (this.valueContainer[slug]) {
                messageExtras += ', ' + this.valueContainer[slug].text + ' - ' + this.valueContainer[slug].value;
                if (action.params.conditionalValues) {
                  let cond: boolean = false;
                  action.params.conditionalValues.forEach(v=>{
                    if (this.valueContainer[slug].value.indexOf(v) >= 0) {
                      cond = true;
                    }
                  })
                  fitConditions = cond;
                }
              }
            });
          }
          if (fitConditions) {
            this.ohnApi.setElementState(action.params.elementSlug, {value : {text : messageExtras}}).subscribe();
          }
          break;

        case 'sendPresetTextMessage' :
          if (this.valueContainer[action.params.phoneNumberSlug] && this.valueContainer[action.params.phoneNumberSlug].value && this.valueContainer[action.params.phoneNumberSlug].value != "") {
            this.ohnApi.setElementState(action.params.elementSlug, {value : {phone_number : this.valueContainer[action.params.phoneNumberSlug].value}}).subscribe();
          }
          break;

        case 'runTextMessageSequence' :
          if (action.params.sequenceId) {
            setTimeout(()=>{
              this.scheduleTextMessageSequence(action.params.sequenceId);
            }, 500)
            //this.actionsProcessor.scheduleTextMessageSequence(action.params.sequenceId)
          }
          break;
      }
    })
  }

  processTransition(transition: OHNLogicTransition) {
    let transitionResult : any;
    switch (transition.type) {
      case 'compare':
        transitionResult = {
          params : {
            nextNode : transition.nextNode,
            reserveNodeId : transition.reserveNodeId
          },
          value : this.comparisonResult(transition)
        }
        break;
      case 'noConditions':
        transitionResult = {
          params : {
            nextNode : transition.nextNode,
            reserveNodeId : transition.reserveNodeId
          },
          value : true
        }
        break;
      case 'AND':
        let conjunctionRes = this.processTransitions(transition.transitions);
        transitionResult = {
          params : {
            nextNode : transition.nextNode,
            reserveNodeId : transition.reserveNodeId
          },
          value : conjunctionRes.length == transition.transitions.length
        }
        break;
      case '!AND':
        let conjunctionResNegative = this.processTransitions(transition.transitions);
        transitionResult = {
          params : {
            nextNode : transition.nextNode,
            reserveNodeId : transition.reserveNodeId
          },
          value : conjunctionResNegative.length != transition.transitions.length
        }
        break;
      case 'OR':
        let disjunctionRes = this.processTransitions(transition.transitions);
        transitionResult = {
          params : {
            nextNode : transition.nextNode,
            reserveNodeId : transition.reserveNodeId
          },
          value : disjunctionRes.length > 0
        }
        break;
    }
    return transitionResult;
  }

  comparisonResult(transition: OHNLogicTransition) {
    let result : boolean;
    let compareToValue : any = this.valueContainer[transition.compareToNode].value;
    if (compareToValue === undefined) {
      result = true;
    } else {
      switch (transition.operator) {
        case '=':
          result = transition.operand == compareToValue;
          break;
        case '!=':
          result = transition.operand != compareToValue;
          break;
        case '>':
          result = transition.operand > compareToValue;
          break;
        case '<':
          result = transition.operand < compareToValue;
          break;
        case '>=':
          result = transition.operand >= compareToValue;
          break;
        case '<=':
          result = transition.operand <= compareToValue;
          break;
        case 'contains':
          result = compareToValue.indexOf(transition.operand) >= 0;
          break;
        case '!contains':
          result = compareToValue.indexOf(transition.operand) < 0;
          break;
      }
    }
    return result;
  }

  processValidityRules(rules: any[], elementController : string, elementValue : any) {
    let result : boolean = true;

    rules.forEach((r)=>{

      switch(r.ruleType){
        case 'valueNotEmpty' :
          switch (elementController) {
            case 'stringFieldController' :
              if (elementValue === undefined || elementValue === null || elementValue === '') {
                result = false
              }
              break;

            case 'textMessageController' :
              if (elementValue === undefined || elementValue === null || elementValue === '') {
                result = false
              }
              break;

            case 'pickManyDefaultController' :
              if (elementValue === undefined || elementValue === null || elementValue.length == 0) {
                result = false
              }
              break;

            default :
              if (elementValue === undefined || elementValue === null) {
                result = false
              }
          }
          break;
      }

    })

    return result;
  }

  getValueFromElementContainer(elementSlug: string) {
    return this.valueContainer[elementSlug].value;
  }

  setValueOfElementContainer(elementSlug: string, value: any) {
    if (this.valueContainer[elementSlug]) {
      this.valueContainer[elementSlug].value = value;
    }
  }

  //Sequence Scheduler - only text messages for now bc need to pull all workouts and surveys for other type of events//

  scheduleTextMessageSequence(sequenceId: string) {
    this.ohnApi.getElement('sequences_container', 1).subscribe(sequenceContainer => {
      const sequenceList = (sequenceContainer.config && sequenceContainer.config.sequencesList) ? JSON.parse(sequenceContainer.config.sequencesList) : [];  
      console.log('Sequence List', sequenceList);
      let sequence = sequenceList.find((s)=>{
        return s.id == sequenceId;
      });
      console.log('Sequence', sequence);
      if (sequence) {
        let textMessageList : any[] = [];
        this.ohnApi.getElement('text_messages_container', 1).subscribe(textMessageContainer => {
          textMessageList = (textMessageContainer.config && textMessageContainer.config.messageList) ? JSON.parse(textMessageContainer.config.messageList) : [];
          this.runTextMessageSequence(sequence, textMessageList);
        });
      }
    });
  }

  runTextMessageSequence(sequence : any, textMessageList : any[]) {
    let currentSequence : OHNEventSequence = <OHNEventSequence>sequence;
    let keys : string[] = _.keys(sequence.days);
    let textMessageEvents : any[] = [];
    const currentDate = new Date(new Date().getTime() + 600000);
    const smartContract = localStorage.getItem('OHNcurrentBranchedLogicUser');
    keys.forEach((k)=>{
      const dayEvents = currentSequence.days[k].filter((e)=>{
        return e.type == 'text_message';
      })
      if (dayEvents.length > 0) {
        textMessageEvents = textMessageEvents.concat(this.getFormattedEventsWithDateShift(dayEvents, k, textMessageList, currentDate))
      }
    });

    if (textMessageEvents.length > 0) {
      textMessageEvents = textMessageEvents.map((e)=>{return {smart_contract : smartContract, value : e}});
    }
     console.log(textMessageEvents);
    this.ohnApi.setElementHistory('calendar_container', textMessageEvents).subscribe(history => {
     
    });
  }

  getFormattedEventsWithDateShift(events: any[], dayShift: string, elementList: any[], startDate: Date) {
    let formattedEvents : any[] = [];
    events.forEach((e)=>{
      e = elementList.find((el)=>{return el.id === e.elementId});
      let eventDate : Date = new Date(startDate);
      eventDate.setDate(eventDate.getDate() + parseInt(dayShift));
      e.startTime = eventDate.toISOString();
      formattedEvents.push(this.generateTextMessageEvent(e));
    });
    return formattedEvents;
  }

  generateTextMessageEvent(message: OHNCalendarEvent) {
    return new OHNCalendarEvent({
          title : message.title,
          startTime : message.startTime,
          completed : false,
          allDay: true,
          controller : 'text_message',
          description : message.description,
          inner_element_slug : message.inner_element_slug
        });
  }
}