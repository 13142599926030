import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'textReplacements'})
export class textReplacementsPipe implements PipeTransform {
  transform(value: string): string {
  	if (localStorage.getItem('ohnReplacements')) {
  		let replacements : any = JSON.parse(localStorage.getItem('ohnReplacements'));
  		replacements.settings.pipe.forEach((p)=>{
  			if (replacements.data[p.replace]) {
  				let replaceWhat : string = p.replace;
  				let replaceWith : string = replacements.data[p.replace];
  				let regex : RegExp = new RegExp("\\^" + replaceWhat + "\\^", "g");
  				value = value.replace(regex, replaceWith);
  			}
  		});
    	return value;
  	} else {
  		return value;
  	}
  }
}