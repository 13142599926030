import { Component, OnInit, Input } from '@angular/core';
import { AlertController, ModalController, PopoverController } from '@ionic/angular';
import { formatDate } from '@angular/common';
import { OHNElement, OHNUser, OHNCalendarEvent } from '../../../models/ohn-instances';
import { OhnApiService } from '../../../services/ohn-api.service';
import { Subject} from 'rxjs';
import { API_URL, DEBUG_MODE } from '../../../../environments/environment';
import { PopoverComponent } from '../../../components/basic/popover/popover.component';
import { CalendarEventViewPage } from '../../../secured/calendar-event-view/calendar-event-view.page';
import { BranchedContentRendererComponent } from '../../branched-content-renderer/branched-content-renderer.component';
import { AuthenticationService } from '../../../services/authentication.service';

@Component({
  selector: 'app-patient-surveys-widget',
  templateUrl: './patient-surveys-widget.component.html',
  styleUrls: ['./patient-surveys-widget.component.scss'],
})
export class PatientSurveysWidgetComponent implements OnInit {

  @Input() el: OHNElement;
  @Input() me: OHNUser;
  @Input() refresher : Subject<boolean>;


  staff : any[] = [];

  currentScreenState : string = 'start';

  pin : number = 1234;

  patientEmail : string = '';

  confirmEmail : string = '';

  currentPatient : OHNUser;

  currentStaff : any;

  loading : boolean = true;

  noEmail : boolean = false;

  userGroup : string = 'undefined';

  preparedToLogOut : boolean = false;

  interventionState : number = 2; //0 : 3 sessions completed; 1 : less than 3 completed, but the latest was < 7 days ago; 2 : ready for follow-up session

  initSession : any = {
    surveySlug : 'session-1-79074',
    reportSlug : 'reportsession_11626200144509',
    title : 'Baseline Session'
  }

  followUpSession : any = {
    surveySlug : 'returning-session-16813',
    reportSlug : 'reportreturning_session1638876319713',
    title : 'Follow Up'
  }

  session : any;

  API_URL = API_URL;

  constructor(
    private alertController: AlertController,
    private ohnApi: OhnApiService,
    private auth: AuthenticationService,
    public modalController: ModalController,
    public popoverController: PopoverController
  ) { }

  ngOnInit() {
    this.loadStaff();
    this.clean();
  }

  updateStaff() {
    localStorage.setItem('ohn-current-staff-name', this.currentStaff.text);
  }

  loadStaff() {
    this.loading = true;
   
    this.ohnApi.getElement('radonc_staff_container', 3).subscribe(staff=>{
      this.staff = staff.elements;
      this.staff.sort((a,b)=>
        a.text.localeCompare(b.text)
      );
      this.pin = staff.config.pin;
      if (localStorage.getItem('ohn-current-staff-name')) {
        this.currentStaff = this.staff.find((s)=>{
          return s.text == localStorage.getItem('ohn-current-staff-name')
        })
      }
      this.loading = false;
    });

  }


  async lockScreen() {
    const alert = await this.alertController.create({
      header: 'PIN',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'OK',
          role: 'confirm',
          handler: (inputs) => {
            if (inputs[0] == this.pin) {
              switch (this.currentScreenState) {
                case 'start' :
                  if (this.preparedToLogOut) {
                    this.auth.logout();
                  } else {
                    this.currentScreenState = 'patientLogin'
                  }
                  break;
                case 'patientLogin' :
                  this.currentScreenState = 'start';
                  this.clean();
                  break;
                case 'patientThankYou' :
                  this.currentScreenState = 'start';
                  this.clean();
                  break;
                case 'patientHome' :
                  this.currentScreenState = 'start';
                  this.clean();
                  break;
              }
            } else {
              this.showAlert('Error', 'Wrong PIN code. Please try again.')
            }
          }
        },
      ],
      backdropDismiss : false,
      inputs: [
        {
          type: 'number',
          placeholder: 'Please enter the PIN code',
          attributes: {
            inputmode : 'numeric'
          }
        }
      ],
    });

    await alert.present();
  }

  async showAlert(header: string, message : string) {
    const alert = await this.alertController.create({
      header: header,
      message: message,
      buttons: ['OK'],
    });

    await alert.present();
  }

  startSurvey() {
    this.loading = true;
    this.session = (this.currentPatient.histories && (this.currentPatient.histories.html_printout_intervention || this.currentPatient.histories.html_printout_followup)) ? this.followUpSession : this.initSession;
    this.scheduleEvent(this.generateEvent());
  }

  clean() {
    this.patientEmail = '';
    this.confirmEmail = '';
    this.userGroup = 'undefined';
    this.noEmail = false;
    this.interventionState = 2;
    this.preparedToLogOut = false;
    localStorage.removeItem('ohn-radonc-printout-recently-generated');
    localStorage.removeItem('ohn-radonc-group');
  }

  checkUser() {

    let re = this.noEmail ? /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im : /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (re.test(this.patientEmail)) {
      if (this.confirmEmail == this.patientEmail) {
        this.loading = true;
        this.ohnApi.getUserByEmail(this.patientEmail).subscribe(user=>{
          this.currentPatient = <OHNUser>user;
          if (this.currentPatient.elements['intervention-control-randomizati-15683'] === 1 && this.currentPatient.histories['html_printout_intervention'] && this.currentPatient.histories['html_printout_intervention'].length > 0) {
            this.userGroup = 'intervention';
            this.getInterventionState();
          }

          if (this.currentPatient.elements['intervention-control-randomizati-15683'] === 0 && this.currentPatient.histories['html_printout_intervention'] && this.currentPatient.histories['html_printout_intervention'].length > 0) {
            this.userGroup = 'control';
          }

          if (this.currentPatient.elements['intervention-control-randomizati-15683'] === undefined && this.currentPatient.histories['html_printout_intervention'] && this.currentPatient.histories['html_printout_intervention'].length > 0) {
            this.userGroup = 'notAvailable';
          }
          
          this.currentScreenState = 'patientHome';
          this.loading = false;
        }, error=>{
          if (error.status == 400) {
            let newUser = this.noEmail ? <OHNUser>{phone : this.patientEmail, roles : ['patientspherex_patient']} : <OHNUser>{email : this.patientEmail, roles : ['patientspherex_patient']};
            this.ohnApi.addNewUser(newUser).subscribe(user=>{
              this.currentPatient = <OHNUser>user;
              this.currentScreenState = 'patientHome';
              this.loading = false;
            })
          }
        })
      } else {
        let erText : string = this.noEmail ? 'Phone # do not match.' : 'Emails do not match.';
        this.showAlert('Error', erText)
      }
    } else {
      let erText : string = this.noEmail ? 'Wrong phone # format. Please correct.' : 'Wrong email format. Please correct.';
      this.showAlert('Error', erText);
    }
  }

  getInterventionState() {
    let daysDifference : number = 0;
    let lastRportDate : Date;

    if (this.currentPatient.histories && this.currentPatient.histories != null && this.currentPatient.histories['html_printout_followup'] && this.currentPatient.histories['html_printout_followup'].length > 0) {
      lastRportDate = new Date(this.currentPatient.histories['html_printout_followup'][this.currentPatient.histories['html_printout_followup'].length - 1].timestamp);
    } else if (this.currentPatient.histories && this.currentPatient.histories != null && this.currentPatient.histories['html_printout_intervention'] && this.currentPatient.histories['html_printout_intervention'].length > 0) {
      lastRportDate = new Date(this.currentPatient.histories['html_printout_intervention'][this.currentPatient.histories['html_printout_intervention'].length - 1].timestamp);
    }

    if (lastRportDate) {
      daysDifference = (new Date().getTime() - lastRportDate.getTime()) / (1000 * 3600 * 24);
    }

    if (this.currentPatient.histories && this.currentPatient.histories != null && this.currentPatient.histories['html_printout_followup'] && this.currentPatient.histories['html_printout_followup'].length >= 2) {
      this.interventionState = 0;
    }
    if (this.currentPatient.histories && this.currentPatient.histories != null 
      && (
        (this.currentPatient.histories['html_printout_followup'] && this.currentPatient.histories['html_printout_followup'].length < 2)
        || ((!this.currentPatient.histories['html_printout_followup'] || this.currentPatient.histories['html_printout_followup'].length == 0) && this.currentPatient.histories['html_printout_intervention'] && this.currentPatient.histories['html_printout_intervention'].length > 0))
      && daysDifference < 7) {
      this.interventionState = 1;
    }

    //this.interventionState = 2;
  }

  generateEvent(){
    let event = new OHNCalendarEvent({
      title : this.session.title,
      startTime : new Date(),
      completed : false,
      allDay: true,
      controller : 'survey',
      inner_element_slug : this.session.surveySlug,
      inner_element_state_id : '',
      report_element_slug : this.session.reportSlug,
      report_element_state_id : '',
      content : ''
    });
    return event;
  }

  scheduleEvent(event: any) {
    this.ohnApi.setElementStateSc('calendar_container', {value: event}, this.currentPatient.smart_contract).subscribe(newEvent => {
      this.openEventModal(new OHNCalendarEvent(newEvent));
      this.loading = false;
    });
  }

  async openEventModal(event: any) {
    /*const calendarEventPage = await this.modalController.create({
      component: CalendarEventViewPage,
      componentProps: {
        'calendarElement' : {element_slug:'calendar_container'},
        'user' : this.currentPatient,
        'event' : event,
        'skipResultScreen' : true
      },
      cssClass: 'modal-fullscreen',
      backdropDismiss: false
    });*/
    const contentPage = await this.modalController.create({
      component: BranchedContentRendererComponent,
      componentProps: {
        parentElementSlug : event.inner_element_slug,
        user : this.currentPatient,
        event : event,
        hideCloseButton :true,
        passedElementValues : [
          {
            "element_slug" : 'clinic-staff-name-75162',
            value : this.currentStaff.text
          }
        ]
      },
      cssClass: 'modal-fullscreen',
      backdropDismiss: false
    });

    contentPage.onDidDismiss().then((data) => {
      if (data.data.finished) {
        this.loadThankYouPage()
      }
    })

    return await contentPage.present();
  }

  loadThankYouPage() {
    this.loading = true;
    this.ohnApi.getUserByEmail(this.patientEmail).subscribe(user=>{
      this.currentPatient = <OHNUser>user;
      this.currentScreenState = 'patientThankYou';
      this.loading = false;
    })
  }

  async logoutNotification() {
    const alert = await this.alertController.create({
      header: 'Warning',
      message: 'This action will log you out completely from the app. Only admin or user with the "practitioner" role will be able to log in after that. Are you sure you want to proceed?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Yes',
          role: 'confirm',
          handler: () => {
            this.preparedToLogOut = true;
           this.lockScreen();
          }
        },
      ],
      backdropDismiss : false,
    });

    await alert.present();
  }

  async openPrint(ev: any) {

    let pdfList = [];

    if (this.currentPatient.histories.html_printout_intervention) {

      let printout = {
        category: "Baseline session",
        links: []
      }

      this.currentPatient.histories.html_printout_intervention.forEach( pdf => {
        let linkItem = {
          link: API_URL + '/reports/' + pdf.value[0],
          text: formatDate(new Date(pdf.timestamp + 'Z'), 'MM/dd/yyyy h:mm a', 'en')
        }
        printout['links'].push(linkItem);
      });

      pdfList.push(printout);
    }

    if (this.currentPatient.histories.html_printout_followup) {

      let printout = {
        category: "Follow Up session",
        links: []
      }

      this.currentPatient.histories.html_printout_followup.forEach( pdf => {
        let linkItem = {
          link: API_URL + '/reports/' + pdf.value[0],
          text: formatDate(new Date(pdf.timestamp + 'Z'), 'MM/dd/yyyy h:mm a', 'en')
        }
        printout['links'].push(linkItem);
      });

      pdfList.push(printout);

    }

    const popover = await this.popoverController.create({
      component: PopoverComponent,
      cssClass: 'ohn-widget-print-popover',
      event: ev,
      animated: true,
      componentProps: {
        dataType: 'print',
        inputData: pdfList
      },
      translucent: true
    });
    await popover.present();
  }

}
