import { Component, OnInit, Input } from '@angular/core';
import { ModalController, NavParams, PopoverController } from '@ionic/angular';
import { OhnApiService } from '../../../services/ohn-api.service';
import { OHNElement, OHNUser } from '../../../models/ohn-instances';
import * as _ from 'underscore/underscore';

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
})

export class PopoverComponent implements OnInit {

	@Input() inputData: any;
	@Input() dataType: string;
	@Input() user: OHNUser;

	newNote : any = {};

	constructor(
		public navParams: NavParams, 
		public popoverController: PopoverController,
		private ohnApi: OhnApiService
	) {
		this.inputData = navParams.get('inputData');
		this.dataType = navParams.get('dataType');
	}

	async selectItem(item: any) {
		await this.popoverController.dismiss(item);
	}

	ngOnInit() {

	}

	saveNote() {
		if (!this.inputData.state.notes) {
			this.inputData.state.notes = [];
		}
		let note : any = _.clone(this.newNote);
		if (note.text && note.text != '') {
			this.inputData.state.notes.push(note);
			this.ohnApi.setElementStateSc(this.inputData.element_slug, {value: {alt_value : this.inputData.state}}, this.user.smart_contract).subscribe(event => {

      });
		}
	}

	async closePopover() {
		await this.popoverController.dismiss();
	}

}
