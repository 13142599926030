import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { OHNElement } from '../../../models/ohn-instances';
import { OhnApiService } from '../../../services/ohn-api.service';
import * as _ from 'underscore/underscore';
import { Subject} from 'rxjs';

import { HealthTrackingEntryPage } from '../../../secured/health-tracking-entry/health-tracking-entry.page';
import { HealthTrackingPage } from '../../../secured/health-tracking/health-tracking.page';

@Component({
  selector: 'app-health-tracking-widget',
  templateUrl: './health-tracking-widget.component.html',
  styleUrls: ['./health-tracking-widget.component.scss'],
})
export class HealthTrackingWidgetComponent extends HealthTrackingPage {

	@Input() el: OHNElement;

	parentElement : OHNElement;

	loading: boolean = false;
	@Input() refresher : Subject<boolean>;

  ngOnInit() {
  	this.loadParentElement(this.el.config.tracking_slug);
  	this.refresher.subscribe(refreshing => {
    	this.loadParentElement(this.el.config.tracking_slug);
    });
  }

}
