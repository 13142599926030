import { Component, OnInit, Input } from '@angular/core';
import { OHNElement } from '../../../models/ohn-instances';
import { OhnLanguageService } from '../../../services/ohn-language.service';

@Component({
  selector: 'app-image-element-config',
  templateUrl: './image-element-config.component.html',
  styleUrls: ['./image-element-config.component.scss'],
})
export class ImageElementConfigComponent implements OnInit {
	
	@Input() el: OHNElement;

  constructor(
    private lS: OhnLanguageService,
  ) { }

  ngOnInit() {
  	this.el.config = this.el.config || {};
  }

}
