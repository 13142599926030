import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { OHNCalendarEvent, OHNElement, OHNUser } from '../../models/ohn-instances';
import { OhnApiService } from '../../services/ohn-api.service';
import { BranchedContentRendererComponent } from '../../components/branched-content-renderer/branched-content-renderer.component';
import * as _ from 'underscore/underscore';

import { DEBUG_MODE } from '../../../environments/environment';

@Component({
  selector: 'app-calendar-event-view',
  templateUrl: './calendar-event-view.page.html',
  styleUrls: ['./calendar-event-view.page.scss'],
})
export class CalendarEventViewPage implements OnInit {

	@Input() event : any;
	@Input() calendarElement : OHNElement;
  @Input() user : OHNUser;
  @Input() skipResultScreen : boolean;
  @Input() reportViewMode : boolean;

  reportElement : OHNElement;
  parentElement : OHNElement;

  reportElementN : OHNElement;

  reportState : any;
  chartUpdater : Subject<any> = new Subject();

  keys : any[];

	loading: boolean = false;

  score : number;

  modalOpened : boolean = false;

  eventStats: any = {};

  constructor( 
		public modalController: ModalController,
		private ohnApi: OhnApiService
	){
	}

  ngOnInit() {
  	switch (this.event.controller) {
      case "survey":
        if (this.event.completed || this.reportViewMode) {
          this.loadReportElement();
        } else {
          this.startSurvey();
        }
        break;

      case "workout":
        if (this.event.completed || this.reportViewMode) {
          this.loadReportElement();
        }
        break;

      case "hkdatapoint":
        if (this.event.completed || this.reportViewMode) {
          this.loadReportElementN();
        }
        break;

      case "dataframe":
        if (this.event.completed || this.reportViewMode) {
          this.loadElementState();
        }
        break;

      default:
        // code...
        break;
    }
  }

  loadElementState() {
    DEBUG_MODE && console.log('Calendar Event', this.event)
    this.loading = true;
    this.ohnApi.getElementStateById(this.event.inner_element_slug, this.user.smart_contract, this.event.inner_element_state_id).subscribe((elementState)=>{
      DEBUG_MODE && console.log('Element State', elementState);
      this.reportState = elementState;
      this.keys = _.keys(this.reportState.value.value[0]);
      if (this.keys[0] == 'time') {
        this.reportState.value.value = _.sortBy(this.reportState.value.value, (v)=>{
          return new Date(v.time);
        });
      }
      this.chartUpdater.next(this.reportState);

    });
    this.loading = false;
  }

  loadReportElementN() {
    this.loading = true;
    this.ohnApi.getElement(this.event.report_element_slug, 3).subscribe((element)=>{
      this.reportElementN = <OHNElement>element;
      this.loading = false;
    });
  }

  loadReportElement() {
    this.loading = true;
    this.ohnApi.getElement(this.event.report_element_slug, 3).subscribe((element)=>{
      this.reportElement = <OHNElement>element;
      this.loadReportState();
    });
  }

  loadReportState() {
    this.ohnApi.getElementStateById(this.event.report_element_slug, this.user.smart_contract, this.event.report_element_state_id).subscribe((reportState)=>{
      this.reportState = reportState;
      DEBUG_MODE && console.log('Report State', this.reportState);
      if (reportState.value.value.value){
        const reportSlugKeys = _.keys(reportState.value.value.value.element_slug);
        let reportElementSlugs : string[] = [];
        reportSlugKeys.forEach((k)=>{
          reportElementSlugs.push(reportState.value.value.value.element_slug[k]);
        });
        this.reportElement.elements = this.reportElement.elements.filter((e)=>{
          return reportElementSlugs.indexOf(e.element_slug) >= 0;
        })
      }
      this.parseReport();
    });
  }

  parseReport() {

    this.eventStats = this.event.contentObject.elementPrescriptions;

    let reportKeys : string[] = _.values(this.reportState.value.value.value.element_slug);
    this.reportElement.elements.forEach((e)=>{
      e.value = this.reportState.value.value.value.alt_value[reportKeys.indexOf(e.element_slug)];
      e.numericVal = this.reportState.value.value.value.value[reportKeys.indexOf(e.element_slug)]

      if (e.controller == "fitbitHeartRateElementController") {
        let hrValues = _.pluck(e.value, 'hr');
        DEBUG_MODE && console.log("hrValues", hrValues);
        e.config['hr_target'] = this.event.contentObject.elementPrescriptions[e.element_slug].targetHeartRate;
        //let hr_sum = hrValues.reduce((a, b) => a + b, 0);
        //e.config['hr_mean'] = (hr_sum / hrValues.length).toFixed(2) || 0;
        //e.config['hr_min'] = Math.min.apply(null, hrValues);
        //e.config['hr_max'] = Math.max.apply(null, hrValues);
        //e.config['hr_std'] = Math.sqrt(arr.variance(hrValues)).toFixed(2);

        e.config['hr_mean'] = this.calcMean(hrValues);
        e.config['hr_min'] = this.calcMin(hrValues);
        e.config['hr_max'] = this.calcMax(hrValues);
        e.config['hr_std'] = this.calcStD(hrValues);
        e.config['hr_tiz'] = this.calcTIZ(e.value, e.config['hr_target']);

        DEBUG_MODE && console.log('parseReport element', e);
      } 
    });
    if (this.reportState.value.value.stats) {
      this.score = this.reportState.value.value.stats.sum;
    }
    DEBUG_MODE && console.log('Parsed Report', this.score);
    this.loading = false;
  }

  complete() {
    this.loading = true;
    switch (this.event.controller) {
      case "survey":
        this.saveForReport();
        break;

      case "workout":
        this.saveForReport();
        break;

      default:
        this.saveCompletion();
        break;
    }
  }

  async startSurvey() {
    this.modalOpened = true;
    const contentPage = await this.modalController.create({
      component: BranchedContentRendererComponent,
      componentProps: {
        parentElementSlug : this.event.inner_element_slug,
        user : this.user,
        event : this.event,
        hideCloseButton: true
      },
      cssClass: 'modal-fullscreen',
      backdropDismiss: false
    });

    contentPage.onDidDismiss().then((data) => {
      if (this.event.completed && !this.skipResultScreen && (this.event.controller == 'survey') ||  (this.event.controller == 'workout')) {
        this.loadReportElement();
      } else if (this.event.controller == 'survey') {
        this.closeModal();
      }
      this.modalOpened = false;
    })

    return await contentPage.present();
  }

  async showTutorial() {
    this.modalOpened = true;
    const contentPage = await this.modalController.create({
      component: BranchedContentRendererComponent,
      componentProps: {
        parentElementSlug : 'chop_fitbit_tutorial',
        user : this.user,
        event : this.event
      },
      cssClass: 'modal-fullscreen',
      backdropDismiss: false
    });

    contentPage.onDidDismiss().then((data) => {
        this.closeModal();
      
      this.modalOpened = false;
    })

    return await contentPage.present();
  }

  saveForReport() {
    if (this.event.report_element_slug && this.event.report_element_slug != "") {
      this.ohnApi.setElementStateSc(this.event.report_element_slug, {value: {}}, this.user.smart_contract).subscribe(reportState => {
        this.event.report_element_state_id = reportState.id;
        this.saveCompletion();
      });
    } else {
      this.saveCompletion();
    }
  }

  saveCompletion() {
    this.event.completed = true;
    let patchObject: any = _.pick(this.event, 'id', 'completed', 'report_element_state_id', 'startTime', 'nylas_event_id', 'nylas_calendar_id');
    patchObject['completedTime'] = new Date();
  	this.ohnApi.patchElementStateSc(this.calendarElement.element_slug, {value: patchObject}, this.user.smart_contract).subscribe(state => {
  		this.loading = false;
      this.closeModal();
    });
  }

  closeModal() {
		this.modalController.dismiss({
			dismissed: true
		}).then(()=>{
      if (this.event.controller == 'survey' && !this.event.completed) {
        this.closeModal();
      }
    },()=>{});
	}

  //-------- CALC STATS --------//

  calcMin(arr: number[]) {
    return Math.min.apply(null, arr);
  }

  calcMax(arr: number[]) {
    return Math.max.apply(null, arr);
  }

  calcMean(arr: number[]) {
    return (this.calcSum(arr) / arr.length).toFixed(2) || 0;
  }

  calcSum(arr: number[]) {
    return arr.reduce((a, b) => a + b, 0);
  }

  calcVariance(arr: number[]) {
    let mean = Number(this.calcMean(arr));
    let pows = arr.map(function(num) {
      return Math.pow(num - mean, 2);
    })
    return this.calcMean(pows);
  }

  calcStD(arr: number[]) {
    return Math.sqrt(Number(this.calcVariance(arr))).toFixed(2);
  }

  calcTIZ(timeseries: any[], tHR: number, tolerance: number = 0.1) {
    var tiz = 0;
    var last_tiz = 0;
    var min = tHR - (tHR * tolerance); 
    var max = tHR + (tHR * tolerance);

    for (var i = 0; i < timeseries.length; i++) {
      if ((timeseries[i].hr >= min) && (timeseries[i].hr <= max)) {
        if(last_tiz==0) {
          last_tiz=timeseries[i].time;
        } else {
          tiz += timeseries[i].time-last_tiz;
          last_tiz=timeseries[i].time;
        }
      } else {
        last_tiz=0;
      }
    }
      return tiz.toFixed(2);
  }


}
