import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-current-timestamp-picker',
  templateUrl: './current-timestamp-picker.component.html',
  styleUrls: ['./current-timestamp-picker.component.scss'],
})
export class CurrentTimestampPickerComponent implements OnInit {

  @Input() el: any;

  constructor() { }

  ngOnInit() {
    this.el.value = new Date().toString();
    this.el.valid = true;
  }

}
