import { Component, OnInit, Input } from '@angular/core';
import { OHNElement } from '../../../models/ohn-instances';
import { OhnLanguageService } from '../../../services/ohn-language.service';

@Component({
  selector: 'app-content-raw-element-config',
  templateUrl: './content-raw-element-config.component.html',
  styleUrls: ['./content-raw-element-config.component.scss'],
})
export class ContentRawElementConfigComponent implements OnInit {
	
	@Input() el: OHNElement;

  constructor(
    private lS: OhnLanguageService,
  ) { }

  ngOnInit() {
  	this.el.config = this.el.config || {};
  }

}
