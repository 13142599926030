import { Component, OnInit, Input } from '@angular/core';
import { OHNElement } from '../../../models/ohn-instances';

@Component({
  selector: 'app-pick-many-element-config',
  templateUrl: './pick-many-element-config.component.html',
  styleUrls: ['./pick-many-element-config.component.scss'],
})
export class PickManyElementConfigComponent implements OnInit {

	@Input() el: OHNElement;

  constructor() { }

  ngOnInit() {}

  addOption() {
    this.el.elements.push(
      <OHNElement>{
        text : "",
        _cls : "ContentElement",
        controller : "contentElementController",
        settings : {},
        config : {},
        elements : [],
        numeric_value : this.el.elements.length
      }
    );
  }

  deleteOption(elementIndex : number) {
    this.el.elements.splice(elementIndex, 1);
  }

}
