import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { OhnApiService } from '../../services/ohn-api.service';
import { OHNElement, OHNUser } from '../../models/ohn-instances';
import { OhnLanguageService } from '../../services/ohn-language.service';
import * as _ from 'underscore/underscore';

@Component({
  selector: 'app-notes-history',
  templateUrl: './notes-history.component.html',
  styleUrls: ['./notes-history.component.scss'],
})
export class NotesHistoryComponent implements OnInit {

	@Input() el: OHNElement;
	@Input() user : OHNUser;

	notes : any[] = [];

	loading: boolean = true;

  constructor(
  	private ohnApi: OhnApiService,
  	public modalController: ModalController,
    private lS: OhnLanguageService,
  ) { }

  ngOnInit() {
  	this.loadHistory();
  }

  loadHistory() {
  	this.ohnApi.getElementHistoryScCostil(this.el.element_slug, 'all', 0, this.user.smart_contract).subscribe((history)=>{
  		if (history) {
  			let keys : string[] = _.keys(history);
  			keys.forEach((k)=>{
  				this.notes.push({date: new Date(k), value : history[k].value});
  			});
  		}
  		this.loading = false;
  	});
  }

  closeModal() {
		this.modalController.dismiss({
      dismissed: true
    });
  }
}
