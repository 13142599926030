import { Component, OnInit, Input } from '@angular/core';
import { OhnApiService } from '../../services/ohn-api.service';
import { OHNElement, OHNUser, OHNUserRole } from '../../models/ohn-instances';
import * as _ from 'underscore/underscore';

@Component({
  selector: 'app-profile-fields-view',
  templateUrl: './profile-fields-view.component.html',
  styleUrls: ['./profile-fields-view.component.scss'],
})
export class ProfileFieldsViewComponent implements OnInit {

	@Input() role: string;

	@Input() profile : OHNUserRole;

	profileValues : any;

	loading : boolean = false;

  constructor(
  	private ohnApi: OhnApiService
  ) { }

  ngOnInit() {
  	//this.getProfile();
  }

  /*getProfile() {
  	this.ohnApi.getElement(this.role + '_profile', 3).subscribe(prof => {
      this.profile = prof;
      this.loading = false;
    });
  }*/

  collectProfileValues() {
  	this.profileValues =  _.object(_.map(this.profile.roleProfileElements, (e) => { return [e.element_slug, e.value] }));
  }

  saveProfile() {
  	this.collectProfileValues();
  	return this.profileValues;
  }

}
