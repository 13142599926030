import { Component, OnInit, Input } from '@angular/core';
import { OHNElement } from '../../../models/ohn-instances';
import { OhnLanguageService } from '../../../services/ohn-language.service';

@Component({
  selector: 'app-link-element-config',
  templateUrl: './link-element-config.component.html',
  styleUrls: ['./link-element-config.component.scss'],
})
export class LinkElementConfigComponent implements OnInit {

	@Input() el: OHNElement;

  constructor(
    private lS: OhnLanguageService,
  ) { }

  ngOnInit() {}

}
