import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { OhnApiService } from '../../../services/ohn-api.service';
import { OHNElement, OHNUser } from '../../../models/ohn-instances';
import { OhnLanguageService } from '../../../services/ohn-language.service';
import { API_URL } from '../../../../environments/environment';
import { OhnLogicService } from '../../../services/ohn-logic.service';
import { HtmlTemplateRendererComponent } from '../../html-template-renderer/html-template-renderer.component';

import * as _ from 'underscore/underscore';

@Component({
  selector: 'app-printout-report',
  templateUrl: './printout-report.component.html',
  styleUrls: ['./printout-report.component.scss'],
})
export class PrintoutReportComponent implements OnInit {

  @Input() el: OHNElement;
  @Input() user : OHNUser;
  @Input() allElements: OHNElement[];

  loading: boolean = true;
  downloadUrl : string = "";

  constructor(
    private ohnApi: OhnApiService,
    private lS: OhnLanguageService,
    public modalController: ModalController,
    private ohnLogicService: OhnLogicService,
  ) { }

  ngOnInit() {
    //this.getState();
    this.el.valid = true;
    if (!localStorage.getItem('ohn-radonc-printout-recently-generated')) {
      this.openPrintout();
      localStorage.setItem('ohn-radonc-printout-recently-generated', 'true')
    }
  }

  /*getState() {
    this.ohnApi.getElementStateSc(this.el.element_slug, this.user.smart_contract).subscribe(state =>{
      this.loading = false;
      console.log(state);
      if (state && state.value) {
        this.downloadUrl = API_URL + '/' + state.value.value
      }
    });
  }*/

  async openPrintout() {

    const modal = await this.modalController.create({
      component: HtmlTemplateRendererComponent,
      componentProps: {
        elements : this.allElements,
        reportElementSlug : this.el.config.reportElementSlug,
        user : this.user,
        el : this.el
      },
      backdropDismiss: true,
      cssClass: 'modal-fullscreen'
    });

    modal.onDidDismiss().then((res) => {

    });

    return await modal.present();
  }

}
