import { Component, OnInit, Input } from '@angular/core';
import { OhnApiService } from '../../services/ohn-api.service';
import { OhnService } from '../../services/ohn.service';
import { ModalController } from '@ionic/angular';
import { OHNUser, OHNUserRole , OHNElement} from '../../models/ohn-instances';
import { combineLatest, Observable, forkJoin} from 'rxjs';

@Component({
  selector: 'app-user-filter-settings',
  templateUrl: './user-filter-settings.component.html',
  styleUrls: ['./user-filter-settings.component.scss'],
})
export class UserFilterSettingsComponent implements OnInit {

  @Input() filtersConfig: any;

  loading: boolean = true;

  allUserRoles : OHNUserRole[];

  filters : any = {
    roleFilter : {}
  };

  constructor(
    public modalController: ModalController,
    private ohnApi: OhnApiService,
    private ohnService: OhnService,
  ) { }

  ngOnInit() {
    this.filtersConfig = {
      availableFilters : [
        {
          title : 'Filter By Role',
          controller : 'roleFilterController'
        }
      ]
    }
    this.loadAssets();
  }

  loadAssets() {
    this.loading = true;
    this.ohnApi.getAvailableRoles().subscribe((roles: OHNUserRole[])=>{
      this.allUserRoles = roles;
      console.log(this.allUserRoles);
      this.loading = false;
    });
  }

  applyFilters() {
    this.modalController.dismiss({
      dismissed: true,
      filters: this.filters,
      filtersConfig : this.filtersConfig
    });
  }
}