import { Component, OnInit, Input } from '@angular/core';
import { OhnApiService } from '../../../services/ohn-api.service';
import { OHNElement, OHNUser, OHNBookmark } from '../../../models/ohn-instances';
import { OhnLanguageService } from '../../../services/ohn-language.service';
import * as _ from 'underscore/underscore';

@Component({
  selector: 'app-external-link-element',
  templateUrl: './external-link-element.component.html',
  styleUrls: ['./external-link-element.component.scss'],
})
export class ExternalLinkElementComponent implements OnInit {

	@Input() el: OHNElement;
	@Input() viewMode : string;
	@Input() user : OHNUser;
	saving: boolean = false;
	bookmarked: boolean = false;
	stateId: string;
	elStateId : string;
	displayingNotesFor : string[] = [];
	hasValidityRule : boolean;

  constructor(
  	private ohnApi: OhnApiService,
  	private lS: OhnLanguageService,
  ) { }

  ngOnInit() {
  	if(!this.el.config.isButton) {
  		this.getState();
  	}
  	this.el.valid = true;
  	if(this.el.config.validityRules) {
  		this.el.valid = false;
  		this.hasValidityRule = true;
  	}
  }

  getState() {
  	this.ohnApi.getElementStateSc(this.el.element_slug, this.user.smart_contract).subscribe(state =>{
  		this.bookmarked = state.value && state.value.value != "";
  		if (this.bookmarked) {
  			this.stateId = state.value.value;
  			this.elStateId = state.value.id;
  		}
  	});
  }

  copyLink() {

  }

  bookmark() {
  	this.saving = true;
  	if (!this.bookmarked) {
	  	this.ohnApi.getElementHistoryScCostil('bookmarks_container', 'all', 0, this.user.smart_contract).subscribe(history => {
	      this.saving = false;
	      this.bookmarked = true;
	      if (history) {
	      	let keys: string[] = _.keys(history);
	      	let alreadySaved: boolean = false;
	      	keys.forEach((k)=>{
	      		if (history[k].value.element_slug == this.el.element_slug) alreadySaved = true;
	      	});
	      	if (!alreadySaved) {
	    			this.saveBookmarkState();
	    		}
	      } else {
	      	this.saveBookmarkState();
	      }
	    });
	  } else {
	  	this.ohnApi.deleteElementStateSc('bookmarks_container', {value : {id : this.stateId}}, this.user.smart_contract).subscribe(state => {
	  		this.saving = false;
	  		this.bookmarked = false;
	  	});
	  	this.ohnApi.deleteElementStateSc(this.el.element_slug, {value : {id : this.elStateId}}, this.user.smart_contract).subscribe(state => {
	    });
	  }
  }

  toggleNote(elementSlug: string) {
  	if (_.contains(this.displayingNotesFor, elementSlug)) {
  		this.displayingNotesFor = this.displayingNotesFor.filter(n=>{ return n != elementSlug });
  	} else {
  		this.displayingNotesFor.push(elementSlug);
  	}
  }

  needToDisplayNote(elementSlug: string) {
  	return _.contains(this.displayingNotesFor, elementSlug);
  }

  saveBookmarkState() {
  	let bookmark : OHNBookmark = <OHNBookmark>{
  		element_slug : this.el.element_slug,
			text : this.el.text,
			tags : this.el.config.tags || []
  	}
  	this.ohnApi.setElementStateSc('bookmarks_container', {value : bookmark}, this.user.smart_contract).subscribe(state => {
  		this.stateId = state.id;
  		this.ohnApi.setElementStateSc(this.el.element_slug, {value : state.id}, this.user.smart_contract).subscribe(state => {
	    });
		});
  }

  linkClicked() {
  	if (this.hasValidityRule) {
  		this.el.valid = true;
  	}
  }
}
