import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { OHNElement, OHNUser } from '../../../models/ohn-instances';
import { OhnApiService } from '../../../services/ohn-api.service';
import { OhnService } from '../../../services/ohn.service';
import * as _ from 'underscore/underscore';
import { Subject} from 'rxjs';

import { BranchedContentRendererComponent } from '../../branched-content-renderer/branched-content-renderer.component';
import { RoadmapComponent } from '../../roadmap/roadmap.component';

@Component({
  selector: 'app-content-opener-widget',
  templateUrl: './content-opener-widget.component.html',
  styleUrls: ['./content-opener-widget.component.scss'],
})
export class ContentOpenerWidgetComponent implements OnInit {

	@Input() el: OHNElement;
  @Input() refresher : Subject<boolean>;
  @Input() me: OHNUser;

	loading: boolean = false;



  constructor(
  	private ohnApi: OhnApiService,
    private ohnService: OhnService,
  	public modalController: ModalController
  ) { }

  ngOnInit() {
  }

  async openModalComponent() {
    const contentPage = await this.modalController.create({
      component: (this.el.config.component === 'RoadmapComponent') && RoadmapComponent || BranchedContentRendererComponent,
      componentProps: {
        parentElementSlug : this.el.config.elementSlug,
        user : this.me
      },
      backdropDismiss: false
    });

    contentPage.onDidDismiss().then((data) => {
   
    })

    return await contentPage.present();
  }
}