import { Component, OnInit, Input } from '@angular/core';
import { OHNElement } from '../../../models/ohn-instances';


@Component({
  selector: 'app-video-element-config',
  templateUrl: './video-element-config.component.html',
  styleUrls: ['./video-element-config.component.scss'],
})
export class VideoElementConfigComponent implements OnInit {

	@Input() el: OHNElement;

  constructor() { }

  ngOnInit() {}

}
