import { Component, OnInit, Input } from '@angular/core';
import { OHNElement } from '../../models/ohn-instances';
import { ModalController} from '@ionic/angular';
import { OhnApiService } from '../../services/ohn-api.service';
import * as _ from 'underscore/underscore';

@Component({
  selector: 'app-health-tracking-entry',
  templateUrl: './health-tracking-entry.page.html',
  styleUrls: ['./health-tracking-entry.page.scss'],
})
export class HealthTrackingEntryPage implements OnInit {

	@Input() parentElement: OHNElement;

	elements: OHNElement[];

  loading: boolean = false;

  constructor(
  	private ohnApi: OhnApiService,
  	public modalController: ModalController
  ) { }

  ngOnInit() {
  	this.elements = _.map(this.parentElement.elements, (e)=>{return _.omit(e, 'value')});
  }

  save(){
  	let parentState: any =  _.object(_.map(_.filter(this.elements, (el)=>{ return el.value}), (e) => { return [e.element_slug, e.value] }));

  	this.ohnApi.setElementState(this.parentElement.element_slug, {value : parentState}).subscribe(state => {
  		console.log(state);
  		this.closeModal(true);
    });
  }

  closeModal(updated: boolean) {
  	if (updated) {
  		this.modalController.dismiss({
	      dismissed: true,
	      elements: this.parentElement.elements
	    });
  	} else {
  		this.modalController.dismiss({
	      dismissed: true
	    });
  	}
  }

}
