import { Component, OnInit, Input } from '@angular/core';
import * as _ from 'underscore/underscore';

@Component({
  selector: 'app-heart-rate-zones-element',
  templateUrl: './heart-rate-zones-element.component.html',
  styleUrls: ['./heart-rate-zones-element.component.scss'],
})
export class HeartRateZonesElementComponent implements OnInit {

	@Input() el: any;
	@Input() viewMode : string;

  constructor() { }

  ngOnInit() {
    this.el.valid = true;
  	_.each(this.el.elements, (e)=>{
  		e.value = (!e.value || _.isEmpty(e.value)) ? {from: undefined, to: undefined} : e.value;
  	})
  }

}
