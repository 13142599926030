import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { OhnApiService } from '../../services/ohn-api.service';
import { OHNElement, OHNUser } from '../../models/ohn-instances';
import * as _ from 'underscore/underscore';
import { DEBUG_MODE } from '../../../environments/environment';
import { OhnLanguageService } from '../../services/ohn-language.service';

@Component({
  selector: 'app-bookmarks',
  templateUrl: './bookmarks.component.html',
  styleUrls: ['./bookmarks.component.scss'],
})
export class BookmarksComponent implements OnInit {

  @Input() el: OHNElement;
	@Input() user : OHNUser;

	bookmarks : any[] = [];

	bookmarkedElements : OHNElement[] = [];

	loading: boolean = true;

  constructor(
  	private ohnApi: OhnApiService,
  	public modalController: ModalController,
    private lS: OhnLanguageService,
  ) { }

  ngOnInit() {
  	this.loadHistory();
  }

  loadHistory() {
  	this.ohnApi.getElementHistoryScCostil('bookmarks_container', 'all', 0, this.user.smart_contract).subscribe((history)=>{
  		DEBUG_MODE && console.log('History: ', history);
  		if (history) {
  			let keys : string[] = _.keys(history);
  			keys.forEach((k)=>{
  				this.bookmarks.push({date: new Date(k), value : history[k].value});
  				this.ohnApi.getElement(history[k].value.element_slug, 1).subscribe(el=>{
  					this.bookmarkedElements.push(el);
  				});
  			});
  		}
  		this.loading = false;
  	});
  }

  closeModal() {
		this.modalController.dismiss({
      dismissed: true
    });
  }

}