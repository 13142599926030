export interface OHNElement {
	element_slug?: string,
	_cls : string,
	text: string,
	settings?: any,
	controller: string,
	value?: any,
	config?: any,
	elements?: OHNElement[],
	prescribed? : number,
	video_url? : string,
	isVariable? : boolean,
	id? : string,
	report_element? : OHNElement,
	actions? : any,
	numericVal? : number,
	valid? : boolean
}

export interface OHNUser {
	username: string,
	name: string,
	email: string,
	phone: string,
	smart_contract: string,
	profiles: any,
  roles: string[],
  app_slug: string,
  onboarded_at? : string | Date,
  order?: number,
  timezone? : number,
  elements? : any,
  projects_authorized? : string[],
  histories?: any
}

export interface OHNExternalDevice {
	device_uuid? : string
  device_nickname? : string
  device_manufacturer : string
  device_model? : string
}

export interface OHNUserRole {
	id? : string,
	name : string,
	title? : string,
	permissions : string[],
	app_slug? : string,
	dependants? : string[],
	roleProfileElements? : OHNElement[],
	flag? : boolean
}

export interface OHNBookmark {
	element_slug : string,
	text : string,
	tags : string[]
} 

export interface OHNEventPreset {
	title : string,
	id? : string,
	description? : string,
	contentObject : any,
	startTime?: string | Date;
	endTime?: string | Date;
	content?: string;
	weekDaysToTake?: boolean[];
	intakeTimes?: any[];
	inner_element_slug?: string;
	image_url?: string;
	text?: string;
}

export interface OHNEventSequence {
	title : string,
	description? : string,
	days : { [key: number]: OHNEventSequenceItem[] },
	numberOfWeeks : number,
	id? : string
}

export interface OHNEventSequenceItem {
	title : string,
	elementId : string,
	type : string
}

export class OHNCalendarEvent {
	id : string;
	title: string;
	startTime: string | Date;
	endTime: string | Date;
	description: string;
	element_slug: string;
	completed: boolean;
	allDay: boolean;
	content?: string;
	rating: number;
	controller: string;
	contentObject?: any;
	inner_element_slug? : string;
  inner_element_state_id? : string;
  report_element_slug? : string;
  report_element_state_id? : string;
  nylas_calendar_id? :string;
  nylas_event_id?: string;
  rrule? : string; 

	constructor(event: any = {
		id : '',
		title : '',
		description : '',
		startTime : new Date(),
		endTime : new Date(),
		element_slug : '',
		completed : false,
		allDay: false,
		rating: 0,
		controller: 'exercise',
		content: '{}',
		inner_element_slug : '',
    inner_element_state_id : '',
    report_element_slug : '',
    report_element_state_id : '',
    nylas_event_id : '',
    nylas_calendar_id : '',
    rrule : ''
	}, useDateOnly : boolean = false) {
      this.id = event.id;
      this.title = event.title;
      this.description = event.description;
      if (useDateOnly) {
      	this.startTime = new Date(event.startTime);
      	this.endTime = new Date(event.endTime);
      } else {
      	this.startTime = new Date(event.startTime);
      	this.endTime = new Date(event.endTime);
      }
      this.element_slug = event.element_slug;
      this.completed = event.completed,
      this.allDay = event.allDay,
      this.content = event.content;
      this.rating = event.rating;
      this.controller = event.controller;
      this.contentObject = this.content && this.content != '{}' && this.content.charAt(0) == "{" ? JSON.parse(this.content) :  <OHNCalendarActionList>{
	      text : "",
	      icon: "",
	      description : [],
	      actions : []
	    };
	    this.inner_element_slug = event.inner_element_slug,
    	this.inner_element_state_id = event.inner_element_state_id,
    	this.report_element_slug = event.report_element_slug,
    	this.report_element_state_id = event.report_element_state_id,
    	this.rrule = event.rrule,
    	this.nylas_calendar_id = event.nylas_calendar_id,
    	this.nylas_event_id = event.nylas_event_id
  }

	normalizeTimes(): void {
		this.startTime = new Date(this.startTime),
		this.endTime = new Date(this.endTime)
	};

	mergeDateAndTimes(date: Date, startTime: Date, endTime: Date): void {
		this.endTime = new Date(date);
  	this.endTime.setHours(endTime.getHours(), endTime.getMinutes());
  	this.startTime = new Date(date);
  	this.startTime.setHours(startTime.getHours(), startTime.getMinutes());
	}
}

export class OHNMedication extends OHNCalendarEvent {

	cron: any;
	post_set_state_trigger: any[];

	constructor(event: any = {
		id : '',
		title : '',
		description : '',
		startTime : new Date(),
		endTime : new Date(),
		element_slug : '',
		completed : false,
		allDay: false,
		rating: 0,
		content: '{}',
		controller: 'medication',
		cron: {},
		post_set_state_trigger : []
	}, useDateOnly: boolean = false, onlyStartTime: boolean = false, singleEvent: boolean = false) {
    super (event, useDateOnly);
   	if (useDateOnly && onlyStartTime) {
			let userTimezoneOffset = new Date(event.startTime).getTimezoneOffset() * 60000;
			this.startTime = new Date((new Date(event.startTime).getTime() + userTimezoneOffset));

    	let eD: number = this.startTime.getTime() + 3600000;
    	this.endTime = new Date(eD);
    }
    this.contentObject = this.content && this.content != '{}' ? JSON.parse(this.content) :  {
      dose : ""
    };
    if (!singleEvent) {
    	this.post_set_state_trigger = [
	    	{
	        action:'setup_periodic_get_state_for_calendar'
	      }
	    ]
	  }
    this.cron = event.cron;
  }

  formatCron(weekDays: boolean[], intakeTimes: any[]): void {

		let weekday: number[] = [];

    weekDays.forEach((d,i)=>{
      if (d) {
      	weekday.push(i)
      }
    });

    this.cron = {
      hour : intakeTimes.map(intake => { return new Date(intake.value).getHours()}),
      weekday : weekday,
      minute : intakeTimes.map(intake => { return new Date(intake.value).getMinutes()})
    }

	};
}

export class OHNMeeting extends OHNMedication {

	cron: any;

	constructor(event: any = {
		id : '',
		title : '',
		description : '',
		startTime : new Date(),
		endTime : new Date(),
		element_slug : '',
		completed : false,
		allDay: false,
		rating: 0,
		content: '{}',
		controller: 'meeting',
		cron: {}
	}, useDateOnly: boolean = false, onlyStartTime: boolean = false, singleEvent: boolean = false) {
    super (event, useDateOnly, onlyStartTime, singleEvent);
  }
	
}

export class OHNCalendarAction {
	text : string;
	description: OHNElement[];
	done : boolean;
	trackingParameters : OHNElement[];
	repeat : number;
	id? : string;
}

export class OHNCalendarActionList {
	id? : string;
	text : string;
	icon: string;
	type: string;
	description : OHNElement[];
	actions : OHNCalendarAction[];

	constructor(id: string) {
		this.id = id;
		this.text = '';
	  this.icon = '';
	  this.type = '';
	  this.description = [];
	  this.actions = [];
  }
}

export class OHNCalendarSequence {
	id? : string;
	title : string;
	description : string;
	sequence : {
		[key: number]: string[]
	}

	constructor(id: string) {
		this.id = id;
		this.title = '';
		this.description = '';
		this.sequence = {};
  }
}

export class OHNLogicNode {
	id : string;
	text : string;
	previousNodeId : string;
	transitions : OHNLogicTransition[];

	constructor(node: any) {
		this.id = node.id;
		this.text = node.text;
		this.transitions = [];
		this.previousNodeId = '';
		node.transitions.forEach(t => {
			this.transitions.push(new OHNLogicTransition(t));
		});
  }
}

export class OHNTriggerAction {
	type : string;
	params : any;
}

export class OHNLogicTransition {
	operator : string;
	type : string;
	operand : any;
	compareToNode : string;
	transitions : OHNLogicTransition[];
	nextNode : string;
	reserveNodeId : string;
	triggerActions? : OHNTriggerAction[];

	constructor(transition: any) {
		this.operator = transition.operator;
		this.type = transition.type;
		this.operand = transition.operand;
		this.compareToNode = transition.compareToNode;
		this.nextNode = transition.nextNode;
		this.transitions = [];
		this.reserveNodeId = transition.reserveNodeId;
		transition.transitions.forEach(t => {
			this.transitions.push(new OHNLogicTransition(t));
		});
		this.triggerActions = [];
		if (transition.triggerActions) {
			transition.triggerActions.forEach((a)=>{
				this.triggerActions.push(<OHNTriggerAction>a);
			});
		}
  }
}

export class OHNLogicTree {
	id?: string;
	text : string;
	nodes : OHNLogicNode[];

	constructor(tree: any) {

		this.text = tree.text;
		this.nodes = [];
		tree.nodes.forEach(n => {
			this.nodes.push(new OHNLogicNode(n));
		});
	}
}