import { Component, OnInit, Input } from '@angular/core';
import { OHNElement, OHNUser } from '../../models/ohn-instances';
import { OhnApiService } from '../../services/ohn-api.service';
import { OhnHealthApiService } from '../../services/ohn-health-api.service';
import { API_URL, DEBUG_MODE } from '../../../environments/environment';

import { OhnLanguageService } from '../../services/ohn-language.service';

export interface Application extends OHNElement {
  menu_elements: [OHNElement],
  home_page: string
}

@Component({
  selector: 'app-fitbit-data-sync',
  templateUrl: './fitbit-data-sync.component.html',
  styleUrls: ['./fitbit-data-sync.component.scss'],
})
export class FitbitDataSyncComponent implements OnInit {

  @Input() el: OHNElement;
  @Input() user: OHNUser;

  app : Application;

  syncURL : string;

  fitbitSynced : boolean = false;

  syncing : boolean = false;

  constructor(
    private ohnApi: OhnApiService,
    private ohnHealthApiService: OhnHealthApiService,
    private lS: OhnLanguageService,
  ) { }

  ngOnInit() {
    this.getSyncURL();
    this.getApp();
  }

  getSyncURL(){
    this.ohnApi.getFitbitSyncURL(this.user.smart_contract).subscribe(url => {
      this.fitbitSynced = url.oauth_ready;
      this.syncURL = url.oauth_url;
    })
  }

  syncHealthKit(){
    this.ohnHealthApiService.initHealthKit(this.app, this.user);
  }

  getApp() {
    this.ohnApi.getApp().subscribe(application => {
      this.app = application;
    });
  }

  syncFitbitData(){
    this.syncing = true;
    this.ohnApi.syncFitbitData().subscribe(data => {
      this.syncing = false;
    });
  }

}
