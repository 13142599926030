import { Component, OnInit, Input } from '@angular/core';
import { ToastController, ModalController } from '@ionic/angular';
import { OHNElement, OHNCalendarEvent, OHNMedication, OHNUser } from '../../../models/ohn-instances';
import { NewMedicationComponent } from '../../new-medication/new-medication.component';
import { OhnApiService } from '../../../services/ohn-api.service';
import { OhnService } from '../../../services/ohn.service';
import * as _ from 'underscore/underscore';
import { Subject} from 'rxjs';


@Component({
  selector: 'app-medications-widget',
  templateUrl: './medications-widget.component.html',
  styleUrls: ['./medications-widget.component.scss'],
})
export class MedicationsWidgetComponent implements OnInit {

	@Input() el: OHNElement;
  @Input() refresher : Subject<boolean>;
  @Input() me: OHNUser;

  intakes: OHNMedication[];

	calendarElement: OHNElement;

  

	loading: boolean = false;

  constructor(
  	private ohnApi: OhnApiService,
    private ohnService: OhnService,
    private toastController : ToastController,
    private modalController : ModalController,
  ) { }

  ngOnInit() {
  	this.loadCalendarHistory();
  	this.loadCalendarElement();
  	this.ohnService.widgetRefresher.subscribe(refreshing => {
      this.loadCalendarHistory();
      this.loadCalendarElement();
    });
    this.refresher.subscribe(refreshing => {
      this.loadCalendarHistory();
      this.loadCalendarElement();
    });
  }

  async openMedModal(isNew: boolean, med: OHNMedication) {
    const editMedPage = await this.modalController.create({
      component: NewMedicationComponent,
      componentProps: {
        'calendarElement' : this.calendarElement,
        'user' : this.me,
        'isNew' : isNew,
        'editMed' : med
      },
      backdropDismiss: false
    });

    editMedPage.onDidDismiss().then((data) => {
      this.ohnService.widgetRefresher.next();
    })

    return await editMedPage.present();
  }

  loadCalendarHistory() {
  	this.loading = true;
  	this.ohnApi.getElementHistory(this.el.config.medication_slug, 'day', 0).subscribe(history => {
      let intakes: any[] = (history && _.isArray(history)) ? _.filter(history, (h)=>{return h.controller == 'medication'}) : [];
      this.intakes = _.map(intakes, (s)=>{return new OHNMedication(s, true, true)});
      this.loading = false;
    });
  }

  loadCalendarElement() {
  	this.ohnApi.getElement(this.el.config.medication_slug, 3).subscribe(calendar => {
      this.calendarElement = calendar;
    });
  }

  takeMedicine(event: any, intake : OHNMedication) {
   // this.intakes = _.filter(this.intakes, (i)=>{return i.id != intake.id});
    if (!intake.completed && event.detail.checked) {
      intake.completed = true;
      this.notifyOfIntake(intake);
      this.ohnApi.patchElementStateSc(this.calendarElement.element_slug, {value: {id : intake.id, completed: true}}, this.me.smart_contract).subscribe(state => {
      });
    } else {
      intake.completed = false;
      this.ohnApi.patchElementStateSc(this.calendarElement.element_slug, {value: {id : intake.id, completed: false}}, this.me.smart_contract).subscribe(state => {
      });
    }
  }

  async notifyOfIntake(prescription: OHNMedication) {
    const toast = await this.toastController.create({
      message: '<strong>'+ prescription.title +' : ' + prescription.contentObject.dose +'</strong> has been taken.',
      duration: 2000
    });
    toast.present();
  }

  /*setRate(rating: number, event: OHNCalendarEvent) {
    event.rating = rating;
    this.ohnApi.patchElementState(this.el.config.medication_slug, {value: {id : event.id, rating: rating}}).subscribe(state => {
      //console.log(state);
    });
    //console.log(event);
  }*/

}
