import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { OHNUser, OHNCalendarEvent } from '../models/ohn-instances';
import { AssessmentRendererComponent } from '../components/assessment-renderer/assessment-renderer.component';
import { BranchedContentRendererComponent } from '../components/branched-content-renderer/branched-content-renderer.component';
import { OhnApiService } from '../services/ohn-api.service';

import { SharedModule } from '../app.shared.module';

@Injectable({
  providedIn: 'root'
})

export class ActionsProcessorService {

  constructor(
  	private modalController: ModalController,
    private ohnApi : OhnApiService
  ) { }

  processActions(actions: any[], extras: any, user: OHNUser){
  	actions.forEach((a)=>{
  		switch (a.actionName) {
  			case "openInputModal":
  				this.openInputModal(a.params.modalPageSlug, user);
  				break;
        case "openContentPage":
          this.openContentPage(a.params.pageSlug, user);
          break;
        case "saveStateForElement":
          this.saveStateForElement(a.params.elementSlug, extras, user);
          break;
  			
  			default:
  				// code...
  				break;
  		}
  	});
  }

  saveStateForElement(elementSlug: string, value: any, user: OHNUser) {
    this.ohnApi.setElementStateSc(elementSlug, {value : value}, user.smart_contract).subscribe(state => {
    });
  }


  /*async openInputModal(elementSlug: string, user: OHNUser) {
    const newSequnceManagerPage = await this.modalController.create({
     component: BranchedContentRendererComponent,
      componentProps: {
        parentElementSlug : 'assessment-after-completion_47437',
        user : user
      },
      backdropDismiss: false
    });

    newSequnceManagerPage.onDidDismiss().then((data) => {
     // if (data.data.newEvent) this.eventSource.push(data.data.newEvent);
      //this.eventCalendar.loadEvents();
    })

    return await newSequnceManagerPage.present();
  }*/

  async openContentPage(pageSlug: string, user: OHNUser) {
    const newSequnceManagerPage = await this.modalController.create({
     component: BranchedContentRendererComponent,
      componentProps: {
        parentElementSlug : pageSlug,
        user : user
      },
      backdropDismiss: false
    });

    newSequnceManagerPage.onDidDismiss().then((data) => {
     // if (data.data.newEvent) this.eventSource.push(data.data.newEvent);
      //this.eventCalendar.loadEvents();
    })

    return await newSequnceManagerPage.present();
  }

  async openInputModal(elementSlug: string, user: OHNUser) {
    const newSequnceManagerPage = await this.modalController.create({
      component: AssessmentRendererComponent,
      componentProps: {
        parentSlug : 'assessment-after-completion_47437',
        user: user
      },
      backdropDismiss: false,
      //cssClass: 'modal-fullscreen'
    });

    newSequnceManagerPage.onDidDismiss().then((data) => {
     // if (data.data.newEvent) this.eventSource.push(data.data.newEvent);
      //this.eventCalendar.loadEvents();
    })

    return await newSequnceManagerPage.present();
  }
}
