import { Component, OnInit, Input } from '@angular/core';
import { OhnApiService } from '../../services/ohn-api.service';
import { OhnService } from '../../services/ohn.service';
import { ActionsProcessorService } from '../../services/actions-processor.service';
import { OhnLogicService } from '../../services/ohn-logic.service';
import { OHNElement, OHNLogicNode, OHNUser, OHNCalendarEvent, OHNMedication, OHNMeeting } from '../../models/ohn-instances';
import { ModalController, AlertController } from '@ionic/angular';
import { Subject} from 'rxjs';
import { OhnLanguageService } from '../../services/ohn-language.service';

import * as _ from 'underscore/underscore';

import { DEBUG_MODE } from '../../../environments/environment';

@Component({
  selector: 'app-branched-content-renderer',
  templateUrl: './branched-content-renderer.component.html',
  styleUrls: ['./branched-content-renderer.component.scss'],
})
export class BranchedContentRendererComponent implements OnInit {

  @Input() parentElementSlug: string;
  @Input() user: OHNUser;
  @Input() event: OHNCalendarEvent|OHNMedication|OHNMeeting;
  @Input() parentElement : OHNElement;
  @Input() isSinglePage : boolean;
  @Input() viewOnly : boolean;
  @Input() splitSinglePageByElements : boolean;
  @Input() lockCloseButton : boolean;
  @Input() hideDoneButton : boolean;
  @Input() isOnboarding : boolean;
  @Input() passedElementValues : any[]; 
  @Input() hideCloseButton : boolean; 

  currentPage : OHNElement;

  pageStack : OHNElement[];

  basicElements : any = {};

  currentPageIndex : number = 0;

  loading : boolean = true;

  hasBranching : boolean = true;

  finished : boolean = false;

  rules : any;

  resultPageSlug : string = '';

  shadowFlowSlugs : string[] = [];

  enableEveryStepReportPatch: boolean = false;

  reportElementStateId : string;


  constructor(
    public modalController: ModalController,
  	private ohnApi: OhnApiService,
  	private ohnService: OhnService,
    private ohnLogicService: OhnLogicService,
    private actionsProcessor: ActionsProcessorService,
    private alertCtrl: AlertController,
    private lS: OhnLanguageService,
  ) { }

  ngOnInit() {
    localStorage.setItem('OHNcurrentBranchedLogicUser', this.user.smart_contract);
    localStorage.setItem('OHNcurrentBranchedLogicUserName', this.user.username);
    localStorage.removeItem('ohn-radonc-pdf-recently-generated-state-id');
    localStorage.removeItem('ohn-radonc-printout-recently-generated');
    this.loading = true;
    if (this.parentElement) {
      this.initBranching();
    } else {
      this.loadParentElement();
    }
  }

  loadParentElement() {
    this.ohnApi.getElement(this.parentElementSlug, 3).subscribe(element => {
      this.parentElement = <OHNElement>element;
      this.parentElement.config = this.parentElement.config || {};
      if (this.parentElement.config.enableEveryStepReportPatch) {
        this.enableEveryStepReportPatch = true;
      }
      this.initBranching(); 
    });
  }

  initBranching() {
    if (this.splitSinglePageByElements) {
      let pageReplacements : OHNElement[] = [];
      this.parentElement.elements.forEach((e, i)=>{
        pageReplacements.push(<OHNElement>{
          text : e.text,
          element_slug: i.toString(),
          _cls : "Page",
          controller : "pageController",
          elements : [e]
        });
      });
      this.parentElement.elements = pageReplacements;
    }
    this.currentPage = this.isSinglePage ? this.parentElement : this.parentElement.elements[0];
    this.hasBranching = this.parentElement.config && !_.isUndefined(this.parentElement.config.rules);
    if (this.hasBranching) {
      this.rules = this.parentElement.config.rules;
      this.collectBasicElements(this.parentElement);
    }
    this.loading = false;
    this.shadowFlowSlugs.push(this.currentPage.element_slug);
  }

  collectBasicElements(element: OHNElement) {
    element.elements.forEach(p => {
      p.elements.forEach(e => {
        this.basicElements[e.element_slug] = e;
      });
    });
    if (this.passedElementValues) {
      this.passedElementValues.forEach(ev=>{
        this.basicElements[ev.element_slug].value = ev.value;
      })
    }
    this.ohnLogicService.init(this.rules, this.basicElements, element.elements[0].element_slug);
  }

  goNext() {
    if (!this.splitSinglePageByElements) {
      this.savePageState(this.currentPage);
    }

    if (this.enableEveryStepReportPatch) {
      this.setReportElemetntState();
    }

    if (this.hasBranching) {
      let logicNode : OHNLogicNode = this.ohnLogicService.getNextNode();
      if (logicNode) {
        this.currentPage = this.parentElement.elements.find(p => p.element_slug === logicNode.id);
      }
    } else {
      if (this.parentElement.elements[this.currentPageIndex + 1]) {
        this.currentPage = this.parentElement.elements[this.currentPageIndex + 1];
      }
    }
    if (this.currentPage.config && this.currentPage.config.isResultPage) {
      this.resultPageSlug = this.currentPage.element_slug;
    }
    this.currentPageIndex = this.parentElement.elements.findIndex(p => p.element_slug === this.currentPage.element_slug);
    this.shadowFlowSlugs.push(this.currentPage.element_slug);
  }

  goPrevious() {
    if (!this.splitSinglePageByElements) {
      this.savePageState(this.currentPage);
    }

    if (this.hasBranching) {
      if (this.ohnLogicService.currentNode.previousNodeId != '') {
        this.ohnLogicService.finalNodeReached = false;
        this.currentPage = this.parentElement.elements.find(p => p.element_slug === this.ohnLogicService.currentNode.previousNodeId);
        this.ohnLogicService.goToNode(this.ohnLogicService.currentNode.previousNodeId);
      }
    } else {
      if (this.parentElement.elements[this.currentPageIndex - 1]) {
        this.currentPage = this.parentElement.elements[this.currentPageIndex - 1];
      }
    }
    this.currentPageIndex = this.parentElement.elements.findIndex(p => p.element_slug === this.currentPage.element_slug);
    this.shadowFlowSlugs.pop();
  }

  setReportElemetntState() {
    if (this.parentElement.report_element) {
      let slugs : string[] = this.collectShadowUsedElements();
      slugs = slugs.filter((s)=>{
        return this.parentElement.report_element.elements.find((e)=>{return e.element_slug == s});
      });
      if (slugs.length > 0) {
        if (this.reportElementStateId) {
          this.ohnApi.patchElementStateSc(this.parentElement.report_element.element_slug, {value : {id : this.reportElementStateId, value: slugs }}, this.user.smart_contract).subscribe(reportState => {

          });
        } else {
          this.ohnApi.setElementStateSc(this.parentElement.report_element.element_slug, {value: slugs }, this.user.smart_contract).subscribe(reportState => {
            if (this.event) {
              this.event.report_element_state_id = reportState.id;
              let patchObject: any = _.pick(this.event, 'id', 'report_element_state_id', 'nylas_calendar_id', 'nylas_event_id');
              this.ohnApi.patchElementStateSc('calendar_container', {value: patchObject}, this.user.smart_contract).subscribe(state => {
              });
            }
            this.reportElementStateId = reportState.id;
          });
        }
      }
    }
  }

  isFinalNode() {
    return (this.hasBranching && this.ohnLogicService.finalNodeReached) || (!this.hasBranching && this.currentPageIndex === (this.parentElement.elements.length - 1)) || this.isSinglePage;
    //return this.currentPageIndex === (this.parentElement.elements.length - 1);
  }
//add saving when done clicked
  finish() {
    this.loading = true;
    this.finished = true;
    if (this.splitSinglePageByElements) {
      let restoredPage : OHNElement = <OHNElement>{
        text : this.parentElement.text,
        element_slug : this.parentElement.element_slug,
        _cls : 'Page',
        controller : 'pageController',
        elements : []
      }

      this.parentElement.elements.forEach(e => {
        DEBUG_MODE && console.log('Element', e);
        restoredPage.elements.push(e.elements[0]);
      });
      this.savePageState(restoredPage);
    } else {
      this.savePageState(this.currentPage);
    }
  }

  savePageState(page : OHNElement) {
    if (this.parentElement.config.viewOnly) {
      if (this.finished) {
        this.closeModal();
      }
    } else {
      let stateObject: any = {value : {}};
      page.elements.forEach(e => {
        if (e.value !== undefined && e.value !== null) stateObject.value[e.element_slug] = e.value;
      });
 /*     if (this.splitSinglePageByElements) {
        this.closeModal();
      } else {*/
        this.ohnApi.setElementStateSc(page.element_slug, stateObject, this.user.smart_contract).subscribe(state => {
          if (this.finished) {
            if (this.parentElement.config.completionCounter) {
              this.ohnApi.getElementStateSc(this.parentElement.config.completionCounter.counterSlug, this.user.smart_contract).subscribe(state =>{
                let counterValue : number = 0;
                if (state && state.value) {
                   if (state.value.value == null) {
                     counterValue = this.parentElement.config.completionCounter.startValue;
                   } else {
                     counterValue = state.value.value + 1;
                   }
                }
                this.ohnApi.setElementStateSc(this.parentElement.config.completionCounter.counterSlug, {value : counterValue}, this.user.smart_contract).subscribe(state =>{
                  this.saveForReport(stateObject);
                });
              });
            } else {
              this.saveForReport(stateObject);
            }
          }
        });
      //}
    }
  }
 
  saveForReport(data? : any) {
    if (this.parentElement.report_element) {
      let slugs : string[] = this.collectShadowUsedElements();
      slugs = slugs.filter((s)=>{
        return this.parentElement.report_element.elements.find((e)=>{return e.element_slug == s});
      });
      if (this.reportElementStateId) {
        this.ohnApi.patchElementStateSc(this.parentElement.report_element.element_slug, {value : {id : this.reportElementStateId, value: slugs }}, this.user.smart_contract).subscribe(reportState => {
          if (this.event) {
            this.event.report_element_state_id = this.reportElementStateId;
            this.saveCompletion();
          } else {
            this.closeModal(data);
          }
        });
      } else {
        this.ohnApi.setElementStateSc(this.parentElement.report_element.element_slug, {value: slugs }, this.user.smart_contract).subscribe(reportState => {
          if (this.event) {
            this.event.report_element_state_id = reportState.id;
            this.saveCompletion();
          } else {
            this.closeModal(data);
          }
        });
      }
    } else {
      this.closeModal(data);
    }
  }

  collectShadowUsedElements(){
    let basicElementSlugs : string[] = [];
    this.shadowFlowSlugs.forEach((s)=>{
      const page = this.parentElement.elements.find((e)=>{return e.element_slug == s;});
      page.elements.forEach((el)=>{
        basicElementSlugs.push(el.element_slug)
      })
    });
    return basicElementSlugs;
  }

  saveCompletion() {
    this.event.completed = true;
    let patchObject: any = _.pick(this.event, 'id', 'completed', 'report_element_state_id', 'startTime', 'nylas_calendar_id', 'nylas_event_id');
    patchObject['completedTime'] = new Date();
    this.ohnApi.patchElementStateSc('calendar_container', {value: patchObject}, this.user.smart_contract).subscribe(state => {
      this.loading = false;
      this.closeModal();
    });
  }

  async showClosingDialog() {
   const alert = await this.alertCtrl.create({
      header: this.lS.g('closing'),
      message: this.lS.g('doYouWantToSaveTheResult'),
      buttons: [
        {
          text: this.lS.g('no'),
          cssClass: 'secondary',
          handler: (blah) => {
             this.resultPageSlug = '';
             this.closeModal();
          }
        }, {
          text: this.lS.g('yes'),
          handler: () => {
            this.finish();
          }
        }
      ]
    });

    await alert.present();
  }

  checkClickAction() {
    if (this.currentPage.config && this.currentPage.config.onClickAction) {
      switch(this.currentPage.config.onClickAction) {
        case "goNext" : 
          this.goNext();
          break;
      }
    }
  }

  closeModal(data? : any) {
    this.loading = false;
    localStorage.removeItem('OHNcurrentBranchedLogicUser');
    localStorage.removeItem('OHNcurrentBranchedLogicUserName');
    localStorage.removeItem('ohn-radonc-pdf-recently-generated-state-id');
    
    if (this.resultPageSlug.length > 0 && !this.finished) {
      this.showClosingDialog();
    } else {
      if (this.finished) {
        if (this.parentElement.actions && this.parentElement.actions.triggers && this.parentElement.actions.triggers.completed) {
          if (!this.parentElement.config || !this.parentElement.config.runActionsOnboardingOnly || (this.parentElement.config.runActionsOnboardingOnly && this.isOnboarding)) {
            this.actionsProcessor.processActions(this.parentElement.actions.triggers.completed, this.resultPageSlug, this.user);
          }
        }
      }
      this.modalController.dismiss({
        dismissed: true,
        finished : this.finished,
        data : data
      });
    }
  }

  pageNotValidated() {
    return this.currentPage.elements.find(e=>{return !e.valid;})
  }

}