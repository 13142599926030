import { Component, OnInit, Input } from '@angular/core';
import { OHNElement, OHNUser } from '../../models/ohn-instances';
import { OhnApiService } from '../../services/ohn-api.service';
import { OhnService } from '../../services/ohn.service';
import { combineLatest, Observable, forkJoin} from 'rxjs';
import { AlertController, ToastController } from '@ionic/angular';
import { AuthenticationService } from '../../services/authentication.service';
import { OhnLanguageService } from '../../services/ohn-language.service';
import * as _ from 'underscore/underscore';

@Component({
  selector: 'app-user-profile-editor',
  templateUrl: './user-profile-editor.component.html',
  styleUrls: ['./user-profile-editor.component.scss'],
})
export class UserProfileEditorComponent implements OnInit {

	@Input() user: OHNUser;

	saving : boolean = false;

	profile : any = {
		roleProfileElements : []
	};

  constructor(
  	private ohnApi: OhnApiService,
  	private ohnService: OhnService,
  	private auth: AuthenticationService,
    private alertCtrl: AlertController,
    private toastController: ToastController,
    private lS: OhnLanguageService,
  ) { }

  ngOnInit() {
  	this.getProfileElement();
  }

  getProfileElement() {
  	combineLatest(
      this.ohnApi.getElement(this.ohnService.appRoleModifier('patient', false) + '_profile', 3),
      this.ohnApi.getElementStateSc((this.ohnService.appRoleModifier('patient', false) + '_profile'), this.user.smart_contract),
    ).subscribe((elements: any[]) => {
      if (elements[1].value && elements[1].value.value) {
        elements[0].elements.forEach(e =>{           
          e.value = elements[1].value.value[e.element_slug];
        });
      }
      this.profile.roleProfileElements = elements[0].elements.filter(e=>{return e.controller != 'contentElementController'});
    });
  }

  saveProfiles(){
  	this.saving = true;
  	const profileValues =  _.object(_.map(this.profile.roleProfileElements, (e) => { return [e.element_slug, e.value] }));
    this.ohnApi.setElementStateSc((this.ohnService.appRoleModifier('patient', false) + '_profile'), {value : profileValues}, this.user.smart_contract).subscribe(p=>{
    	this.saving = false;
    	if (this.user.profiles && this.user.profiles[this.ohnService.appRoleModifier('patient', false) + '_profile']) {
    		this.user.profiles[this.ohnService.appRoleModifier('patient', false) + '_profile']['value'] = profileValues;
    	}
    	this.updateLocalProfileReplacements();
      this.presentToast();
    })
  }

  updateLocalProfileReplacements(){
    if (localStorage.getItem('ohnReplacements') && this.user.profiles.patient_profile && this.user.profiles.patient_profile.value) {
      let replacements : any = JSON.parse(localStorage.getItem('ohnReplacements'));
      replacements.settings.pipe.forEach((p)=>{
        if (this.user.profiles.patient_profile.value[p.withProfileElementSlug]) {
          replacements.data[p.replace] = this.user.profiles.patient_profile.value[p.withProfileElementSlug]
        }
      })
      localStorage.setItem('ohnReplacements', JSON.stringify(replacements));
    }
  }

  dropProfile() {
    this.user.onboarded_at = null;
    this.ohnApi.setElementStateSc('tutorial-2_13810', {value : null}, this.user.smart_contract).subscribe();
    this.ohnApi.patchUser(this.user).subscribe();
    this.auth.logout(); 
  }

  async showDropProfileDialog() {
   const alert = await this.alertCtrl.create({
      header: this.lS.g('reset'),
      message: this.lS.g('tapResetToClear'),
      buttons: [
        {
          text: this.lS.g('keepData'),
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
           
          }
        }, {
          text: this.lS.g('reset'),
          handler: () => {
            this.dropProfile();
          }
        }
      ]
    });

    await alert.present();
  }

  async presentToast() {
    const toast = await this.toastController.create({
      message: this.lS.g('settingsHasBeenSaved'),
      duration: 2000,
      position : 'middle'
    });
    toast.present();
  }

}