import { Component, OnInit, Input } from '@angular/core';
import { OhnLogicService } from '../../../services/ohn-logic.service';

@Component({
  selector: 'app-numeric-pick-one',
  templateUrl: './numeric-pick-one.component.html',
  styleUrls: ['./numeric-pick-one.component.scss'],
})
export class NumericPickOneComponent implements OnInit {

  @Input() el: any;
  @Input() viewMode : string;

  validityRules : any[];
  rangeArray : number[]; 

  constructor(
    private ohnLogicService: OhnLogicService,
  ) { }

  ngOnInit() {
    this.rangeArray = Array(Number(this.el.config.max)-Number(this.el.config.min) + 1).fill(Number(this.el.config.min)).map((x,i) => i + x);
    this.el.value = this.el.value || undefined;
    this.el.valid = true;
    this.validityRules = (this.el.config && this.el.config.validityRules) ? this.el.config.validityRules : [];
    if (this.validityRules.length > 0) {
      this.el.valid = this.ohnLogicService.processValidityRules(this.validityRules, this.el.controller, this.el.value);
    }
  }

  changed(tick : any) {
    if (this.el.value != -1) {
      this.el.value = tick;
      this.el.valid = this.ohnLogicService.processValidityRules(this.validityRules, this.el.controller, this.el.value);
    }
  }

  modelChanged(){
    this.el.valid = this.ohnLogicService.processValidityRules(this.validityRules, this.el.controller, this.el.value);
  }

}
