import { Component, OnInit, Input } from '@angular/core';
import { OhnApiService } from '../../../services/ohn-api.service';
import { OHNElement, OHNUser } from '../../../models/ohn-instances';
import { OhnLogicService } from '../../../services/ohn-logic.service';

@Component({
  selector: 'app-session-counter',
  templateUrl: './session-counter.component.html',
  styleUrls: ['./session-counter.component.scss'],
})
export class SessionCounterComponent implements OnInit {

  @Input() el: OHNElement;
  @Input() user : OHNUser;

  constructor(
    private ohnApi: OhnApiService,
    private ohnLogicService: OhnLogicService,
  ) { }

  ngOnInit() {
    this.getState();
    this.el.valid = true;
  }

  getState() {
    this.ohnApi.getElementStateSc(this.el.element_slug, this.user.smart_contract).subscribe(state =>{
      if (state && state.value) {
         if (state.value.value == null) {
           this.el.value = this.el.config.startValue;
           this.ohnLogicService.setValueOfElementContainer(this.el.element_slug, this.el.value);
           this.setState();
        } else {

        }
      }
    });
    /*this.ohnApi.getElementStateSc(this.el.element_slug, this.user.smart_contract).subscribe(state =>{
      if (state && state.value) {
         if (state.value.value == null) {
           this.el.value = this.el.config.startValue;
         } else {
           this.el.value = state.value.value + 1;
         }
         this.setState();
      }
    });*/
  }

  setState() {
     this.ohnApi.setElementStateSc(this.el.element_slug, {value : this.el.value}, this.user.smart_contract).subscribe(state =>{
     });
  }

}
