import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { OhnLanguageService } from '../../services/ohn-language.service';

@Component({
  selector: 'app-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.scss'],
})
export class DisclaimerComponent implements OnInit {

	@Input() text: string;

	el : any;

	format: any = {
		config: {
			disclaimer : ""
		}
	}

  constructor(
  	public modalController : ModalController,
  	private lS: OhnLanguageService,
  ) { }

  ngOnInit() {
  	this.el = {
  		value : this.text
  	}
  }

  dismiss(accepted: boolean) {
		this.modalController.dismiss({
				dismissed: true,
				accepted: accepted,
				config : this.format
		});
  }

  updateLinks(){
  	let links: any = document.links;
		for (let i = 0, linksLength = links.length; i < linksLength; i++) {
		  links[i].target = '_blank';
		}
  }

}
