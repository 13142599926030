import { Component, OnInit, Input } from '@angular/core';
import { OhnApiService } from '../../../services/ohn-api.service';
import { OHNElement} from '../../../models/ohn-instances';

@Component({
  selector: 'app-jinia-template-config',
  templateUrl: './jinia-template-config.component.html',
  styleUrls: ['./jinia-template-config.component.scss'],
})
export class JiniaTemplateConfigComponent implements OnInit {

  @Input() el : OHNElement;

  constructor(
    private ohnApi: OhnApiService,
  ) { }

  ngOnInit() {}

  saveTemplate(){
    this.ohnApi.setElement(this.el.element_slug, this.el).subscribe();
  }

}
 