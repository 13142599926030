import { Component, OnInit, NgZone } from '@angular/core';
import { OhnBLEService } from '../../../services/ohn-ble.service';
import { OhnService } from '../../../services/ohn.service';
declare let ble: any;

@Component({
  selector: 'app-o2-ring-oximeter',
  templateUrl: './o2-ring-oximeter.component.html',
  styleUrls: ['./o2-ring-oximeter.component.scss'],
})
export class O2RingOximeterComponent implements OnInit {

  bluetoothDevices : any[] = [];
  bluetoothPaired : boolean = false;
  currentDeviceId : string;
  currentDeviceName : string;
  responseContent : any[] = [];
  scanning : boolean = false;
  content : string = "";

  constructor(
    private ble : OhnBLEService,
    private _ngZone: NgZone,
    private ohnService : OhnService
  ) { }

  ngOnInit() {
    this.currentDeviceId = localStorage.getItem('ohnConnectedBluetoothO2RingId');
    this.currentDeviceName = localStorage.getItem('ohnConnectedBluetoothO2RingName');
    this.initBluetoothDevices();
  }

  initBluetoothDevices() {
    let self = this;
    this.ble.dataReceived.subscribe((d)=>{
      alert(d);
      this.content += d;
    });
    if (this.currentDeviceId) {
      this.ble.deviceConnected(this.currentDeviceId).then((connected)=>{
      })
    }
  }

  scanForDevices() {
    this.scanning = true;
    setTimeout(()=>{ this.scanning = false;}, 3000);
    this.ble.bleEnabled().then((enabled)=>{
      this.bluetoothDevices = [];
      this.ble.scanForDevices().subscribe((device)=>{
        this._ngZone.run(() => {
          if(device.name && device.name != "") {
           this.bluetoothDevices.push(device);
          }
        });
      },
      (error)=>{
        alert(JSON.stringify(error));
      });
    },
    (error)=>{
      this.ohnService.showAlert('Bluetooth', 'Bluetooth is not enabled on this device. Please enable it to allow access to Bluetooth peripherals.');
    });
  }

  pairBluetoothDevice(device: any){
    this.ble.connect(device, 'o2oximeter').subscribe((dev)=>{
      this._ngZone.run(() => { 
        this.currentDeviceId = device.id;
        this.currentDeviceName = device.name;
        this.bluetoothPaired = true;
      });
      this.ble.subscribeForDataread(device.id, 'o2oximeter');
    },
    (error)=>{ 
      alert(JSON.stringify(error));
    });
  }


  syncWithBluetooth() {
    ble.isConnected(this.currentDeviceId, 
    ()=>{
    },
    ()=>{
      this.ble.connect({name : this.currentDeviceName, id : this.currentDeviceId}, 'o2oximeter').subscribe((dev)=>{
        this.ble.subscribeForDataread(this.currentDeviceId, 'o2oximeter');
      },
      (error)=>{ 
        alert(JSON.stringify(error));
      });
    })
  }

  disconnectBluetooth(deviceId){
    this.currentDeviceId = undefined;
    this.ble.disconnect(deviceId);
  }

}
