import { Component, OnInit, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { OhnApiService } from '../../services/ohn-api.service';
import { OhnService } from '../../services/ohn.service';
import { OHNElement, OHNUser } from '../../models/ohn-instances';
import { PopoverComponent } from '../basic/popover/popover.component';
import * as _ from 'underscore/underscore';

@Component({
  selector: 'app-ranged-rules-renderer',
  templateUrl: './ranged-rules-renderer.component.html',
  styleUrls: ['./ranged-rules-renderer.component.scss'],
})
export class RangedRulesRendererComponent implements OnInit {

	@Input() parentElementSlug : string;
	@Input() user: OHNUser;
	@Input() type: string;

	parentElement : OHNElement;

	loading : boolean = true;

  constructor(
  	private ohnApi: OhnApiService,
  	public ohnService: OhnService,
  	public popoverController: PopoverController
  ) { }

  ngOnInit() {
  	this.getParentElement();
  }

  getParentElement() {
  	this.ohnApi.getElement(this.parentElementSlug, 3).subscribe(element => {
  		this.parentElement = element;
  		this.getParentElementState();
  	});
  }

  getParentElementState() {
  	this.ohnApi.getElementStateSc(this.parentElementSlug, this.user.smart_contract).subscribe(pageState => {
  		this.user.order = 0;
  		if (pageState.value) {
  			this.parentElement.elements = _.filter(this.parentElement.elements, (e)=>{return this.type == 'extended' ? pageState.value[e.element_slug] : pageState.value[e.element_slug] && pageState.value[e.element_slug].value > 0});
  			_.each(this.parentElement.elements, (e) => {
  				if (pageState.value[e.element_slug]) {
  					e.color = OhnService.getColorByRange(e.config.ranges, pageState.value[e.element_slug].value);
  					e.state = (pageState.value[e.element_slug].alt_value == "") ? {} : JSON.parse(pageState.value[e.element_slug].alt_value);
  					this.user.order += OhnService.getValueByRange(e.config.ranges, pageState.value[e.element_slug].value);
  				}
  			});
  			if (this.type == 'extended') {
  				this.parentElement.elements = _.filter(this.parentElement.elements, (e)=>{return e.state.notes && e.state.notes.length > 0});
  			}
  		}
  		this.loading = false;
  	});
  }



async showRuleDetails(ev: any, rule : any) {

		const popover = await this.popoverController.create({
			component: PopoverComponent,
      event: ev,
			componentProps: {
				inputData: rule, 
				dataType : 'rule',
				user : this.user
			},
			backdropDismiss: true,
			mode: "ios"
		});

		popover.onDidDismiss().then((res) => {

		});

		return await popover.present();
	}
}