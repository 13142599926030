import { Component, OnInit, Input } from '@angular/core';
import { OHNElement, OHNUser, OHNExternalDevice} from '../../models/ohn-instances';
import { OhnApiService } from '../../services/ohn-api.service';
import { OhnService } from '../../services/ohn.service';
import { ModalController, AlertController } from '@ionic/angular';

@Component({
  selector: 'app-external-device-management',
  templateUrl: './external-device-management.component.html',
  styleUrls: ['./external-device-management.component.scss'],
})
export class ExternalDeviceManagementComponent implements OnInit {

  @Input() user : OHNUser;
  @Input() me : OHNUser;

  loading : boolean = false;

  availableDevices : any[] = [];

  userDevices : OHNExternalDevice[] = [];

  newDevice : OHNExternalDevice = <OHNExternalDevice>{};

  currentSelectedDevice : any;

  constructor(
    public modalController: ModalController,
    private ohnApi: OhnApiService,
    private ohnService: OhnService,
    private alertCtrl: AlertController
  ) { }

  ngOnInit() {
    this.loading = true;
    this.loadAvailableDevices();
  }

  loadAvailableDevices() {
    this.ohnApi.getAvailableExternalDevices().subscribe(devices => {
      this.availableDevices = devices;
      this.loadUserDevices();
    });
  }

  loadUserDevices() {
    this.ohnApi.getUserDevices(this.user.smart_contract).subscribe(devices => {
      this.userDevices = devices;
      this.loading = false;
    });
  }

  manufacturerChanged() {
    this.currentSelectedDevice = this.availableDevices.find(d => { return d.device_manufacturer == this.newDevice.device_manufacturer});
  }

  addNewDevice() {
    this.userDevices.push(this.newDevice);
    this.ohnApi.addUserDevice(this.newDevice, this.user.smart_contract).subscribe(devices => {
    });
    this.newDevice = <OHNExternalDevice>{};
  }

  deleteDevice(device : OHNExternalDevice) {
    this.userDevices = this.userDevices.filter(d => {return d.device_manufacturer != device.device_manufacturer});
    this.ohnApi.deleteUserDevice(device.device_uuid, this.user.smart_contract).subscribe(devices => {
    });
  }

  closeModal() {
    this.modalController.dismiss({
      dismissed: true
    });
  }

}
