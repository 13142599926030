import { Component, OnInit, Input, Renderer2 } from '@angular/core';
import { OHNElement, OHNCalendarActionList, OHNCalendarAction } from '../../models/ohn-instances';
import { OhnService } from '../../services/ohn.service';
import * as _ from 'underscore/underscore';

import { DEBUG_MODE } from '../../../environments/environment';

@Component({
  selector: 'app-calendar-action-list-manager',
  templateUrl: './calendar-action-list-manager.component.html',
  styleUrls: ['./calendar-action-list-manager.component.scss'],
})
export class CalendarActionListManagerComponent implements OnInit {

  @Input() actionList: OHNCalendarActionList;
  @Input() contentModeEditable : boolean;

  constructor(
    private ohnService: OhnService,
    private renderer: Renderer2
  ) { }

  ngOnInit() {
    DEBUG_MODE && console.log('Action List', this.actionList);
  }

  newDescriptionElement(element: OHNElement){
    let el : OHNElement = this.ohnService.getObjectCopy(element);
    this.actionList.description.push(el);
  }

  deleteActionListDescriptionElement(elementIndex: number) {
    this.actionList.description.splice(elementIndex, 1);
  }

  newActionDescriptionElement(element: OHNElement, actionIndex: number ){
    let el : OHNElement = this.ohnService.getObjectCopy(element);
    this.actionList.actions[actionIndex].description.push(el);
  }

  deleteActionDescriptionElement(elementIndex: number, actionIndex: number) {
    this.actionList.actions[actionIndex].description.splice(elementIndex, 1);
  }

  newAction() {
    this.actionList.actions.push(
      <OHNCalendarAction>{
        text : "",
        description:[],
        done : false,
        trackingParameters : [],
        repeat : 1,
        id : OhnService.getUniqueId(this.actionList.actions)
      }
    );
  }

  deleteAction(actionIndex: number) {
    this.actionList.actions.splice(actionIndex, 1);
  }

  newTrackingParameter(actionIndex: number) {
    this.actionList.actions[actionIndex].trackingParameters.push(
      <OHNElement>{
        element_slug: "0",
        controller: "numberParameterController",
        text: "",
        value: 0,
        prescribed: 0,
        id : OhnService.getUniqueId(this.actionList.actions[actionIndex].trackingParameters)
      }
    );
  }

  deleteTrackingParameter(actionIndex: number, parameterIndex: number){
    this.actionList.actions[actionIndex].trackingParameters.splice(parameterIndex, 1);
  }


  toggleClass(event: any, selector: string) {
    const hasClass = event.target.parentNode.classList.contains(selector);

    if(hasClass) {
      this.renderer.removeClass(event.target.parentNode, selector);
    } else {
      this.renderer.addClass(event.target.parentNode, selector);
    }
  }

  toggleView(ev: any, element: any) {
    
    let isCollapsed = element.el.getAttribute('data-collapse');

    if (isCollapsed == "true") {
      element.el.setAttribute('data-collapse', "false");
      this.renderer.addClass(element.el, 'rolled-out');
      ev.target.firstChild.setAttribute('name', 'ellipsis-horizontal');
    } else {
      element.el.setAttribute('data-collapse', "true");
      this.renderer.removeClass(element.el, 'rolled-out');
      ev.target.firstChild.setAttribute('name', 'ellipsis-vertical');
    }
  }
}