import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
	transform(items: any[], searchFields: string[], searchText: string): any[] {
	    if(!items) return [];
	    if(!searchText || !searchFields) return items;
		searchText = searchText.toLowerCase();
		items = Array.from(items);
		return items.filter( it => {
			return searchFields.some(field => {
				(Array.isArray(it[field])) && (it[field].map(v => v.toLowerCase()));
	      return ((Array.isArray(it[field])) ? (it[field] && it[field].includes(searchText)) : (it[field] && it[field].toLowerCase().includes(searchText)));
    	});
    });
	}
}