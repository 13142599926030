import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-list-field-element',
  templateUrl: './list-field-element.component.html',
  styleUrls: ['./list-field-element.component.scss'],
})
export class ListFieldElementComponent implements OnInit {

	@Input() el: any;
	@Input() viewMode : string;

  constructor() { }

  ngOnInit() {
    this.el.valid = true;
  }

}
