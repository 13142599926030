import { Component, OnInit, Input } from '@angular/core';
import { OHNElement, OHNUser } from '../../models/ohn-instances';

@Component({
  selector: 'app-basic-elements-renderer',
  templateUrl: './basic-elements-renderer.component.html',
  styleUrls: ['./basic-elements-renderer.component.scss'],
})
export class BasicElementsRendererComponent implements OnInit {

	@Input() elements : OHNElement[];
	@Input() user : OHNUser;
	@Input() viewMode : string;
  @Input() wideScreenAvailable : boolean;
  @Input() allElements : OHNElement[];

  constructor() { }

  ngOnInit() {
  }

}