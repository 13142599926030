import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Health } from '@ionic-native/health/ngx';
//import { DBMeter } from '@ionic-native/db-meter/ngx';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http'; 
import { DatePipe } from '@angular/common'
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { Insomnia } from '@ionic-native/insomnia/ngx';
import { Device } from '@ionic-native/device/ngx';
import { BLE } from '@ionic-native/ble/ngx';


import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { OhnApiService } from './services/ohn-api.service';
import { SharedModule } from './app.shared.module';
import { NgxEchartsModule } from 'ngx-echarts';
import { MarkdownModule, MarkedOptions, MarkedRenderer } from 'ngx-markdown';

//////////////////Pages///////////////////
import { NewUserPage } from './secured/new-user/new-user.page';
import { NewCalendarEventPage } from './secured/new-calendar-event/new-calendar-event.page';
import { CalendarEventViewPage } from './secured/calendar-event-view/calendar-event-view.page';
import { HealthTrackingEntryPage } from './secured/health-tracking-entry/health-tracking-entry.page';
import { CalendarSequenceManagerPage } from './secured/calendar-sequence-manager/calendar-sequence-manager.page';
import { CalendarEventResultPage } from './secured/calendar-event-result/calendar-event-result.page';
import { PopoverComponent } from './components/basic/popover/popover.component';
import { NewMedicationComponent } from './components/new-medication/new-medication.component';
import { NewMeetingComponent } from './components/new-meeting/new-meeting.component';
import { AssessmentRendererComponent } from './components/assessment-renderer/assessment-renderer.component';
//import { BranchedContentRendererComponent } from './components/branched-content-renderer/branched-content-renderer.component';
import { RoadmapComponent } from './components/roadmap/roadmap.component';
import { HistoryRendererComponent } from './components/history-renderer/history-renderer.component';
import { CalendarEventSchedulerComponent } from './components/calendar-event-scheduler/calendar-event-scheduler.component';
import { NotesHistoryComponent } from './components/notes-history/notes-history.component';
import { BookmarksComponent } from './components/bookmarks/bookmarks.component';


//////////////////Components///////////////////

@NgModule({
  declarations: [
    AppComponent,
    NewCalendarEventPage,
    NewUserPage,
    CalendarEventViewPage,
    HealthTrackingEntryPage,
    CalendarSequenceManagerPage,
    CalendarEventResultPage,
    PopoverComponent,
    NewMedicationComponent,
    NewMeetingComponent,
    AssessmentRendererComponent,
    //BranchedContentRendererComponent,
    RoadmapComponent,
    HistoryRendererComponent,
    CalendarEventSchedulerComponent,
    NotesHistoryComponent,
    BookmarksComponent
  ],
  entryComponents: [
    NewCalendarEventPage,
    NewUserPage,
    CalendarEventViewPage,
    HealthTrackingEntryPage,
    CalendarSequenceManagerPage,
    CalendarEventResultPage,
    PopoverComponent,
    NewMedicationComponent,
    NewMeetingComponent,
    AssessmentRendererComponent,
    //BranchedContentRendererComponent,
    RoadmapComponent,
    HistoryRendererComponent,
    CalendarEventSchedulerComponent,
    NotesHistoryComponent,
    BookmarksComponent
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    IonicModule.forRoot({mode: 'md'}), 
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts')
    }),
    MarkdownModule.forRoot({})
  ],
  providers: [
    OhnApiService,
    MarkdownModule,
    //DBMeter,
    InAppBrowser,
    Insomnia,
    Device,
    Health,
    DatePipe,
    BLE,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}