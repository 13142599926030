import { Component, OnInit, Input} from '@angular/core';
import { DatePipe } from '@angular/common';
import { ModalController, ToastController, AlertController } from '@ionic/angular';
import { OHNUser, OHNCalendarEvent, OHNElement ,OHNCalendarAction, OHNCalendarActionList, OHNMedication, OHNMeeting } from '../../models/ohn-instances';
import { OhnApiService } from '../../services/ohn-api.service';
import { Observable, forkJoin } from 'rxjs';
import { OhnService } from '../../services/ohn.service';
import * as _ from 'underscore/underscore';

@Component({
  selector: 'app-new-meeting',
  templateUrl: './new-meeting.component.html',
  styleUrls: ['./new-meeting.component.scss'],
})
export class NewMeetingComponent implements OnInit {

	@Input() user: OHNUser;
	@Input() calendarElement : OHNElement;
  @Input() isNew: boolean;
  @Input() editMeeting: OHNMeeting;

  actionProcessed: string;

  meeting: OHNMeeting;

  datePipe : DatePipe = new DatePipe('en-US');

	loading: boolean = false;

  weekDaysToTake: boolean[] = [false, false, false, false, false, false, false];

  meetingTimes : any[] = [];

  daily: boolean = false;

  searching: boolean = false;
  fillType: string = 'manually';

  search : any = {
    startTime : "2020-01-01T09:00:00Z",
    endTime : "2020-01-01T17:00:00Z",
    day : 1
  }

  userTimezoneOffset : number = new Date().getTimezoneOffset() * 60000;

  searchProcessing : boolean = false;

  searchResult : any[] = [];

  rrule : any = {};

  rruleDays : any[] = [
    {val : 'SU', isChecked : false},
    {val : 'MO', isChecked : false},
    {val : 'TU', isChecked : false},
    {val : 'WE', isChecked : false},
    {val : 'TH', isChecked : false},
    {val : 'FR', isChecked : false},
    {val : 'SA', isChecked : false}
  ]

  constructor(
  	public modalController: ModalController,
    public toastController: ToastController,
    private ohnApi: OhnApiService,
    private ohnService: OhnService,
    private alertCtrl: AlertController
  ) { }

  ngOnInit() {
  	this.loadDetailedCalendarElement();
    this.loadSearchFields();
    this.meeting = this.editMeeting || new OHNMeeting();
    if (this.isNew){
      this.meeting.startTime = new Date().toISOString();
      this.meeting.endTime = new Date(new Date().getTime() + 600000).toISOString();
      this.addIntakeTime();
    } else {
      this.meeting.startTime = new Date(this.meeting.startTime).toISOString();
      this.meeting.endTime = new Date(this.meeting.endTime).toISOString();
      this.setWeekdaysAndIntakeTimes();
    }
  }

  setWeekdaysAndIntakeTimes() {
    this.meeting.cron.weekday.forEach(w => { this.weekDaysToTake[w] = true; });
    this.daily = _.filter(this.weekDaysToTake, (d)=>{return !d;}) ? false : true;
    this.meeting.cron.hour.forEach((h,i) => {
      let tmpDate : Date = new Date();
      tmpDate.setHours(h);
      tmpDate.setMinutes(this.meeting.cron.minute[i]);
      this.meetingTimes.push({value : tmpDate.toISOString()});
    });
  }

  dailySwitched(event: any) {
    if (event.detail.checked) {
      this.weekDaysToTake.forEach((w,i) => { this.weekDaysToTake[i] = true; });
    } else {
      this.weekDaysToTake.forEach((w,i) => { this.weekDaysToTake[i] = false; });
    }
  }

  addIntakeTime() {
    this.meetingTimes.push({value : "2020-01-01T08:00:00"});
  }

  deleteIntakeTime(index: number) {
    this.meetingTimes.splice(index, 1);
  }

  loadDetailedCalendarElement(){
  	this.loading = true;
  	this.ohnApi.getElement(this.calendarElement.element_slug, 3).subscribe(calendar => {
  		this.calendarElement = calendar;
  		this.loading = false;
  	});
  }

  save() {
    this.loading = true;
    this.meeting.content = JSON.stringify(this.meeting.contentObject);
    this.meeting.formatCron(this.weekDaysToTake, this.meetingTimes);
    this.meeting.rrule = this.getRRule();
    let meetingWithTrigger: any = this.ohnService.getObjectCopy(this.meeting);
    if (this.isNew) {    
      meetingWithTrigger['post_set_state_trigger'] = [
        {
          action:'setup_periodic_get_state_for_calendar'
        }
      ];
      this.ohnApi.setElementStateSc(this.calendarElement.element_slug, {value: meetingWithTrigger}, this.user.smart_contract).subscribe(event => {
        this.meeting = new OHNMeeting(event);
        this.completeEditing('created');
      });
    } else {
      meetingWithTrigger['pre_set_state_trigger'] = [
        {
          action:'reschedule_periodic_get_state_for_calendar'
        }
      ];
      this.ohnApi.patchElementStateSc(this.calendarElement.element_slug, {value: meetingWithTrigger}, this.user.smart_contract).subscribe(state => {
        this.completeEditing('modified');
      });
    }
  }

  getRRule() {
    const endPeriod = new Date(new Date(this.meeting.startTime).getTime() + 31536000000).toISOString();
    let rruleString : string = 'RRULE:UNTIL=' + endPeriod.toString().replace(/[\-,\:]/g, '').replace(/\..*Z/, 'Z');
    if (this.rrule.freq) {
      rruleString += ';FREQ=' + this.rrule.freq.toUpperCase(); 
    }
    const selectedDays = this.rruleDays.filter(d=>{return d.isChecked}).map(d=>{return d.val});
    if (selectedDays.length > 0) {
      rruleString += ';BYDAY=' + selectedDays.join();
    }

    return rruleString;
  }

  async showDeleteDialog() {
   const alert = await this.alertCtrl.create({
      header: 'Delete Meeting',
      message: 'Are you sure you want to delete <strong>'+ this.meeting.title +'</strong>?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
           
          }
        }, {
          text: 'Yes',
          handler: () => {
            this.delete(this.meeting);
          }
        }
      ]
    });

    await alert.present();
  }

  delete(prescription: OHNMedication){
    //this.prescriptions = _.filter(this.prescriptions, (p)=>{return p.id != prescription.id});
    let medWithTrigger: any = this.ohnService.getObjectCopy(this.meeting);
    medWithTrigger['pre_set_state_trigger'] = [
      {
        action:'delete_periodic_tasks_for_calendar'
      }
    ];
    this.ohnApi.deleteElementStateSc(this.calendarElement.element_slug, {value: medWithTrigger}, this.user.smart_contract).subscribe(event => {
      this.completeEditing('deleted');
    });
  }

  completeEditing(action: string) {
    this.loading = false;
    this.actionProcessed = action;
    this.notify(action);
    this.closeModal();
  }

  loadSearchFields() {
    if (this.calendarElement.config && this.calendarElement.config.enabledEvents && this.calendarElement.config.enabledEvents.meetingSearchSlug) {
      this.ohnApi.getElement(this.calendarElement.config.enabledEvents.meetingSearchSlug, 2).subscribe(db => {
      });
    }
  }

  searchDB() {
    let searchTasks$ : Observable<any>[] = [];
    let startTimeUTC: string = this.datePipe.transform(new Date((new Date(this.search.startTime).getTime() + this.userTimezoneOffset)), 'HH:mm');
    let endTimeUTC: string = this.datePipe.transform(new Date((new Date(this.search.endTime).getTime() + this.userTimezoneOffset)), 'HH:mm');
    //если просто следующий день
    if (startTimeUTC > endTimeUTC) {
      const timeZoneSign = Math.sign(this.userTimezoneOffset) < 0 ? -1 : 1;
      let weekday : number;
      switch (this.search.day) {
        case 1:
          weekday = this.userTimezoneOffset < 0 ? 7 : 2;
          break;
        case 7:
          weekday = this.userTimezoneOffset < 0 ? 6 : 1;
          break;  
        default:
          weekday = this.search.day + timeZoneSign;
          break;
      }
      let timeFrames : string[] = [startTimeUTC, '23:59', '00:01', endTimeUTC];
      searchTasks$.push(
        this.ohnApi.searchElementQuery(this.calendarElement.config.enabledEvents.meetingSearchSlug, {"start_time__gte": timeFrames[0], "start_time__lte": timeFrames[1], "formats__icontains" : 'ENG', 'weekday' : this.search.day}),
        this.ohnApi.searchElementQuery(this.calendarElement.config.enabledEvents.meetingSearchSlug, {"start_time__gte": timeFrames[3], "start_time__lte": timeFrames[4], "formats__icontains" : 'ENG', 'weekday' : weekday})
      );
    } else {
       let searhcObj: any = {"start_time__gte": startTimeUTC, "start_time__lte": endTimeUTC, "formats__icontains" : 'ENG', 'weekday' : this.search.day};
       searchTasks$.push(
         this.ohnApi.searchElementQuery(this.calendarElement.config.enabledEvents.meetingSearchSlug, searhcObj)
       );
    }
    this.searchProcessing = true;
    const requests = forkJoin(searchTasks$).subscribe(results => { 
      if (results[0]) {
        results.forEach((r, i)=>{
          this.searchResult =  this.searchResult.concat(r.map(m=>{
            return {
              name : m.meeting_name,
              link : m.comments,
              duration : m.duration_time,
              startTime : '2020-01-01T' + this.datePipe.transform(new Date((new Date('2020-01-01T' + m.start_time + 'Z').getTime() + 3600000*OhnService.daylightSavingCoef(new Date()))), 'HH:mm'),
              startTimeString : this.datePipe.transform(new Date((new Date('2020-01-01T' + m.start_time + 'Z').getTime() + 3600000*OhnService.daylightSavingCoef(new Date()))), 'h:mm a'),
              dayString : i == 0 ? OhnService.dayOfWeekNumToText(m.weekday): OhnService.dayOfWeekNumToText(m.weekday-1),
              day : m.weekday
            }
          }))
        });
        this.searchProcessing = false;
      }
    });
    
    
    /*this.ohnApi.searchElementQuery(this.calendarElement.config.enabledEvents.meetingSearchSlug, searhcObj).subscribe(searchResult => {
      if (searchResult) {
        this.searchResult = searchResult.map(m=>{
          return {
            name : m.meeting_name,
            link : m.comments,
            duration : m.duration_time,
            startTime : '2020-01-01T' + this.datePipe.transform(new Date((new Date('2020-01-01T' + m.start_time + 'Z').getTime() + 3600000*OhnService.daylightSavingCoef(new Date()))), 'HH:mm'),
            startTimeString : this.datePipe.transform(new Date((new Date('2020-01-01T' + m.start_time + 'Z').getTime() + 3600000*OhnService.daylightSavingCoef(new Date()))), 'h:mm a'),
            dayString : OhnService.dayOfWeekNumToText(m.weekday),
            day : m.weekday
          }
        });
      }
      this.searchProcessing = false;
      console.log(this.searchResult);
    });*/
  }

  selectMeetingToSchedule(meeting: any) {
    this.meeting.title = meeting.name;
    this.meeting.contentObject.url = meeting.link;
    this.meeting.startTime = new Date().toISOString();
    this.meetingTimes = [{value : meeting.startTime}];
    this.meeting.endTime = new Date(new Date().getTime() + (24 * 8 * 3600000)).toISOString();
    this.weekDaysToTake = [false, false, false, false, false, false, false];
    this.weekDaysToTake[meeting.day-1] = true;
    this.searching = false;
    this.fillType = 'manually';
  }

  async notify(action : string) {
    const toast = await this.toastController.create({
      message: 'Meeting has been ' + action,
      duration: 2000
    });
    toast.present();
  }

  closeModal() {
  	if (this.actionProcessed) {
      this.modalController.dismiss({
        dismissed: true,
        event: this.meeting,
        deleted : this.actionProcessed === 'deleted',
        isNew: this.isNew
      });
    } else {
  		this.modalController.dismiss({
	      dismissed: true
	    });
  	}
  }

}