import { Component, OnInit, Input } from '@angular/core';
import { OhnLogicService } from '../../../services/ohn-logic.service';

@Component({
  selector: 'app-numeric-field-element',
  templateUrl: './numeric-field-element.component.html',
  styleUrls: ['./numeric-field-element.component.scss'],
})
export class NumericFieldElementComponent implements OnInit {

	@Input() el: any;
	@Input() viewMode : string;

  validityRules : any[];

  constructor() { }

  ngOnInit() {
    this.el.value = this.el.value || null;
    this.el.valid = true;
  }

}
