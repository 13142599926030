import { Component, OnInit, Input, NgZone } from '@angular/core';
//import { DBMeter } from '@ionic-native/db-meter/ngx';
import { Subject } from 'rxjs';
import { OhnApiService } from '../../../services/ohn-api.service';
import { OHNElement, OHNUser } from '../../../models/ohn-instances';
import { Insomnia } from '@ionic-native/insomnia/ngx';
import { OhnLanguageService } from '../../../services/ohn-language.service';

@Component({
  selector: 'app-noise-level-element',
  templateUrl: './noise-level-element.component.html',
  styleUrls: ['./noise-level-element.component.scss'],
})
export class NoiseLevelElementComponent implements OnInit {

	@Input() el: any;
	@Input() viewMode : string;
	@Input() user : OHNUser;

	minuteBuffer: any[] = [];

  listening : boolean = false;

  dbListener : any;

  timer : any;

  chartOptions : any = {dataZoom : {start: 0, end : 100}};

  minutes : number;

  chartUpdater : Subject<any> = new Subject();

  db : any = 0;

  audioContext : any;

  constructor(
  	private ohnApi: OhnApiService,
  	//private dbMeter: DBMeter,
    //private _ngZone: NgZone,
    private insomnia: Insomnia,
    private lS: OhnLanguageService,
  ) { }

  ngOnInit() {
  	this.el.value = this.el.value || [];
    this.el.valid = true;
  }

  startListening() {
    let average = 0;
    let offset = 30;

    navigator.mediaDevices.getUserMedia({ audio: true, video: false }).then((stream) => {
      this.minutes = 0;
      this.listening = true;
      this.audioContext = new AudioContext();
      const source = this.audioContext.createMediaStreamSource(stream);
      const processor = this.audioContext.createScriptProcessor(2048, 1, 1);
      const analyser = this.audioContext.createAnalyser();

      analyser.smoothingTimeConstant = 0.8;
      analyser.fftSize = 256;

      source.connect(analyser);
      analyser.connect(processor);
      processor.connect(this.audioContext.destination);

      processor.onaudioprocess = () => {
        if (this.listening) {

          var data = new Uint8Array(analyser.frequencyBinCount);
          analyser.getByteFrequencyData(data);
          var values = 0;

          for (var i = 0; i < data.length; i++) {
              values += data[i];
          }
          average = 20*Math.log10(values/data.length) + offset;
          if (!isFinite(average)) average = 0;
          this.minuteBuffer.push(average);
        }
      };
    })
    .catch(function(err) {
      console.log(err);
    });

    this.timer = setInterval(()=>{
      this.minutes++;
      let volume = Math.round(this.minuteBuffer.reduce((a,b) => a+b, 0) / this.minuteBuffer.length);  
      if (!isFinite(volume)) volume = 0;
      this.el.value.push({time : new Date(), noise : volume, minute : this.minutes});
      this.minuteBuffer = [];
    }, 60000);
    

    this.insomnia.keepAwake().then(
      () => console.log('success'),
      () => console.log('error')
    );
  }

  stopListening() {
    this.listening = false;
    this.audioContext.close();
    clearInterval(this.timer);
    this.saveValue();

    this.insomnia.allowSleepAgain().then(
      () => console.log('success'),
      () => console.log('error')
    );
  }

  saveValue(){
    this.ohnApi.setElementStateSc(this.el.element_slug, {value : this.el.value}, this.user.smart_contract).subscribe();
  }

  /*startListening() {
  	this.minutes = 0;
 		this.listening = true;
  	this.timer = setInterval(() => {
  		this.minutes++;
  		let averageMinuteValue : number = this.minuteBuffer.map((m)=>{ return m.noise}).reduce((a, b) => a + b, 0)/this.minuteBuffer.length;
  		this.el.value.push({time : new Date(), noise : averageMinuteValue, minute : this.minutes});
			this.minuteBuffer = [];
  	}, 60000);

  	
    this.dbListener = this.dbMeter.start().subscribe(
      data => {
      	this.listening = true;
        this._ngZone.run(() => { 
        	this.minuteBuffer.push({time : new Date(), noise : data});
        });
      }
    );

    this.insomnia.keepAwake().then(
			() => console.log('success'),
			() => console.log('error')
		);
  }

  stopListening() {
  	this.listening = false;
  	clearInterval(this.timer);
  	this.saveValue();
    this.dbListener.unsubscribe();

    this.insomnia.allowSleepAgain().then(
			() => console.log('success'),
			() => console.log('error')
		);
  }

  saveValue(){
  	this.ohnApi.setElementStateSc(this.el.element_slug, {value : this.el.value}, this.user.smart_contract).subscribe();
  }*/
}