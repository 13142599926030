import { Component, OnInit, Input } from '@angular/core';
import { OHNElement } from '../../../models/ohn-instances';

@Component({
  selector: 'app-instacart-instance',
  templateUrl: './instacart-instance.component.html',
  styleUrls: ['./instacart-instance.component.scss'],
})
export class InstacartInstanceComponent implements OnInit {

  @Input() el: OHNElement;

  constructor() { }

  ngOnInit() {}

}
