import { Component, OnInit, Input } from '@angular/core';
import { OhnApiService } from '../../services/ohn-api.service';
import { OhnService } from '../../services/ohn.service';
import { ActionsProcessorService } from '../../services/actions-processor.service';
import { OhnLogicService } from '../../services/ohn-logic.service';
import { OHNElement, OHNLogicNode, OHNUser } from '../../models/ohn-instances';
import { ModalController } from '@ionic/angular';
import { Subject} from 'rxjs';

import { OhnLanguageService } from '../../services/ohn-language.service';
import { BranchedContentRendererComponent } from '../branched-content-renderer/branched-content-renderer.component';
import * as _ from 'underscore/underscore';

@Component({
  selector: 'app-history-renderer',
  templateUrl: './history-renderer.component.html',
  styleUrls: ['./history-renderer.component.scss'],
})
export class HistoryRendererComponent implements OnInit {

  @Input() parentElementSlug: string;
  @Input() user: OHNUser;

  parentElement : OHNElement;
  currentStepKeeper : OHNElement;
  historyDataPoints : any[];

  loading : boolean = true;

  constructor(
    public modalController: ModalController,
  	private ohnApi: OhnApiService,
  	private ohnService: OhnService,
    private ohnLogicService: OhnLogicService,
    private actionsProcessor: ActionsProcessorService,
    private lS: OhnLanguageService,
  ) { }

  ngOnInit() {
    this.loadParentElement();
  }

  loadParentElement() {
  	this.loading = true;
  	this.ohnApi.getElement(this.parentElementSlug, 3).subscribe(element => {
  		this.parentElement = <OHNElement>element;
  		this.processParentElement();
  		this.loading = false;
  	});
  }

  processParentElement() {
  	switch (this.parentElement.controller) {
  		case 'roadmapController':
  			this.currentStepKeeper = this.parentElement.elements.find((e)=>{return e.controller !== 'pageController'});
  			this.loadRoadmapElementHistory(this.currentStepKeeper.element_slug);
  			break;
  		
  		default:
  			// code...
  			break;
  	}
  }

  loadRoadmapElementHistory(elementSlug: string) {
  	this.loading = true;
    this.ohnApi.getElementHistoryScCostil(elementSlug, 'year', 1, this.user.smart_contract).subscribe(history => {
      if (history) {
      	const dates = _.keys(history);
      	this.historyDataPoints = _.sortBy(dates.map((d)=>{ return {
            date : new Date(d),
            value : history[d].value.value,
            page : this.parentElement.elements.find((e) => { return e.element_slug === history[d].value.value})
          }
        }), 'date');
      }
      this.loading = false;
    });
  }

  async openDetails(page : OHNElement) {
    const contentPage = await this.modalController.create({
      component: BranchedContentRendererComponent,
      componentProps: {
      	parentElement : page,
      	isSinglePage : true,
        user : this.user
      },
      backdropDismiss: false
    });

    contentPage.onDidDismiss().then((data) => {
   
    })

    return await contentPage.present();
  }

  closeModal() {
    this.modalController.dismiss({
      dismissed: true
    });
  }
}
