import { Injectable } from '@angular/core';
import { LoadingController, AlertController, ModalController } from '@ionic/angular';
import { Subject, Observable } from 'rxjs';
import { BLE } from '@ionic-native/ble/ngx';
import * as _ from 'underscore/underscore';
declare let ble: any;

@Injectable({
  providedIn: 'root'
})

export class OhnBLEService {

	uart : any = {
	    serviceUUID: '6e400001-b5a3-f393-e0a9-e50e24dcca9e',
	    txCharacteristic: '6e400002-b5a3-f393-e0a9-e50e24dcca9e',
	    rxCharacteristic: '6e400003-b5a3-f393-e0a9-e50e24dcca9e'
	};

	currentDeviceId : string;
	gettingInformativeString : boolean = false;
	informativeString : string = "";

	dataReceived : Subject<string> = new Subject();

	constructor(
		private loadingCtrl: LoadingController,
		private alertController: AlertController,
		private modalController: ModalController,
		private ble: BLE,
	){

		this.currentDeviceId = localStorage.getItem('ohnConnectedBluetoothButtonId');
	}

	scanForDevices(): Observable<any> {
		return this.ble.scan([], 10);
		//this.disconnect('E5:F7:A9:6D:A7:11');
	}

	bleEnabled(): Promise<any> {
		return this.ble.isEnabled();
	}

	connect(device : any, type: string): Observable<any> {
		this.currentDeviceId = device.id;
		switch (type) {
			case 'puck' : 
				localStorage.setItem('ohnConnectedBluetoothButtonId', device.id);
				localStorage.setItem('ohnConnectedBluetoothButtonName', device.name);
			break;
			case 'o2oximeter' :
				localStorage.setItem('ohnConnectedBluetoothO2RingId', device.id);
				localStorage.setItem('ohnConnectedBluetoothO2RingName', device.name);
			break;
		}
		return this.ble.connect(device.id);
	}

	subscribeForDataread(deviceId :string, type : string){
		alert('Subscibed for Dataread');
		ble.startNotification(this.currentDeviceId, this.uart.serviceUUID, this.uart.rxCharacteristic, (data)=>{
			alert('Data received');
			alert(data);
      this.processDataChunk(this.bytesToString(data), type);
    }, (error)=>{ alert("Starting notification Error"); alert(JSON.stringify(error))});
	}

	processDataChunk(chunk: string, type : string) {
		switch (type) {
			case 'puck' : 
				for (let i = 0, l = chunk.length; i < l; i++) {
					if (chunk.charAt(i) == '§') {
						this.gettingInformativeString = true;
					}
					if (chunk.charAt(i) == '^') {
						this.gettingInformativeString = false;
						this.dataReceived.next(this.informativeString);
						this.informativeString = "";
					}
					if (this.gettingInformativeString && chunk.charAt(i) != '§') {
						this.informativeString = this.informativeString.concat(chunk.charAt(i));
					}
				}
			break;
			case 'o2oximeter' :
				this.dataReceived.next(chunk);
			break;
		}
	}

	sleep(ms) {
	  return new Promise(resolve => setTimeout(resolve, ms));
	}

	pushToBluethooth(content: string) {
		let chunks: string[] = [];
		let sleepTime : number = 200;
		chunks = content.match(new RegExp('(.|[\r\n]){1,20}', 'g'));
		chunks.forEach((chunk, i)=>{
			this.pushChunk(chunk, sleepTime*i);
		});
	}

	async pushChunk(chunk : string, sleepTime: number){
		await this.sleep(sleepTime);
		let data = this.stringToBytes(chunk);
		this.ble.write(this.currentDeviceId, this.uart.serviceUUID, this.uart.txCharacteristic, data).then((d)=>{
		});
	}

	disconnect(deviceId : string){
		this.ble.disconnect(deviceId);
		this.currentDeviceId = undefined;
		localStorage.removeItem('ohnConnectedBluetoothButtonId');
	}

	deviceConnected(deviceId: string) {
		return this.ble.isConnected(deviceId);
	}

	processDevices(data?: any) {
		//alert(JSON.stringify(data));
	}

	onScanError(e? : any){
		//alert(JSON.stringify(e));
	}

	bytesToString(buffer: any) {
	  return String.fromCharCode.apply(null, new Uint8Array(buffer));
	}

	stringToBytes(string: string) {
    let array = new Uint8Array(string.length);
    for (let i = 0, l = string.length; i < l; i++) {
        array[i] = string.charCodeAt(i);
    }
    return array.buffer;
	}
}